import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import { SignoutResponse, User } from 'oidc-client-ts'
import ChimeUser from '../models/ChimeUser'
import ResponseBase from '../models/ResponseBase'
import { AuthService } from '../services/AuthService'
import httpPCS from '../services/HttpServicePCS'
import { AppDispatch, RootState } from './store'

export interface AccountState {
  ciamId: string
  email: string
  name: string
  chimeUserId: string
  chimeName: string
  mobile: string
  hasPermission: boolean
  redirectUrl: string
}

const initialState: AccountState = {
  ciamId: '',
  email: '',
  name: '',
  chimeUserId: '',
  chimeName: '',
  mobile: '',
  hasPermission: false,
  redirectUrl: '',
}

export const getAccountSimpleAsync = createAsyncThunk<
  ResponseBase<ChimeUser> | null,
  any,
  {
    dispatch: AppDispatch
    state: RootState
    rejectValue: AxiosError
  }
>('account/getAccountSimple', async (chimeUserLoginRequest, thunkApi) => {
  try {
    const headers = {
      ApiKey: process.env.REACT_APP_PCS_KEY || '',
    }
    var response = await httpPCS.post<ResponseBase<ChimeUser>>(
      `/api/Chime/GetChimeUser`,
      chimeUserLoginRequest,
      { headers },
    )
    return response.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error)
    }
  }
  return null
})

export const loginCallbackAsync = createAsyncThunk<
  User | null,
  void,
  {
    dispatch: AppDispatch
    state: RootState
    rejectValue: AxiosError
  }
>('account/loginCallbackAsync', async () => {
  var result: User | null = null
  try {
    const accountService = AuthService.getInstance()
    result = await accountService.loginCallbackAsync()
  } catch (error) {}
  return result
})

export const logoutCallbackAsync = createAsyncThunk<
  SignoutResponse | null,
  void,
  {
    dispatch: AppDispatch
    state: RootState
    rejectValue: AxiosError
  }
>('account/logoutCallbackAsync', async () => {
  var result: SignoutResponse | null = null
  try {
    const accountService = AuthService.getInstance()
    result = await accountService.logoutCallbackAsync()
  } catch (error) {}
  return result
})

export const loginAsync = createAsyncThunk<
  void,
  void,
  {
    dispatch: AppDispatch
    state: RootState
    rejectValue: AxiosError
  }
>('account/loginAsync', async () => {
  try {
    const accountService = AuthService.getInstance()
    await accountService.loginAsync()
  } catch (error) {}
})

export const logoutAsync = createAsyncThunk<
  void,
  void,
  {
    dispatch: AppDispatch
    state: RootState
    rejectValue: AxiosError
  }
>('account/logoutAsync', async () => {
  //var result: SignoutResponse | null = null;
  try {
    const accountService = AuthService.getInstance()
    //result =
    await accountService.logoutAsync()
  } catch (error) {}
  //return result;
})

export interface RequestChimeUserModel {
  name: string
  userId: string
}

export const accountSlice = createSlice({
  name: 'accountRediReducer',
  initialState,
  reducers: {
    getAccountState: (state) => {
      return { ...state }
    },
    setRedirectUrl: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        redirectUrl: action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccountSimpleAsync.fulfilled, (state, { payload }) => {
        if (payload != null && payload.value && payload.value.isSuccess) {
          // state = payload.value;
          state.ciamId = payload.value.ciamId
          state.email = payload.value.email
          state.name = payload.value.name
          state.chimeUserId = payload.value.userId
          state.chimeName = payload.value.name
          state.mobile = payload.value.mobile
          state.hasPermission = true
        }
      })

      .addCase(logoutCallbackAsync.fulfilled, (state, { payload }) => {
        state.ciamId = ''
        state.email = ''
        state.name = ''
        state.chimeUserId = ''
        state.chimeName = ''
        state.mobile = ''
        state.hasPermission = false
      })
  },
})

export const selectAccount = (rootState: RootState) => rootState.accountRedi
export const selectUserId = (rootState: RootState) => rootState.accountRedi.ciamId
export const selectRedirectUrl = (rootState: RootState) =>
  rootState.accountRedi.redirectUrl
export const { getAccountState, setRedirectUrl } = accountSlice.actions
export default accountSlice.reducer
