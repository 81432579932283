import axios from "axios";
const qs = require("qs");

const httpPCSPublic = axios.create({
    baseURL: process.env.REACT_APP_API_KEY_PCS,
    timeout: 30000,
    paramsSerializer: function (params) {
        return qs.stringify(params, {
            encode: false,
        });
    },
});


httpPCSPublic.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

httpPCSPublic.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);
export default httpPCSPublic;
