import { Fragment, useEffect, useState } from "react";
import React from "react";
import Style from "./LandingPage.module.css";
import { useTranslation } from "react-i18next";
import Footer from "./Footer/Footer";
import { ProductsCardCom } from "../../../common-components/ProductsCard/ProductsCardCom";
import { AppConsts } from "../../../helpers/AppConstants";
import BotWidgetLauncher from "./botWidgetLauncher/BotWidget";
import CustomLoader from "../../../common-components/RDComponents/CustomLoader";

const LandingPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const listAppItemData = AppConsts.listAppItem

  //Change Browser Tab Info
  useEffect(() => {
    const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement | null;
    if (link !== null) {
      link.href = "/logo_wsa.png"; // Replace 'newfavicon.ico' with your desired favicon
    } else {
      const newLink = document.createElement("link");
      newLink.rel = "icon";
      newLink.href = "/logo_wsa.png"; // Replace 'newfavicon.ico' with your desired favicon
      document.head.appendChild(newLink);
    }
    window.document.title = "Mobility Aftermarket Services";
  }, []);

  return (
    <>
      <div
        className={Style.landing_page_container}
      >
        <div className={Style.page_main__header_title}>
          <div className={Style.header_title}>
            <p>{t("WSA_label")}​</p>
          </div>
          <div className={Style.header_name}>
            <p>{t("WSA_Applications_label_full")}</p>
          </div>
          <div className={Style.main_content}>
            {listAppItemData.map((item, index) => {
              return (
                <ProductsCardCom item={item} key={index} />
              );
            })}
          </div>
        </div>

        <BotWidgetLauncher></BotWidgetLauncher>
        {/* <div className="page-main--footer">
          <Footer></Footer>
        </div> */}
      </div>


      {isLoading && (
        <Fragment>
          <CustomLoader enable={isLoading}></CustomLoader>
        </Fragment>
      )}

    </>
  );
};

export default LandingPage;
