import { FC, Fragment, useState } from "react";
import { Button, Dropdown, Icon, Modal } from "semantic-ui-react";
import { ReactComponent as Profile } from "../../assets/svg/profile.svg";
import { ReactComponent as Language } from "../../assets/svg/languagePopup.svg";
import { selectIsSignIn } from "../../redux/auth";
import { useSelector } from "react-redux";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import React from "react";
import "./css/AccountButton.css";
import { languageOptions } from "../../translations/languageOptions";

interface AccountButtonProps {
  isMobile: boolean;
  style?: Object;
  className: string;
  login: Function;
  logout: Function;
}

const AccountButton: FC<AccountButtonProps> = (props) => {
  const isLoggedIn = useSelector(selectIsSignIn);
  const [isOpenLogOutModal, setIsOpenLogOutModal] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [language, setLanguage] = useState<string>(i18n.language);
  const { t } = useTranslation();
  const handleSelection = () => {
    setIsOpenLogOutModal(true)
  };

  const switchLanguage = (event: any, data: any) => {
    i18n.changeLanguage(data.value);
    setLanguage(data.value)
  };


  if (isLoggedIn) {
    return (
      <Fragment>
        <div className="header--icon">
          <Button className="header--icon--language">
            <Dropdown
              floating
              inline
              header={t("Available_Languages_Label")}
              options={languageOptions}
              value={i18n.language}
              onChange={switchLanguage}
              icon={
                <Icon>
                  <Language />
                  <span className="header--icon--language--key">{i18n.language}</span>
                </Icon>
              }
            ></Dropdown>
          </Button>
          <Button className="header--icon--account" onClick={() => handleSelection()}>
            <Icon>
              <Profile />
            </Icon>
          </Button>
        </div>

        <Modal
          onClose={() => setIsOpenLogOutModal(false)}
          open={isOpenLogOutModal}
          dimmer={{ className: "test-dimmer black-dimmer" }}
          closeOnDimmerClick={false}
          size={"mini"}
        >
          <Modal.Content>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "1rem 0",
                fontSize: "1.25rem",
              }}
            >
              {t("Log_Out_Question")}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                padding: "1rem 0",
              }}
            >
              <Button
                loading={isLoggingOut}
                disabled={isLoggingOut}
                className="bwc-btn--primary"
                onClick={() => {
                  setIsLoggingOut(true);
                  props.logout();
                }}
              >
                {t("Yes_Label")}
              </Button>
              <Button
                disabled={isLoggingOut}
                className="bwc-btn--secondary"
                onClick={() => setIsOpenLogOutModal(false)}
              >
                {t("Cancel_Label")}
              </Button>
            </div>
          </Modal.Content>
        </Modal>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <div className="header--icon">
          <Button className="header--icon--language">
            <Dropdown
              className="language-selector-dropdown"
              floating
              labeled
              options={languageOptions}
              value={i18n.language}
              onChange={switchLanguage}
              header="Available Languages"
              icon={
                <Icon>
                  <Language />
                  <span className="header--icon--language--key">{i18n.language}</span>
                </Icon>
              }
            ></Dropdown>
          </Button>
        </div>
      </Fragment>
    );
  }
};

export default AccountButton;
