import React, { Fragment, useState } from 'react'
import { Grid, Button, Modal, Input } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './css/ModalCreateNewChannel.css'
import Boschicon from '../../common-components/boschIcon/Boschicon'
import { useSelector } from 'react-redux'
import { selectVisualConnectListState } from '../../redux/visualConnect'

interface ModalSendInviteVisualConnectProps {
  isOpen: boolean
  onClose: Function
  onHanldeSendSMS: Function
  sendEmailVisualConnect: Function
  phoneNumberInput: string
  isLoading: boolean
  onVoiceCall: boolean
}

const ModalSendInviteVisualConnect = ({
  isOpen,
  onClose,
  onHanldeSendSMS,
  phoneNumberInput,
  isLoading,
  sendEmailVisualConnect,
  onVoiceCall
}: ModalSendInviteVisualConnectProps) => {
  const [openSendSMS, setOpenSendSMS] = useState<boolean>(false)
  const [phoneNumber, setPhoneNumber] = useState<string>(phoneNumberInput)
  const visualConnectList = useSelector(selectVisualConnectListState)
  const sendLinkVideo = () => {
    onHanldeSendSMS(phoneNumber)
  }
  const onHanldeSendLink = () => {
    onHanldeSendSMS()
  }
  const onChangeEmail = (e: any) => {
    setEmail(e.target.value)
  }
  const [openEmailInput, setOpenEmailInput] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const { t } = useTranslation()
  return (
    <Fragment>
      {!openSendSMS && !openEmailInput && (
        <Modal
          onClose={() => onClose()}
          open={isOpen}
          dimmer={{ className: 'test-dimmer' }}
          size={'tiny'}
          closeIcon
          closeOnDimmerClick={false}
        >
          <div className="header-color-line"></div>
          <Modal.Header style={{ textAlign: 'center' }}>
            {!onVoiceCall ? t('Invite_To_Visual_Question') : t("Invite_To_Voice_Call_Question")}
          </Modal.Header>
          <Modal.Content>
            <Grid stackable columns={2}>
              <Grid.Column className="options">
                <Button
                  className="options--item"
                  onClick={() => setOpenSendSMS(true)}
                >
                  <Boschicon name={'bosch-ic-smartphone-loading-wheel'} />
                  {t('Sms_label')}
                </Button>
                <Button className="options--item" disabled>
                  <Boschicon name={'bosch-ic-binary'} />
                  {t('Code_label')}
                </Button>
              </Grid.Column>
              <Grid.Column className="options">
                <Button
                  className="options--item"
                  onClick={() => setOpenEmailInput(true)}
                >
                  <Boschicon name={'bosch-ic-mail'} />
                  {t('Email_label')}
                </Button>
                {
                  !visualConnectList.active && (
                    <Button
                      className="options--item"
                      onClick={() => { onHanldeSendLink() }}
                    >
                      <Boschicon name={'bosch-ic-link'} />
                      {t('Link_label')}
                    </Button>
                  )
                }

              </Grid.Column>
            </Grid>
          </Modal.Content>
        </Modal>
      )}
      {openSendSMS && (
        <Modal
          onClose={() => onClose()}
          open={isOpen}
          dimmer={{ className: 'test-dimmer' }}
          size={'tiny'}
          closeIcon
          closeOnDimmerClick={false}
        >
          <div className="header-color-line"></div>
          <Modal.Header style={{ textAlign: 'center' }}>
            {t('Invite_Sms_Question')}
          </Modal.Header>
          <Modal.Content>
            <Grid>
              <Grid.Row
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <PhoneInput
                    onChange={(e) => setPhoneNumber(e)}
                    value={phoneNumber}
                    onEnterKeyPress={(
                      e: React.KeyboardEvent<HTMLInputElement>,
                    ) => {
                      if (e.key === 'Enter') {
                        sendLinkVideo()
                      }
                    }}
                  />
                </div>
              </Grid.Row>

              <Grid.Row style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  disabled={isLoading || !phoneNumber}
                  loading={isLoading}
                  onClick={() => sendLinkVideo()}
                  primary
                  className="button-search"
                >
                  {t('Send_Invite_Label')}
                </Button>
              </Grid.Row>
            </Grid>
          </Modal.Content>
        </Modal>
      )}
      {openEmailInput && (
        <Modal
          onClose={() => onClose()}
          open={isOpen}
          dimmer={{ className: 'test-dimmer' }}
          size={'tiny'}
          closeIcon
          closeOnDimmerClick={false}
        >
          <div className="header-color-line"></div>
          <Modal.Header style={{ textAlign: 'center' }}>
            {t('Invite_Email_Question')}
          </Modal.Header>
          <Modal.Content>
            <Grid>
              <Grid.Row
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    onChange={onChangeEmail}
                    placeholder="Type an Email"
                    onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (e.key === 'Enter') {
                        sendEmailVisualConnect(email)
                      }
                    }}
                  />
                </div>
              </Grid.Row>

              <Grid.Row style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  disabled={isLoading || !email}
                  loading={isLoading}
                  onClick={() => sendEmailVisualConnect(email)}
                  primary
                  className="button-search"
                >
                  {t('Send_Invite_Label')}
                </Button>
              </Grid.Row>
            </Grid>
          </Modal.Content>
        </Modal>
      )}
    </Fragment>
  )
}

export default ModalSendInviteVisualConnect
