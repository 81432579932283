import { Fragment, useEffect } from "react";
import { AppDispatch, useAppDispatch } from "../../redux/store";
import { logoutCallbackAsync } from "../../redux/account";
import { AuthService } from "../../services/AuthService";
import React from "react";
import CustomLoader from "../../common-components/RDComponents/CustomLoader";

const SignOutCallback = () => {
    const dispatchReduxToolkit: AppDispatch = useAppDispatch();

    useEffect(() => {
        async function performLogout() {
            const response = await dispatchReduxToolkit(logoutCallbackAsync());
            if (logoutCallbackAsync.fulfilled.match(response)) {
                const authService = AuthService.getInstance();
                await authService.clearLoginUserState();
                setTimeout(() => {
                    window.location.href = "/pcs";
                }, 2000)
            }
        }

        performLogout();

    }, [])

    return (
        // <div>
        //     <h1>Loading...</h1>
        // </div>
        <Fragment>
            <CustomLoader enable={true}></CustomLoader>
        </Fragment>
    )
}

export default SignOutCallback;