import React, { useEffect, useRef, useState } from 'react';
import { AttachmentService } from '../../../../services/AttachmentService';
import defaultImage from '../../../../assets/default-img.jpg';
import Boschicon from '../../../../common-components/boschIcon/Boschicon';
import './Attachment.css'
interface AttachmentProps {
    fileKey: string,
    name: string,
    size: string,
    isImage: boolean
}

const Attachment = ({ fileKey, name, size = "0", isImage }: AttachmentProps) => {
    const [url, setUrl] = useState(defaultImage);
    const [downUrl, setDownUrl] = useState("");

    const attachmentService = AttachmentService.getInstance();

    useEffect(() => {
        setDownUrl(`${process.env.REACT_APP_API_KEY_PCS}/api/Document/DownloadFile/` + fileKey + `/${name}`);
        async function getUrl() {
            const data = await attachmentService.getPresignURL(fileKey);
            setUrl(data);
        }
        getUrl();
    }, [fileKey]);

    const handleOnClick = (url: string) => {
        window.open(url, '_blank')
    }

    return (
        // <MessageAttachment renderImg={isImage} name={name} downloadUrl={downUrl} size={size} imgUrl={url} />
        <div className='message-attachment'>
            <div className='message-attachment--infor'>
                <div className='message-attachment--infor--icon'>
                    <Boschicon name={'bosch-ic-document-plain'} />
                </div>
                <div className='message-attachment--infor--name' onClick={() => handleOnClick(downUrl)}>
                    <p className='file-name'>{name}</p>
                    <p className='file-size'>{size}</p>
                </div>
            </div>
            {
                isImage && (
                    <div className='message-attachment--image'>
                        <img src={url}></img>
                    </div>
                )
            }
        </div>
    );
};

export default Attachment;
