import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { loginAsync } from '../../redux/account';
import { useDispatch } from 'react-redux';
import { authSetSignIn } from '../../redux/auth';

const SessionTimeout = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const goToHomePage = () => {
        setTimeout(() => {
            history.push("/pcs");
        }, 1000)
    }

    const login = () => {
        dispatch(loginAsync());
    };

    useEffect(() => {
        dispatch(authSetSignIn(false));
    }, [])

    return (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
            <h5>Your working session has been ended.</h5>
            <p>Please click <a href="#" onClick={() => goToHomePage()}>here</a> to go to home page or <a href="#" onClick={() => login()}>login</a> again and continue!</p>
        </div>
    )
}

export default SessionTimeout;