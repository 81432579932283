export const TRANSLATIONS_EN = {
	Service_Assist_Label: "Service Assist",
	Start_New_LabelBtn: "Start New",
	Logo_Title_Label: "Private Channel Solution",
	Start_New_Question: "How would you like to start a new channel?",
	Sms_label: "SMS",
	Email_label: "E-mail",
	Ticket_Id_Label: "Ticket ID",
	Code_label: "Random Code",
	Channel_List: "Channel List",
	Chat_Label: "Chat",
	Call_Label: "Call",
	Video_Label: "Video",
	Share_Label: "Share",
	Stop_Channel_Label: "Stop Channel",
	Contact_Details_Label: "Contact Details",
	Request_Details_Label: "Request Details",
	Attendees_Label: "Attendees",
	Customer_Label: "Customer",
	Bosch_Expert_Label: "Bosch Expert",
	Type_Your_Message_Label: "Type Your Message",
	Stop_Channel_Question: "Do you want to close the channel?",
	Yes_Label: "Yes",
	Cancel_Label: "Cancel",
	Start_New_Video_Session: "Start new video session",
	Without_SMS_Label: "Without SMS",
	Send_SMS_Label: "Send SMS",
	Send_Invite_Label: "Send Invite",
	Log_Out_Question: "Do you want to log out?",
	Welcome_Label: "Welcome to Private Channel Solution",
	Login_Label: "Login",
	Login_Is_Required_Label: "Login is required to start channel",
	Account_Permission_Label: "This account doesn't have permission to start channel",
	Start_Label: "Start",
	Noted_Recommend: "Due to Bosch's policy for the use of Firefox, we recommend that you use a different browser to get the best user experience of the Private Channel Solution application. Recommended browsers: Microsoft Edge & Google Chrome",
	Available_Languages_Label: "Available Languages",
	Can_Not_Create_Meeting_Title: "Unable to create new Private Channel",
	Can_Not_Create_Meeting_Content: "You are still in an active Private Channel. Please end the current Channel before starting a new one.",
	Leave_meeting_Warning: "Leave meeting or you can end the meeting for all. The meeting cannot be used once it ends.",
	Leave_Lable: "Leave",
	End_Lable: "End",
	Meeting_Ended: "The meeting has been ended",
	Start_Channel_Session: "Start channel session",
	Start_Channel_Session_Content: "The Technician is currently in another with Ticket ID: ",
	Join_Lable: "Join",
	Join_Meeting_Label: "Join Meeting",
	Meeting_Information_Label: "Channel information",
	Meeting_ID_Label: "Channel ID",
	Translate_Option_Title: "Translate Option",
	Translate_Mode_Label: "Translate mode: ",
	From_Label: "From: ",
	To_Label: "To: ",
	Chat_Room_Label: "Private Channel",
	Visual_Connect_Label: "Visual Connect​",
	Bosch_ID_Label: "Bosch ID​",
	Invite_To_Chat_Question: "How would you like to share the Private Channel invitation?",
	Invite_To_Visual_Question: "How would you like to share the Visual Connect invitation?",
	Invite_Sms_Question: "Send invite by SMS​",
	Invite_To_Chat_By_Ticket_ID: "Send invite by Ticket ID​",
	Invite_Email_Question: "Send invite by Email​",
	Link_label: "Link​",
	WSA_label: "Workshop Service Assist",
	WSA_Applications_label: "Applications",
	WSA_Remote_Diagnostics_label: "Remote Diagnostics",
	WSA_Service_Training_label: "Service Training",
	WSA_Enter_website_label: "Enter website",
	WSA_Slogan_label: "Invented for life",
	WSA_contact_info_label: "General contact info",
	WSA_PSIRT_label: "Product security (PSIRT)",
	WSA_licences_label: "Innovations, patents, and licenses",
	WSA_purchasing_label: "Purchasing and logistics",
	WSA_copyright_label: "© Robert Bosch GmbH, all rights reserved",
	WSA_copyright_information_label: "Corporate information",
	WSA_copyright_notice_label: "Legal notice",
	WSA_copyright_protectionnotice_label: "Data protection notice",
	WSA_copyright_terms_label: "Terms & Conditions",
	WSA_copyright_settings_label: "Privacy settings",
	Infor_CorporateInformation_Description: "Responsible for the internet pages of Robert Bosch GmbH",
	Infor_DataProtectionNotice_Description: "Data protection notice for Bosch Remote Diagnostics Service",
	Infor_LegalNotice_Description: "For users of the internet pages of Robert Bosch GmbH",
	Id_panel: "Id",
	Topic_panel: "Topic",
	Make_panel: "Make",
	Model_pane: "Model",
	Engine_code_panel: "Engine Code",
	Year_panel: "Year",
	Vin_panel: "Vin",
	Plate_panel: "Plate",
	Hardware_panel: "Hardware",
	ar_mode_warning: "Your Technician device is not available for AR",
	Write_something: "Write something to start Chatting",
	Session_title: "Session",
	Live_Translation: "Live translation",
	Enabled_panel: "Enabled",
	Joined_note: "You are now connected to the chat session!",
	Today_panel: "Today",
	Read_panel: "Read",
	Sent_panel: "Sent",
	Dis_translation: "Disable translation",
	En_translation: "Enable translation",
	Terminated_Channel_note: "Bosch Expert has terminated the channel!",
	Not_start_note: "has not started yet!",
	Retry_note: "Please click “Retry” to try again or close this window to exit.",
	Retry_panel: "Retry",
	Thanks_note: "Thank you for using Bosch Technical Services. I’m very please that we had the opportunity to help you today. Please contact us again if you need help. Have a wonderful day.",
	En_live_translate: "Enable live translation",
	Continue_panel: "Continue",
	Translate_from: "Translate from",
	Translate_to: "Translate to",
	Random_ID: "Random ID",
	Anonymous: "Anonymous",
	Generated_ID: "Random ID has been generated!",
	Your_ID: "Your random ID is:",
	Public_Meeting_ID_Label: "Public Meeting ID:",
	SelectContactPersonModal_Title: "Who would you like to chat with?",
	SelectContactPersonDropDown_Title: "Contact Person",
	SelectContactPersonDropDown_Error_Message: "This user doesn't have a ciam id (user account for CDM), Select another contact person to continue ...",
	TicketCreator__Title: "(ticket creator)",
	Wsa_status_title: "WSA status: ",
	Close_panel: "Close",
	OCR_Detected_title: "Optical Character Recognition Detected:",
	Voice_Call_Label: "Voice Call",
	Invite_To_Voice_Call_Question: "How would you like to share the Voice Call invitation?",
	AR_mode: "AR MODE",
	Switch_modal_title: "Switch",
	Switch_modal_content_Video: "Switch to Video Call success!",
	Switch_modal_content_Voice: "Switch to Voice Call success!",
	Video_Call_Label: "Video Call",
	Functionality_Label: "Functionality",
	Press_Chatting_Button_Label: "Press Chatting Button to Start Chatting Room",
	WSA_Applications_label_full: "Workshop Service Applications",
	WSA_Applications_item_card: "Enter application",
	WSA_Applications_item_card_diagnostic_support: "Diagnostic Support",
	WSA_Applications_item_card_remote_diagnostics: "Remote Diagnostics",
	WSA_Applications_item_card_service_training: "Service Training",
	WSA_Applications_item_card_technical_support: "Technical Support",
	Header_Label_Settings: "Settings",
	Assistant_Label_Bot: "Assistant",
	Assistant_first_message: "Hi there, how may i help you today ?",
	Assistant_today_label: "Today",
	Visual_Connect_Pro_btn: "Visual Connect Pro",
	Join_chat_btn: "Join Chat",
	Your_name_input_label: "Your name",
	Code_input_label: "Code",
	Start_btn_label: "Let's go",
	Change_info_label: "Change infomation",
	Change_name_label: "Change Name",
	Change_code_label: "Change Code",
	assistant_ask_name_message: "Before we start, may I know your name please?",
	assistant_ask_code_message: "Please enter your code to join the session",
	assistant_ask_change_message: "Please choose the item that you want to change",
	assistant_ask_change_name_message: "Please type your name",
	assistant_ask_change_code_message: "Please type code",
	assistant_ask_change_action_message: "Please select action type",
	assistant__confirm_message: "I have received your information: \n Your code: [code] \n Your name: [name] \n Would you like to continue?",
	Assistant_Welcome_Bot: "Welcome to Tessa Bot!",
	End_meeting_Warning: "Are you sure to end the meeting ?. The meeting cannot be used once it ends.",
	Leave_meeting_meesage_Warning: "You are on a call leave, please click here to continue",
	Reject_Switch_modal_content_Voice: "User does not allow Camera to be turned on",
	Change_action_label: "Change Action Type",
	BoschExpert_Label_Sender: "Bosch Expert",
	Retry_note_v2: "Please close the modal then try again or change the code",
	End_Channel_note: "The channel has ended!",
	Leave_Channel_note: "The technician has leaved out the channel!",
	Join_Channel_note: "The technician has join the channel!",
	Translation_is_available: "Translation is available!",
	Translation_is_available_detail: "We have detected that your language is [tech_language] and that the Bosch Experts language is [expert_language]. \n Would you like to enable live translation?",
	No_Label: "No",
	Minimize_Label: "Minimize",
	Maximize_Label: "Maximize",
	Close_Session_Label: "Close Session",
	Popup_Title_Warning: "Warning",
	Show_Message_Label: "Click here to view the full chat history of this conversation",
	Hide_Message_label: "Click here to hide chat history",
	Close_Channel_Message_Question: "Do you want to close this session?",
	Channel_noti_joined_message: "[name] has joined channel.",
	Click_to_view_history_message: "Click here to view chat history",
	Online_Label: "Online",
	Offline_Label: "Offline",
	Onhold_Label: "On Hold",
	Busy_Label: "Busy",
	OK_Label: "OK",
	Channel_Notification: "Channel Notification",
	BE_Leave: "It looks like Bosch Expert has been offline for a while. You can continue waiting or leave this conversation.",
	Chat_LoadingChat_Message: "Loading chat.....",
	Popup_Title_Error: "Oops, something went wrong!",
}