import React, { useEffect, useState } from "react";
import './Chat.css'
import { useTranslation } from "react-i18next";
import RDAlert from "../../../../common-components/RDComponents/RDAlert";
import CustomLoader from "../../../../common-components/RDComponents/CustomLoader";
declare global {
  namespace JSX {
    interface ExtendedButton
      extends React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
      > {
      sessionid?: string;
    }
    interface IntrinsicElements {
      "r-chat-channel-anonymous": ExtendedButton;
    }
  }
}

interface ChatProps {
  sessionId?: string,
  hanldeOnActiveChannel: Function
}

export const Chat: React.FC<ChatProps> = ({ sessionId, hanldeOnActiveChannel }) => {
  const { t } = useTranslation();
  const [isChatLoaded, setIsChatLoaded] = useState(false);
  const [status, setStatus] = useState("");

  const closeTab = () => {
    setStatus("")
  };

  useEffect(() => {

    const handleChatLoadedError = (e: any) => {
      console.log('Chat history loaded for ticket: ', e.detail);
      setStatus(
        e.detail.messages || ""
      )
      setIsChatLoaded(true);
    };

    const handleChatLoaded = (e: any) => {
      console.log('Chat loaded success: ', e.detail);
      setIsChatLoaded(e.detail);
      hanldeOnActiveChannel();
    };

    window.addEventListener('chatLoadedError', handleChatLoadedError, false);
    window.addEventListener('chatLoaded', handleChatLoaded, false);

    return () => {
      window.removeEventListener('chatLoadedError', handleChatLoadedError, false);
      window.removeEventListener('chatLoaded', handleChatLoaded, false);
    };
  }, []);

  return sessionId ? (
    <div className="chat_wrapper">
      {!isChatLoaded && (
        <CustomLoader enable={!isChatLoaded}></CustomLoader>
      )}
      {
        status && (<RDAlert title={t("Dialog_Error_Title")} messages={status} isOpen={true} type={"error"} onAccept={closeTab} acceptButtonText={t("Close_panel")} />)
      }
      <div
        className={`${!isChatLoaded ? "invisible_chat" : "chat_form"}`}
      >
        <r-chat-channel-anonymous
          sessionid={sessionId}
        ></r-chat-channel-anonymous>
      </div>
    </div>
  ) : (
    <></>
  );
};
