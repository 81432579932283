import React, { useState, useRef, Fragment, useEffect } from 'react'
import {
  Persistence,
  MessageType,
  sendChannelMessage,
  updateChannelReadMarker,
} from '../../api/ChimeAPI'
import './Input.css'
import {
  Button,
  Image,
  Icon,
  Input as InputAV,
  Popup,
  Message,
  Progress,
} from 'semantic-ui-react'
import { EmojiClickData } from 'emoji-picker-react'
import { ReactComponent as File } from '../../assets/svg/document-plain.svg'
import { ReactComponent as Emoji } from '../../assets/svg/emoji-happy.svg'
import { AttachmentService } from '../../services/AttachmentService'
import { v4 as uuidv4 } from 'uuid'
import ErrorModal from '../../components/modals/ErrorModal'
import ModalGetEmoji from '../../components/modals/ModalGetEmoji'
import { useTranslation } from 'react-i18next'
import { checkText } from 'smile2emoji'
import autosize from 'autosize';
interface InputProps {
  activeChannelArn: string
  member: string
  browserLanguage: string
}

const InputMessage = ({
  activeChannelArn,
  member,
  browserLanguage
}: InputProps) => {
  const [text, setText] = useState('')
  const [file, setFile] = useState<any>(null)
  const [progressCurrent, setProgressCurrent] = useState<number>(0)
  const [isUploadFail, changeUploadFail] = useState<boolean>(false)
  const [fileSizeWarn, setFileSizeWarn] = useState({
    popup: false,
    message: '',
  })
  const [networkError, setNetworkError] = useState<boolean>(false)
  const [openEmoji, setOpenEmoji] = useState<boolean>(false)
  const fileInputRef = useRef<any>()
  const inputRef = useRef<any>()
  const { t } = useTranslation()
  const memberSender = { userId: member }
  const maxSize = parseInt(`${process.env.REACT_APP_AWS_S3_MAX_SIZE}`)
  const maxSizeInMB = Math.round(maxSize / 1024 / 1024)
  const maxSizeMessageInWord = 4000;
  const [countWordMesssage, setCountWordMesssage] = useState<number>(0)

  const resetState = () => {
    setText('')
    setFile(null)
    setProgressCurrent(0)
    setCountWordMesssage(0)
  }

  const onChange = (e: any) => {
    let text = e.target.value
    //convert text to emoji
    let result = checkText(text)
    let countWord = result.length;
    if (countWord <= maxSizeMessageInWord) {
      setText(result);
      setCountWordMesssage(countWord)
    }

    if (countWord > maxSizeMessageInWord) {
      let maxSizeMessageInWordInText = result.substring(0, maxSizeMessageInWord);
      setText(maxSizeMessageInWordInText);
      setCountWordMesssage(maxSizeMessageInWordInText.length)
    }


  }

  var flagOnUpload = false

  const onSubmit = async (e: any) => {
    e.preventDefault()
    let textMessage = text;
    const options = {
      language: browserLanguage,
    }
    if (file && !flagOnUpload) {
      flagOnUpload = true
      const fileName = uuidv4()
      const attachmentService = AttachmentService.getInstance()
      await attachmentService
        .uploadObject(
          file,
          activeChannelArn.substring(activeChannelArn.lastIndexOf('/') + 1),
          fileName,
        )
        .on('httpUploadProgress', (evt) => {
          setProgressCurrent(Math.round((evt.loaded / evt.total) * 100))
        })
        .send(async (err: any, data: any) => {
          flagOnUpload = false
          if (err) {
            if (err.code == 'NetworkingError') {
              setNetworkError(true)
            }
            changeUploadFail(true)
          } else {
            const optionAttach = JSON.stringify({
              ...options,
              attachment: {
                fileKey: data.Key,
                location: data.Location,
                name: file.name,
                size: file.size,
                type: file.type,
              },
            })

            await sendChannelMessage(
              activeChannelArn,
              text || ' ',
              Persistence.PERSISTENT,
              MessageType.STANDARD,
              memberSender,
              optionAttach,
            )
            updateChannelReadMarker(activeChannelArn, memberSender.userId)
            resetState()
          }
        })
    } else {
      if ((textMessage.trim() !== "") && (countWordMesssage < maxSizeMessageInWord)) {
        await sendChannelMessage(
          activeChannelArn,
          textMessage,
          Persistence.PERSISTENT,
          MessageType.STANDARD,
          memberSender,
          JSON.stringify(options),
        )
        updateChannelReadMarker(activeChannelArn, memberSender.userId)
        resetState()
      }
    }
  }

  const handleChange = (e: any) => {
    changeUploadFail(false)
    const file = e.target.files[0]

    if (file) {
      if (file.size <= maxSize) {
        setFile(file)
      } else {
        setFileSizeWarn({
          popup: true,
          message: `Cannot upload file. File size is larger than ${maxSizeInMB} MB`,
        })
      }
      inputRef.current.focus()
      e.target.value = null
    }
  }

  const handleOnPaste = (e: any) => {
    changeUploadFail(false)
    const file = e.files[0]
    if (file) {
      if (file.size <= maxSize) {
        setFile(file)
      } else {
        setFileSizeWarn({
          popup: true,
          message: `Cannot upload file. File size is larger than ${maxSizeInMB} MB`,
        })
      }
    }
  }

  const setPopFileSizeWarnFalse = () => {
    setFileSizeWarn({
      popup: false,
      message: '',
    })
  }

  const setNetworkErrorFalse = () => {
    setNetworkError(false)
  }
  const getEmoji = (data: EmojiClickData) => {
    setText(text + data.emoji)
    setOpenEmoji(false)
  }

  const resize = (size: string) => {
    let size_num = Number(size)
    return size_num < 1024
      ? `${size} bytes`
      : size_num < 1024 * 1024
        ? `${String((size_num / 1024).toFixed(2))} KB`
        : `${String((size_num / 1024 / 1024).toFixed(2))} MB`
  }


  useEffect(() => {
    if (inputRef.current) {
      autosize(inputRef.current);

      return () => {
        autosize.destroy(inputRef.current!);
      };
    }
  }, [text, inputRef.current]);


  const insertLineBreak = () => {
    const textarea = inputRef.current;

    if (!textarea) {
      return;
    }

    const { selectionStart, selectionEnd, value } = textarea;
    const newText = `${value.substring(0, selectionStart)}\n${value.substring(selectionEnd)}`;

    setText(newText);

    // Move the cursor to the position after the inserted line break
    const newPosition = selectionStart + 1;
    textarea.setSelectionRange(newPosition, newPosition);
  };

  return (
    <div className="message-input-container">
      <form onSubmit={onSubmit} className="message-input-form">
        <Fragment>
          {file && (
            <Message
              className="image-preview-container"
              info
              error={isUploadFail}
            >
              <div className="image-preview-container-image">
                {file.type.includes('image') ? (
                  <Image
                    style={{
                      width: '200px',
                      maxHeight: '150px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    src={URL.createObjectURL(file)}
                  />
                ) : (
                  <Icon className="icon-file-upload">
                    <File />
                  </Icon>
                )}
                <div className="details">
                  <span>{file.name}</span>
                  <br />
                  <span>{resize(file.size)}</span>
                </div>
                <div
                  className="button-remove-attachment"
                  onClick={() => setFile(null)}
                >
                  <Icon name="remove" />
                </div>
              </div>
              {progressCurrent !== 0 && (
                <Progress
                  error={isUploadFail}
                  value={progressCurrent}
                  total={100}
                  progress="percent"
                  size="tiny"
                  className="progress-bar"
                />
              )}
            </Message>
          )}
          <div className={'input-component'}>
            <textarea
              className="text_area"
              placeholder={t('Type_Your_Message_Label')}
              onChange={onChange}
              value={text}
              onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
                if (e.key === 'Enter' && e.altKey) {
                  e.preventDefault();
                  insertLineBreak();
                } else if (e.key === 'Enter') {
                  onSubmit(e);
                }
              }}
              onPaste={(e: React.ClipboardEvent<HTMLTextAreaElement>) => {
                handleOnPaste(e.clipboardData);
              }}
              ref={inputRef}
            />
          </div>
          <div className={(countWordMesssage >= maxSizeMessageInWord) ? 'input-text-count limited' : 'input-text-count'}>
            <p>{`${countWordMesssage}/${maxSizeMessageInWord}`}</p>
          </div>
          <div className={'input-container'}>
            <div className={'input-container-feature-icon'}>
              <Button
                className="input-button"
                onClick={() => setOpenEmoji(!openEmoji)}
                type="button"
              >
                <Popup
                  content="Emoji"
                  inverted
                  position="top right"
                  trigger={
                    <Icon>
                      <Emoji />
                    </Icon>
                  }
                />
              </Button>

              <Button
                className="input-button"
                type="button"
                onClick={() => {
                  fileInputRef.current.value = null
                  fileInputRef.current.click()
                }}
              >
                <Popup
                  content="Attach file"
                  inverted
                  position="top right"
                  trigger={<Icon name="attach" />}
                />
              </Button>
            </div>

            <input
              id="file-input"
              accept="file_extension|audio/*|video/*|image/*|media_type"
              multiple={false}
              type="file"
              hidden
              onChange={handleChange}
              ref={fileInputRef}
            />
            <Button
              className="input-button"
              onClick={(e) => {
                onSubmit(e)
              }}
            >
              <Popup
                content="Send message"
                inverted
                position="top right"
                trigger={<Icon name="send" />}
              />
            </Button>
          </div>
        </Fragment>
      </form>

      {openEmoji && (
        <ModalGetEmoji
          isOpen={openEmoji}
          onClose={() => setOpenEmoji(false)}
          getEmoji={(e: EmojiClickData) => getEmoji(e)}
        ></ModalGetEmoji>
      )}

      <ErrorModal
        showError={fileSizeWarn.popup}
        errorMessage={fileSizeWarn.message}
        onSetErrorTrigger={setPopFileSizeWarnFalse}
      />

      <ErrorModal
        showError={networkError}
        errorMessage={
          'Networking Error - Failed to upload file. It may be due to lack of IdM access right "Extend Internet Web Access File Sharing"'
        }
        onSetErrorTrigger={setNetworkErrorFalse}
      />
    </div>
  )
}

export default InputMessage
