import React from 'react';

import {
  RosterAttendee,
  useAttendeeStatus,
} from 'amazon-chime-sdk-component-library-react';

interface Props {
  /** The Chime attendee ID */
  attendeeId: string;
}

const RosterAttendeeWrapper: React.FC<Props> = ({ attendeeId }) => {
  const { videoEnabled } = useAttendeeStatus(attendeeId);
  return (
    <div style={{ border: "1px solid rgba(0, 0, 0, 1)" }}>
      <RosterAttendee
        attendeeId={attendeeId}
      // menu={
      //   videoEnabled ? <VideoStreamMetrics attendeeId={attendeeId} /> : null
      // }
      />
    </div>
  );
};

export default RosterAttendeeWrapper;
