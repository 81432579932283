import React, { Fragment, useEffect, useState, useRef, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Button, Modal } from "semantic-ui-react";
import { createChannelMembership } from '../../api/ChimeAPI';
import { selectAccount } from '../../redux/account';
import { useTranslation } from "react-i18next";
interface ModalJoinCurrentChannelProps {
    isOpen: boolean,
    onClose: Function,
    onJoinFinish: Function,
    data: any
}

const ModalJoinCurrentChannel = ({ isOpen, onClose, onJoinFinish, data }: ModalJoinCurrentChannelProps) => {
    const { t } = useTranslation();
    const account = useSelector(selectAccount);
    const [isLoading, setIsLoading] = useState(false);

    const onJoinChannel = async () => {
        const result = await onAddMember()
        setIsLoading(false)
        onJoinFinish(result);
    }

    const onAddMember = async () => {

        try {
            setIsLoading(true)
            await createChannelMembership(
                data.ChannelArn,
                `${process.env.REACT_APP_AWS_APP_INSTANCE_ARN}/user/${account.chimeUserId}`,
                data.CreatedBy.Arn.substring(data.CreatedBy.Arn.lastIndexOf('/') + 1)
            );
            return true;
        } catch {
            console.log("Error occurred. Member not added to channel.");
            return false;
        }
    };

    return (
        <Fragment>
            <Modal
                onClose={() => onClose()}
                open={isOpen}
                dimmer={{ className: "test-dimmer" }}
                size={"mini"}
                closeIcon
                closeOnDimmerClick={false}
            >
                <Modal.Header style={{ textAlign: "center" }}>{t("Start_Channel_Session")}</Modal.Header>
                <Modal.Content>
                    <Grid divided="vertically">
                        <Grid.Row style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                            {t("Start_Channel_Session_Content") + data?.Name}
                        </Grid.Row>

                        <Grid.Row>
                            <Button.Group style={{ width: "100%" }}>
                                <Button onClick={() => onClose()} className="button-search" style={{ width: "50%" }}>{t("Cancel_Label")}</Button>
                                <Button loading={isLoading} onClick={onJoinChannel} primary className="button-search" style={{ width: "50%" }} >{t("Join_Lable")}</Button>
                            </Button.Group>
                        </Grid.Row>
                    </Grid>
                </Modal.Content>
            </Modal>
        </Fragment>

    );
};

export default ModalJoinCurrentChannel;
