import React, { Fragment } from 'react';
import { Grid } from 'semantic-ui-react';

import CameraDevices from './CameraDevices';
import MicrophoneDevices from './MicrophoneDevices';
import SpeakerDevices from './SpeakerDevices';

const DeviceSelection = () => (
    <Fragment>
        <Grid divided='vertically'>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <MicrophoneDevices />
                    <SpeakerDevices />
                </Grid.Column>
                <Grid.Column>
                    <CameraDevices />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Fragment>
);

export default DeviceSelection;
