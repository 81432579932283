export const TRANSLATIONS_PT = {
	Logo_Title_Label: "Conversa privada",
	Service_Assist_Label: "Assistente de serviço",
	Start_New_LabelBtn: "Nova comunicação",
	Start_New_Question: "Gostaria de iniciar uma nova comunicação",
	Sms_label: "SMS",
	Email_label: "E-mail",
	Ticket_Id_Label: "Ticket ID",
	Code_label: "Código",
	Channel_List: "Lista de comunicações",
	Chat_Label: "Chat",
	Call_Label: "Chamada",
	Video_Label: "Video",
	Share_Label: "Partilha",
	Stop_Channel_Label: "Parar comunicação",
	Contact_Details_Label: "Detalhes do contato",
	Request_Details_Label: "Solicitar detalhes",
	Attendees_Label: "Participantes",
	Customer_Label: "Cliente",
	Bosch_Expert_Label: "Bosch Expert",
	Type_Your_Message_Label: "Escreva a sua mensagem",
	Stop_Channel_Question: "Quer terminar a conversação?",
	Yes_Label: "Sim",
	Cancel_Label: "Terminar",
	Start_New_Video_Session: "Iniciar nova conversa por video",
	Without_SMS_Label: "Sem SMS",
	Send_SMS_Label: "Enviar SMS",
	Send_Invite_Label: "Enviar convite",
	Log_Out_Question: "Quer sair da sessão?",
	Welcome_Label: "Bem vindo à comunicação privada",
	Login_Label: "Iniciar nova conversa por video",
	Login_Is_Required_Label: "Necessário uma ligação para iniciar a conversação",
	Account_Permission_Label: "Esta conta não têm permissão para iniciar uma conversa",
	Start_Label: "Iniciar",
	Noted_Recommend: "Devido à politica da Bosch na utilização do Firefox, recomendamos a utilização de um explorador diferente para uma melhor experiência durante a conversação privada. Exploradores recomendados: Microsoft Edge e Google Chrome",
	Available_Languages_Label: "Idiomas disponiveis",
	Can_Not_Create_Meeting_Title: "Não é possível iniciar uma nova sessão privada",
	Can_Not_Create_Meeting_Content: "Você ainda se encontra num conversação, para poder iniciar uma nova conversação feche a atual.",
	Leave_meeting_Warning: "Deixe a conversa particular ou termine a sessão para todos. Assim que terminar a sessão esta não poder ser mais utilizada.",
	Leave_Lable: "Sair",
	End_Lable: "Fim",
	Meeting_Ended: "A conversação foi terminada",
	Start_Channel_Session: "Iniciar a sessão",
	Start_Channel_Session_Content: "O técnico utiliza atualmente um outro Ticket ID:",
	Join_Lable: "Unir-se",
	Join_Meeting_Label: "Unir-se à comunicação",
	Meeting_Information_Label: "Informação da comunicação",
	Meeting_ID_Label: "Canal ID",
	Translate_Option_Title: "Opção de tradução",
	Translate_Mode_Label: "Modo de tradução:",
	From_Label: "De:",
	To_Label: "Para:",
	Chat_Room_Label: "Canal Privado",
	Visual_Connect_Label: "Visual Connect",
	Bosch_ID_Label: "Bosch ID",
	Invite_To_Chat_Question: "Como pretende enviar o convite para a ligação da conversa privada?",
	Invite_To_Visual_Question: "Como pretende enviar o convite para a ligação do Visual Connect ?",
	Invite_Sms_Question: "Enviar convite por SMS",
	Invite_To_Chat_By_Ticket_ID: "Enviar convite por Ticket ID",
	Invite_Email_Question: "Enviar convite por Email",
	Link_label: "Link",
	WSA_label: "Workshop Service Assist",
	WSA_Applications_label: "Aplicações",
	WSA_Remote_Diagnostics_label: "Diagnóstico Remoto",
	WSA_Service_Training_label: "Formação",
	WSA_Enter_website_label: "Entre no website",
	WSA_Slogan_label: "Inovação para a vida",
	WSA_contact_info_label: "Informação geral do contato",
	WSA_PSIRT_label: "Segurança do produto (PSIRT)",
	WSA_licences_label: "Inovações, patentes e licenças",
	WSA_purchasing_label: "Compras e logística",
	WSA_copyright_label: "© Robert Bosch GmbH, todos os direitos reservados",
	WSA_copyright_information_label: "Informação corporativa",
	WSA_copyright_notice_label: "Aviso legal",
	WSA_copyright_protectionnotice_label: "Cláusula de privacidade",
	WSA_copyright_terms_label: "Termos e condições",
	WSA_copyright_settings_label: "Ajustes de privacidade",
	Infor_CorporateInformation_Description: "Responsável pela página da Robert Bosch GmbH",
	Infor_DataProtectionNotice_Description: "Cláusula de privacidade do serviço de Diagnóstico Remoto da Bosch",
	Infor_LegalNotice_Description: "Para utilizadores da página web da Robert Bosch GmbH",
	Id_panel: "ID",
	Topic_panel: "Tema",
	Make_panel: "Marca",
	Model_pane: "Modelo",
	Engine_code_panel: "Código do motor",
	Year_panel: "Ano",
	Vin_panel: "VIN",
	Plate_panel: "Matrícula",
	Hardware_panel: "Hardware",
	ar_mode_warning: "O seu equipamento não permite AR",
	Write_something: "Escreva algo para iniciar a conversação",
	Session_title: "Sessão",
	Live_Translation: "Tradução ao vivo",
	Enabled_panel: "Dísponivel",
	Joined_note: "Juntou-se à sessão…",
	Today_panel: "Hoje",
	Read_panel: "Lido",
	Sent_panel: "Enviado",
	Dis_translation: "Nova comunicação",
	En_translation: "Ativar a tradução",
	Terminated_Channel_note: "O técnico da Bosch terminou a sessão",
	Not_start_note: "ainda não inciado!",
	Retry_note: "Por favor, selecione novamente \"repetir\" para voltar a repetir ou feche a janela.",
	Retry_panel: "Repetir",
	Thanks_note: "Obrigado por usar o Serviço Técnico da Bosch. Estamos muito agradecidos de ter tido a oportunidade de hoje o ter podido ajudar. Entre em contato connosco novamente se necessitar de ajuda. Desejamos um bom dia!",
	Continue_panel: "Ativar a tradução ao vivo",
	En_live_translate: "Continuar",
	Translate_from: "Traduzir de",
	Translate_to: "Traduzir para",
	Random_ID: "ID aleatório",
	Anonymous: "Anônima",
	Generated_ID: "ID aleatório foi gerado!",
	Your_ID: "Seu ID aleatório é:"
};