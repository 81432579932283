import forge from 'node-forge'

export class RSADecrypt {
  constructor() {}

  public static async RSADecrypt(awsEncryptedKey: any) {    
    try {
      const encryptedData = awsEncryptedKey.value // the encrypted data as a string
      const privateKeyString = process.env.REACT_APP_RSA_PRIVATEKEY || "";
      const privateKey = forge.pki.privateKeyFromPem(privateKeyString);
      const encryptedBytes = forge.util.decode64(encryptedData);
      const decryptedBytes = privateKey.decrypt(encryptedBytes);
      return decryptedBytes
    } catch (error) {
      console.log("Cannot get AWS keys");
      return null;
    }
  }
}
