export const TRANSLATIONS_ES = {
	Logo_Title_Label: "Canal privado",
	Service_Assist_Label: "Agente Soporte Técnico",
	Start_New_LabelBtn: "Nueva conversación",
	Start_New_Question: "Te gustaría empezar un nuevo Canal Privado?",
	Sms_label: "SMS",
	Email_label: "E-mail",
	Ticket_Id_Label: "Ticket ID",
	Code_label: "Código",
	Channel_List: "Lista de canales",
	Chat_Label: "Chatear",
	Call_Label: "Llamar",
	Video_Label: "Video",
	Share_Label: "Cuota",
	Stop_Channel_Label: "Detener canal",
	Contact_Details_Label: "Detalles de contacto",
	Request_Details_Label: "Solicitar detalles",
	Attendees_Label: "Participantes",
	Customer_Label: "Cliente",
	Bosch_Expert_Label: "Experto en Bosch",
	Type_Your_Message_Label: "Escribe tu mensaje",
	Stop_Channel_Question: "Quieres detener el canal?",
	Yes_Label: "Sí",
	Cancel_Label: "Cancelar",
	Start_New_Video_Session: "Iniciar nueva sesión de vídeo",
	Without_SMS_Label: "Sin SMS",
	Send_SMS_Label: "Enviar SMS",
	Send_Invite_Label: "Enviar invitación",
	Log_Out_Question: "Quieres cerrar sesión?",
	Welcome_Label: "Bienvenido a tu Canal Privado",
	Login_Label: "Iniciar sesión",
	Login_Is_Required_Label: "Se requiere iniciar sesión para iniciar el canal",
	Account_Permission_Label: "Esta cuenta no tiene permiso de inicar un canal",
	Start_Label: "Iniciar",
	Noted_Recommend: "Debido a la política de Bosch en el uso de Firefox, recomendamos el uso de un explorador diferente para obtener la mejor experiencia de usuario en la utilización de la aplicación de Canal Privado. Exploradores recomendados: Microsoft Edge & Google Chrome",
	Available_Languages_Label: "Idiomas disponibles",
	Can_Not_Create_Meeting_Title: "No es posible crear un nuevo Canal Privado",
	Can_Not_Create_Meeting_Content: "Tu estás todavía activo en este Canal Privado",
	Leave_meeting_Warning: "Salir del Canal Privado o finalizar el Canal para todos. El Canal no podrá ser utilizado una vez finalice.",
	Leave_Lable: "Salir",
	End_Lable: "Finalizar",
	Meeting_Ended: "La reunión ha sido finalizada",
	Start_Channel_Session: "Iniciar una nueva sesión",
	Start_Channel_Session_Content: "El Técnico está actualmente en otra con nº de Ticket ID:",
	Join_Lable: "Unirse",
	Join_Meeting_Label: "Unirse al Canal",
	Meeting_Information_Label: "Información del Canal",
	Meeting_ID_Label: "Canal ID",
	Translate_Option_Title: "Opciones de traducción",
	Translate_Mode_Label: "Modo de traducción:",
	From_Label: "De:",
	To_Label: "A:",
	Chat_Room_Label: "Canal Privado",
	Visual_Connect_Label: "Visual Connect",
	Bosch_ID_Label: "Bosch ID",
	Invite_To_Chat_Question: "¿Cómo le gustaría compartir la invitación del Canal Privado?",
	Invite_To_Visual_Question: "¿Cómo le gustaría compartir la invitación al Visual Connect?",
	Invite_Sms_Question: "Enviar invitación por SMS",
	Invite_To_Chat_By_Ticket_ID: "Enviar invitación por Ticket ID",
	Invite_Email_Question: "Enviar invitación por Email",
	Link_label: "Enlace",
	WSA_label: "Servicio de Asistencia para el Taller",
	WSA_Applications_label: "Aplicaciones",
	WSA_Remote_Diagnostics_label: "Diagnosis Remota",
	WSA_Service_Training_label: "Formación",
	WSA_Enter_website_label: "Introducir página web",
	WSA_Slogan_label: "Innovación para tu vida",
	WSA_contact_info_label: "Información General de Contacto",
	WSA_PSIRT_label: "Seguridad de Producto (PSIRT)",
	WSA_licences_label: "Innovaciones, patentes y licencias",
	WSA_purchasing_label: "Compras y logística",
	WSA_copyright_label: "© Robert Bosch GmbH, todos los derechos reservados",
	WSA_copyright_information_label: "Información corporativa",
	WSA_copyright_notice_label: "Aviso Legal",
	WSA_copyright_protectionnotice_label: "Cláusula de Privacidad",
	WSA_copyright_terms_label: "Términos y Condiciones",
	WSA_copyright_settings_label: "Ajustes de Privacidad",
	Infor_CorporateInformation_Description: "Responsable de la página de internet de Robert Bosch GmbH",
	Infor_DataProtectionNotice_Description: "Cláusula de Privacidad del Servicio de Diagnosis Remota de Bosch",
	Infor_LegalNotice_Description: "Para usuarios de las páginas de internet de Robert Bosch GmbH",
	Id_panel: "ID",
	Topic_panel: "Tema",
	Make_panel: "Marca",
	Model_pane: "Modelo",
	Engine_code_panel: "Código motor",
	Year_panel: "Año",
	Vin_panel: "VIN",
	Plate_panel: "Matrícula",
	Hardware_panel: "Hardware",
	ar_mode_warning: "Su dispositivo no está disponible para AR",
	Write_something: "Escribe algo para iniciar la Conversación",
	Session_title: "Sesión",
	Live_Translation: "Traducción en vivo",
	Enabled_panel: "Permitido",
	Joined_note: "Te has unido a la sesión…",
	Today_panel: "Hoy",
	Read_panel: "Leer",
	Sent_panel: "Enviado",
	Dis_translation: "Desabilitar Traducción",
	En_translation: "Permitir traducción en vivo",
	Terminated_Channel_note: "El Agente de Bosch ha finalizado el canal",
	Not_start_note: "¡no se ha iniciado todavía!",
	Retry_note: "Por favor hacer click en \"Reintentar\" para volver a probar o cerrar esta ventana para salir.",
	Retry_panel: "Reintentar",
	Thanks_note: "Gracias por usar el Soporte Técnico de Bosch. Estamos muy agradecidos de poderle ayudar hoy. Por favor contacta de nuevos con nosotros si necesita ayuda. ¡Que tenga un día esupendo!",
	Continue_panel: "Permitir traducción en vivo",
	En_live_translate: "Continuar",
	Translate_from: "Traducir de",
	Translate_to: "Traducir a",
	Random_ID: "ID aleatoria",
	Anonymous: "Anónima",
	Generated_ID: "Se ha generado una ID aleatoria!",
	Your_ID: "Tu ID aleatoria es:"
};