import React, { Fragment, useEffect, useState } from "react";
import Styles from "./AssistantDashBoard.module.css";
import { useTranslation } from "react-i18next";
import Messages from "../LandingPage/botWidgetLauncher/MessageList";
import UserInput from "../LandingPage/botWidgetLauncher/UserInput";
import { useSelector } from "react-redux";
import { actionTypeENUM, addMessageItem, clearAssistantState, clearStateAfterEndChannel, funtionalTypeENUM, messageListModal, selectAssistantMessageListState, selectChatChannelHistory, selectFeatureType, selectOnSaveHistoryMessages, selectOnStartAChatChannel, selectPresenceOfUserInPublicChannel, selectShowLoadingDot, selectStepAction, selectUserChime, senderENUM, senderNameType, setActiveState, setChatChannelHistory, setOnSaveHistoryMessages, setOnStartAChatChannel, setPresenceOfUserInPublicChannel, setSelectedFeatureState, setStepState, setUserChime, stepENUM } from "../../../redux/assistantBot";
import { useAppDispatch } from "../../../redux/store";
import PublicInputMessage from "../PublicDashBoard/PublicInputChat/PublicInputMessage";
import { ChannelClearState, ChannelSetMessage, SetIsTranslate, SetNewMemberJoinChannel, channelSetChannel, selectChannelArn, selectChannelMessage, selectIsTranslate } from "../../../redux/chimeSlide";
import { MessageType, Persistence, createChannelMembership, createMemberArn, describeChannel, listChannelMemberships, listChannelMembershipsForAppInstanceUser, listChannelMessages, listChannels, sendChannelMessage } from "../../../api/ChimeAPI";
import { v4 } from "uuid"
import { CommonService } from "../../../services/Common";
import AWS from "aws-sdk";
import { RSADecrypt } from "../../../services/RSA_Encrypt";
import MessagingService from "../../../services/MessagingService";
import ModalTranlationOptionsPublic from "../../modals/publicChannelModal/ModalTranlationOptionsPublic";
import addNotification from "react-push-notification";
import { OnlinePresenceStatus, TimeOutOnlineStatus, TimeOutOnlineStatusChange, TimeOutOnlineStatusTrigger, TimeOutOnlineStatusTriggerCountDown, countryOptions } from "../../../helpers/AppConstants";
import { Button } from "semantic-ui-react";
import Boschicon from "../../../common-components/boschIcon/Boschicon";
import CustomLoader from "../../../common-components/RDComponents/CustomLoader";
import RDAlert from "../../../common-components/RDComponents/RDAlert";
import { MemberPresence } from "../LandingPage/botWidgetLauncher/BotWidget";
import { selectMeetingState } from "../../../redux/meeting";

export interface languageDetectedCode {
  browserLang: string,
  messageLang: string
}

const AssistantDashBoard = () => {
  const { t } = useTranslation();
  const showLoadingDot = useSelector(selectShowLoadingDot);
  const dispatchReduxToolkit = useAppDispatch();
  const featureType = useSelector(selectFeatureType);
  const stepAction = useSelector(selectStepAction);
  const onStartAChatChannel = useSelector(selectOnStartAChatChannel);
  const channelArn = useSelector(selectChannelArn);
  const [updatedListMessage, setUpdatedListMessage] = useState<messageListModal[]>([]);
  const [updatedListChannelMessage, setUpdatedListChannelMessage] = useState<messageListModal[] | null>(null);
  const assistantMessageListState = useSelector(selectAssistantMessageListState);
  const [channelArnString, setChannelArnString] = useState<string>("");
  const [hasChimeRegisted, setHasChimeRegisted] = useState<boolean>(false);
  const browserLanguage = window.navigator.language;
  const [isLoading, setIsLoading] = useState(false);
  const userChime = useSelector(selectUserChime);
  const [messagingService] = useState(MessagingService.getInstance());
  const [onDetectedLanguage, setOnDetectedLanguage] = useState<boolean>(false);
  const [errorModalMessage, setErrorModalMessage] = useState<string>("");
  const [displayErrorModal, setDisplayErrorModal] = useState<boolean>(false);
  const [displayDeleteModal, setDisplayDeleteModal] = useState<boolean>(false);
  const [onLoginAwsSuccess, setOnLoginAwsSuccess] = useState<boolean>(false);
  const [displaydetectedLanguageModal, setDisplaydetectedLanguageModal] = useState<boolean>(false);
  const [displaySelectLanguageModal, setDisplaySelectLanguageModal] = useState<boolean>(false);
  const [onLoadChannelMessageSuccess, setOnLoadChannelMessageSuccess] = useState<boolean>(false);
  const [messageLanguageDetectedModal, setMessageLanguageDetectedModal] = useState<string>("");
  const isTranslate = useSelector(selectIsTranslate);
  const [languageDetectedCode, setLanguageDetectedCode] = useState<languageDetectedCode | null>(null);
  const onSaveHistoryMessages = useSelector(selectOnSaveHistoryMessages);
  const chatChannelHistory = useSelector(selectChatChannelHistory);
  const [channelMessageState, setChannelMessageState] = useState<any>(null);
  const [memberList, setMemberList] = useState([]);
  const listPresenceOfUserInChannel = useSelector(selectPresenceOfUserInPublicChannel);
  const [memberPresenceList, setMemberPresenceList] = useState<MemberPresence[]>([])
  const meetingData = useSelector(selectMeetingState);
  let uuid = v4();

  var tabHasFocus = true;

  const handleFocus = () => {
    tabHasFocus = true;
  };

  const handleBlur = () => {
    tabHasFocus = false;
  };

  useEffect(() => {
    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  useEffect(() => {
    if (assistantMessageListState.length === 0) {
      let currentTime = getCurrentTime();
      dispatchReduxToolkit(addMessageItem({
        sender: senderENUM.assistant,
        message: `${t("Assistant_first_message")}`,
        datetime: currentTime,
      }));
      dispatchReduxToolkit(setActiveState(true));
    }
  }, [assistantMessageListState])

  const getCurrentTime = () => {
    const userLocale = navigator.language;
    const now = new Date();
    const timeFormat = new Intl.DateTimeFormat(userLocale, {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });

    return timeFormat.format(now);
  };

  const hanldeCloseState = () => {
    setTimeout(() => { dispatchReduxToolkit(clearAssistantState()) }, 1000)
  };

  const hanldeOpenIframe = () => {
    if (featureType?.type === funtionalTypeENUM.chatting) {
      if (tabHasFocus) {
        dispatchReduxToolkit(setOnStartAChatChannel(true))
      }
    }

    if (featureType?.type === funtionalTypeENUM.vsc) {
      window.open(
        `/assistant/publicMeeting/?sessionId=${featureType?.code}&name=${featureType?.name}`,
        'null',
        'popup,width=1200,height=680,left=300,top=500, menubar=0, toolbar=0, scrollbar=1',
      )
      hanldeCloseState()
    }
  };

  const hanldeOnSubmitInput = (data: { type: number, data: any }) => {
    let actionType = data.type;
    let actionData = data.data;
    let currentTime = getCurrentTime();
    let name = featureType?.name || "";
    let code = featureType?.code || "";
    let nextStep = 0;
    switch (actionType) {
      case actionTypeENUM.chooseOption:
        if (actionData === funtionalTypeENUM.change) {
          nextStep = stepENUM.typeEdit
          dispatchReduxToolkit(addMessageItem({
            sender: senderENUM.user,
            message: t("Change_info_label"),
            datetime: currentTime,
          }));
          //use time out to delay response message of bot
          setTimeout(() => {
            dispatchReduxToolkit(setStepState(stepENUM.typeEdit));
          }, 1000)
        }

        if (actionData === funtionalTypeENUM.changeName) {
          nextStep = stepENUM.typeEditName
          dispatchReduxToolkit(addMessageItem({
            sender: senderENUM.user,
            message: t("Change_name_label"),
            datetime: currentTime,
          }));
          //use time out to delay response message of bot
          setTimeout(() => {
            dispatchReduxToolkit(setStepState(stepENUM.typeEditName));
          }, 1000)
        }

        if (actionData === funtionalTypeENUM.changeCode) {
          nextStep = stepENUM.typeEditCode
          dispatchReduxToolkit(addMessageItem({
            sender: senderENUM.user,
            message: t("Change_code_label"),
            datetime: currentTime,
          }));
          //use time out to delay response message of bot
          setTimeout(() => {
            dispatchReduxToolkit(setStepState(stepENUM.typeEditCode));
          }, 1000)
        }

        if (actionData === funtionalTypeENUM.vsc || actionData === funtionalTypeENUM.chatting) {
          dispatchReduxToolkit(addMessageItem({
            sender: senderENUM.user,
            message: actionData === funtionalTypeENUM.vsc ? t("Visual_Connect_Pro_btn") : t("Join_chat_btn"),
            datetime: currentTime,
          }));
          nextStep = stepENUM.typeName
          //use time out to delay response message of bot
          setTimeout(() => {
            dispatchReduxToolkit(setStepState(stepENUM.typeName));
            dispatchReduxToolkit(setSelectedFeatureState({
              type: actionData === funtionalTypeENUM.vsc ? funtionalTypeENUM.vsc : funtionalTypeENUM.chatting,
              name: "",
              code: ""
            }));
          }, 1000)
        }
        if (actionData === funtionalTypeENUM.start) {
          hanldeOpenIframe()
        }
        break;
      case actionTypeENUM.inputText:
        dispatchReduxToolkit(addMessageItem({
          sender: senderENUM.user,
          message: actionData,
          datetime: currentTime,
        }));

        if (stepAction === stepENUM.typeName) {
          name = actionData;
          nextStep = stepENUM.typeIdNumber
          setTimeout(() => {
            dispatchReduxToolkit(setStepState(stepENUM.typeIdNumber));
          }, 1000)
          dispatchReduxToolkit(setSelectedFeatureState({
            type: featureType?.type,
            name: actionData,
            code: ""
          }));
        }
        if (stepAction === stepENUM.typeIdNumber) {
          code = actionData
          nextStep = stepENUM.typeStart
          setTimeout(() => {
            dispatchReduxToolkit(setStepState(stepENUM.typeStart));
          }, 1000)
          dispatchReduxToolkit(setSelectedFeatureState({
            type: featureType?.type,
            name: featureType?.name || "",
            code: actionData
          }));
        }
        if (stepAction === stepENUM.typeEditName) {
          name = actionData;
          nextStep = stepENUM.typeStart
          setTimeout(() => {
            dispatchReduxToolkit(setStepState(stepENUM.typeStart));
          }, 1000)
          dispatchReduxToolkit(setSelectedFeatureState({
            type: featureType?.type,
            name: actionData,
            code: featureType?.code || "",
          }));
        }
        if (stepAction === stepENUM.typeEditCode) {
          code = actionData
          nextStep = stepENUM.typeStart
          setTimeout(() => {
            dispatchReduxToolkit(setStepState(stepENUM.typeStart));
          }, 1000)
          dispatchReduxToolkit(setSelectedFeatureState({
            type: featureType?.type,
            name: featureType?.name || "",
            code: actionData
          }));
        }
        break;
      default:
        break;
    }
    triggerBotAction(code, name, nextStep);
  };

  const triggerBotAction = (code?: string, name?: string, nextStep?: number) => {
    let currentTime = getCurrentTime();
    // settimeout to delay response message of Bot
    setTimeout(() => {
      switch (nextStep) {
        case stepENUM.typeName:
          dispatchReduxToolkit(addMessageItem({
            sender: senderENUM.assistant,
            message: `${t("assistant_ask_name_message")}`,
            datetime: currentTime,
          }));
          break;
        case stepENUM.typeIdNumber:
          dispatchReduxToolkit(addMessageItem({
            sender: senderENUM.assistant,
            message: `${t("assistant_ask_code_message")}`,
            datetime: currentTime,
          }));
          break;
        case stepENUM.typeStart:
          const text = t("assistant__confirm_message").replace("[code]", code || "").replace("[name]", name || "");
          dispatchReduxToolkit(addMessageItem({
            sender: senderENUM.assistant,
            message: text,
            datetime: currentTime,
          }));
          break;
        case stepENUM.typeEdit:
          dispatchReduxToolkit(addMessageItem({
            sender: senderENUM.assistant,
            message: t("assistant_ask_change_message"),
            datetime: currentTime,
          }));
          break;
        case stepENUM.typeEditName:
          dispatchReduxToolkit(addMessageItem({
            sender: senderENUM.assistant,
            message: t("assistant_ask_change_name_message"),
            datetime: currentTime,
          }));
          break;
        case stepENUM.typeEditCode:
          dispatchReduxToolkit(addMessageItem({
            sender: senderENUM.assistant,
            message: t("assistant_ask_change_code_message"),
            datetime: currentTime,
          }));
          break;
        default:
          break;
      }
    }, 1000)


  };

  useEffect(() => {
    progressMessages();
  }, [channelMessageState, assistantMessageListState, onSaveHistoryMessages, onLoadChannelMessageSuccess])

  useEffect(() => {
    updateMessage()
  }, [channelArn, isTranslate, onLoginAwsSuccess])

  useEffect(() => {
    if (!channelArn) {
      setUpdatedListChannelMessage(null)
      setChannelMessageState(null)
    }
  }, [channelArn])

  const updateMessage = async () => {
    if (onLoginAwsSuccess && channelArn && channelArn !== '') {
      const newMessages = await listChannelMessages(
        channelArn,
        userChime?.userId
      )

      let listNewChannelMessage = newMessages.Messages ? newMessages.Messages.filter((m: any, i, seif) => {
        let checkType = m.Metadata ? JSON.parse(m.Metadata).type : ""
        if (checkType === 'history') {
          return false;
        }
        return true;
      }) : [];

      const message_translate = await Promise.all(
        listNewChannelMessage.map(async (message: any) => {
          if (
            createMemberArn(userChime?.userId) !== message.Sender.Arn &&
            isTranslate.status
          ) {
            const translateMessage = await CommonService.liveTranslateMessage(
              message.Content,
              isTranslate.langSource,
              isTranslate.langTarget,
            )
            return { ...message, Content: translateMessage }
          }
          return message
        }),
      )
      await dispatchReduxToolkit(
        ChannelSetMessage({
          ChannelMessages: message_translate,
          NextToken: newMessages.NextToken,
        }),
      )
      await setChannelMessageState(
        newMessages ? {
          ChannelMessages: message_translate,
          NextToken: newMessages.NextToken,
        } : null
      )
      setOnLoadChannelMessageSuccess(true);
    }
  }

  const progressMessages = async () => {
    let tempMessageList: messageListModal[] = [];
    if (assistantMessageListState) {
      tempMessageList = assistantMessageListState;
    }

    if (channelArn && channelMessageState && channelMessageState.ChannelMessages && onLoadChannelMessageSuccess) {
      let tempChannelMessage = await channelMessageState.ChannelMessages.map((m: any) => {
        const senderArn = m?.Sender?.Arn;
        let senderType = senderENUM.boschExpert;
        let currentTime = getCurrentTime();
        if (senderArn.includes(`${userChime?.userId}`)) {
          senderType = senderENUM.user
        } else {
          senderType = senderENUM.member
        }

        if (senderArn.includes(`@bosch.com`)) {
          senderType = senderENUM.boschExpert
        }
        return {
          sender: senderType,
          message: m.Content,
          datetime: currentTime,
          metaData: m.Metadata,
          messageId: m.MessageId || "",
          senderName: m.Sender?.Name || "",
          senderArn: m.Sender?.Arn || ""
        }
      })
      if (!onSaveHistoryMessages && !chatChannelHistory) {
        let checkedTempChannelMessage = tempChannelMessage.length === 0 ? ["empty"] : tempChannelMessage;
        await dispatchReduxToolkit(
          setChatChannelHistory(checkedTempChannelMessage),
        )
        await dispatchReduxToolkit(
          setOnSaveHistoryMessages(true),
        )
      }
      setUpdatedListChannelMessage(tempChannelMessage);
    }

    setUpdatedListMessage(tempMessageList);
  }

  /* Chat Channel has started */
  const beforeUnloadHandler = (event: any) => {
    event.preventDefault();
    event.returnValue = true;
  };

  useEffect(() => {
    if (channelArnString !== "") {
      window.addEventListener("beforeunload", beforeUnloadHandler);
    } else {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    }
  }, [channelArnString]);

  // register user to chimeSDK
  useEffect(() => {
    if (featureType && !userChime && onStartAChatChannel) {
      setIsLoading(true);
      let memberTemp = {
        username: "anonymous",
        userId: "adfs.anonymous",
      };
      let newMemberChime = {
        ciamId: memberTemp.userId,
        name: memberTemp.username,
        mobile: "",
        email: ""
      };
      if (featureType.name && featureType.name !== "") {
        let nameTrim = featureType.name.replace(/\s/g, '');
        memberTemp.username = nameTrim;
        memberTemp.userId = `adfs.anonymous_${nameTrim.toLowerCase()}_` + `${uuid}`;
        newMemberChime.ciamId = memberTemp.userId;
        newMemberChime.name = memberTemp.username;
      } else {
        memberTemp.userId = `adfs.anonymous_` + `${uuid}`;
        newMemberChime.ciamId = memberTemp.userId;
        newMemberChime.name = memberTemp.username;
      }
      CommonService.getChimeUserForPublic(newMemberChime);
      dispatchReduxToolkit(setUserChime(memberTemp));
    }
    if (userChime) {
      setHasChimeRegisted(true);
    }
  }, [featureType, onStartAChatChannel, userChime]);

  //Authen to AWS SDK
  useEffect(() => {
    if (userChime && !hasChimeRegisted) {
      AWS.config.region = process.env.REACT_APP_REACT_REGION;
      const setAwsKeysAndConnect = async () => {
        const awsKeys = await CommonService.getChimeSecretPublic();
        if (awsKeys) {
          const awsKeysDecrypt = await RSADecrypt.RSADecrypt(awsKeys);
          if (awsKeysDecrypt) {
            let awsKeysDecryptData = JSON.parse(awsKeysDecrypt!)
            AWS.config.credentials = {
              accessKeyId: awsKeysDecryptData.AccessKey,
              secretAccessKey: awsKeysDecryptData.SecretKey,
            };
            console.log("Get AWS keys Success");
          }
          if (featureType?.code && featureType.code !== "") {
            excuteStartChannel(featureType.code);
          }
        } else {
          console.log("Cannot get AWS keys");
        }
        await messagingService.connect(userChime);
        setOnLoginAwsSuccess(true)
      }
      setAwsKeysAndConnect();
      return () => {
        messagingService.close();
      };
    }
  }, [hasChimeRegisted, userChime]);

  useEffect(() => {
    if (userChime || !onDetectedLanguage || onLoginAwsSuccess || channelArn) {
      messagingService.subscribeToMessageUpdate(messagesProcessor);
    }

    return () => {
      messagingService.unsubscribeFromMessageUpdate(messagesProcessor);
    };
  }, [messagingService, userChime, onDetectedLanguage, onLoginAwsSuccess, channelArn]);

  const messagesProcessor = async (message: any) => {
    const messageType = message?.headers["x-amz-chime-event-type"];
    const record = JSON.parse(message?.payload);
    const currentTime = getCurrentTime();
    switch (messageType) {
      // Channel Messages
      case "CHANNEL_DETAILS":
        break;
      case "CREATE_CHANNEL_MESSAGE":
      case "REDACT_CHANNEL_MESSAGE":
      case "UPDATE_CHANNEL_MESSAGE":
      case "DELETE_CHANNEL_MESSAGE":
      case "DENIED_CREATE_CHANNEL_MESSAGE":
      case "FAILED_CREATE_CHANNEL_MESSAGE":
      case "DENIED_UPDATE_CHANNEL_MESSAGE":
      case "FAILED_UPDATE_CHANNEL_MESSAGE":
      case "PENDING_CREATE_CHANNEL_MESSAGE":
      case "PENDING_UPDATE_CHANNEL_MESSAGE":
        await processChannelMessage(record);
        break;
      case "CREATE_CHANNEL":
      case "UPDATE_CHANNEL":
        break;
      case "DELETE_CHANNEL":
        dispatchReduxToolkit(ChannelClearState());
        setErrorModalMessage(t('Terminated_Channel_note'));
        setDisplayDeleteModal(true);
        // fetchChannels();
        break;
      case "CREATE_CHANNEL_MEMBERSHIP":
        dispatchReduxToolkit(SetNewMemberJoinChannel(true));
        dispatchReduxToolkit(addMessageItem({
          sender: senderENUM.system,
          message: t("Joined_note"),
          datetime: currentTime,
        }));
        break;
      case "UPDATE_CHANNEL_MEMBERSHIP":
        dispatchReduxToolkit(SetNewMemberJoinChannel(true));
        break;
      case "DELETE_CHANNEL_MEMBERSHIP":
        dispatchReduxToolkit(SetNewMemberJoinChannel(true));
        if (record?.Member?.Arn.includes(userChime?.username.toLowerCase())) {
          setErrorModalMessage(t('End_Channel_note'));
          setDisplayDeleteModal(true);
          dispatchReduxToolkit(ChannelClearState());
        }
        break;
      default:
        console.log(`Unexpected message type! ${messageType}`);
    }
  };

  const excuteStartChannel = async (ticketIdParam: string) => {
    setIsLoading(true);
    // Create a messaging session
    const channelName = ticketIdParam; // Replace with the channel name
    const anonymousUserId = userChime?.userId; // Replace with the anonymous user ID
    const response = await listChannels(process.env.REACT_APP_AWS_APP_INSTANCE_ARN, anonymousUserId);
    const exitChannel = response.find(
      (x: any) =>
        x.Name == channelName
    );
    if (exitChannel) {
      const channelArn = exitChannel.ChannelArn;
      const memberArn = JSON.parse(exitChannel.Metadata)?.memberArn;
      if (channelArn) {
        connectChannel(
          channelArn,
          userChime ? userChime.userId : "",
          memberArn
        );
        setIsLoading(false);
        setChannelArnString(channelArn)
      } else {
        setIsLoading(false);
        setErrorModalMessage("Cannot create new Channel");
        setDisplayErrorModal(true);
      }
    } else {
      setIsLoading(false);
      setErrorModalMessage(`${t('Session_title')} ${channelName} ${t('Not_start_note')} `);
      setDisplayErrorModal(true);
    }

  }

  const connectChannel = async (
    channelDescribe: string,
    userId: string,
    memberArn: string
  ) => {
    const promise = Promise.resolve(channelDescribe);
    const channelArnCurrent = await promise.then((m) => m);
    const channel = await describeChannel(channelArnCurrent, userId);
    const memberShip = await listChannelMemberships(
      channelArnCurrent,
      userId,
    )
    const exitMember = memberShip.find(
      (x: any) =>
        x.Member.Name == userChime?.username
    );
    if (channel) {
      if (!exitMember) {
        const statusAdd = await onAddMember(channel, memberArn, userId);
        if (statusAdd) {
          dispatchReduxToolkit(channelSetChannel(channel));
          if (channelArnCurrent && channelArnCurrent !== '') {
            const newMessages = await listChannelMessages(
              channelArnCurrent,
              userId
            )
            const message_translate = await Promise.all(
              newMessages.Messages.map(async (message: any) => {
                if (
                  createMemberArn(userId) !== message.Sender.Arn &&
                  isTranslate.status
                ) {
                  // const sourceLanguageCode = JSON.parse(message.Metadata)?.language || "";
                  const translateMessage = await CommonService.liveTranslateMessage(
                    message.Content,
                    isTranslate.langSource,
                    isTranslate.langTarget,
                  )
                  return { ...message, Content: translateMessage }
                }
                return message
              }),
            )
            await dispatchReduxToolkit(
              ChannelSetMessage({
                ChannelMessages: message_translate,
                NextToken: newMessages.NextToken,
              }),
            )

          }
          fetchChannels();
        }
      }
    } else {
      console.log("Error, could not retrieve channel information.");
    }
  };

  const processChannelMessage = async (message: any) => {
    const promise = Promise.resolve(message);
    const newMessage = await promise.then((m) => m);
    let browserLanguage = window.navigator.language;
    let messageDetectLanguage = await CommonService.detectLanguage({ text: newMessage.Content });
    //enable detected language
    if (newMessage.Persistence === Persistence.NON_PERSISTENT && newMessage.Type === MessageType.CONTROL) {
      dispatchReduxToolkit(setPresenceOfUserInPublicChannel(newMessage));
    } else {
      //enable detected language
      if ((!browserLanguage.toLocaleLowerCase().includes(messageDetectLanguage)) && (newMessage.Sender.Arn !== createMemberArn(userChime?.userId))) {
        await processChannelMessageDetectLanguage(browserLanguage, messageDetectLanguage);
      }
      let metaData = JSON.parse(newMessage.Metadata)
      let checkHistoryType = metaData.type ? (metaData.type === 'history' ? true : false) : false;
      //send noti in browser
      if (!tabHasFocus && !checkHistoryType) {
        addNotification({
          title: newMessage.Sender.Name + " sent you a message",
          message: newMessage.Content,
          theme: "darkblue",
          duration: 10000,
          native: true,
          onClick: notifiOnclick,
        });
      }
    }
  };

  const processChannelMessageDetectLanguage = async (browserLanguage: string, messageDetectLanguage: string) => {
    if (!onDetectedLanguage) {
      let getCountyByBrowserLanguage = await getTextByKey(browserLanguage);
      let getCountyByMessageDetectLanguage = await getTextByKey(messageDetectLanguage);
      setOnDetectedLanguage(true)
      const messageForModal = t("Translation_is_available_detail").replace("[tech_language]", getCountyByBrowserLanguage || "").replace("[expert_language]", getCountyByMessageDetectLanguage || "");
      await setMessageLanguageDetectedModal(messageForModal)
      setDisplaydetectedLanguageModal(true)
      setLanguageDetectedCode({
        browserLang: browserLanguage,
        messageLang: messageDetectLanguage
      })
    }
  }

  const getTextByKey = (key: string) => {
    let country = countryOptions.find(option => option.key.includes(key));
    if (!country) {
      country = countryOptions.find(option => key.includes(option.key));
    }
    return country ? country.text : undefined;
  }

  const onHanldeStartLiveTranslation = () => {
    dispatchReduxToolkit(SetIsTranslate({
      status: true,
      langSource: languageDetectedCode?.messageLang,
      langTarget: languageDetectedCode?.browserLang,
      languagebox: true
    }));
    setDisplaydetectedLanguageModal(false)
  };
  const onCancelStartLiveTranslation = () => {
    dispatchReduxToolkit(SetIsTranslate({
      status: false,
      langSource: languageDetectedCode?.messageLang,
      langTarget: languageDetectedCode?.browserLang,
      languagebox: false
    }));
    setDisplaydetectedLanguageModal(false)
  };

  const notifiOnclick = () => {
    window.focus();
  };

  const onTrigger = () => {
    setDisplayErrorModal(false)
    dispatchReduxToolkit(setOnStartAChatChannel(false))
  }

  const onDeleteChannel = () => {
    dispatchReduxToolkit(clearStateAfterEndChannel())
    setDisplayDeleteModal(false)
    dispatchReduxToolkit(setOnStartAChatChannel(false))
    dispatchReduxToolkit(ChannelClearState())
    dispatchReduxToolkit(SetIsTranslate({
      status: false,
      langSource: "auto",
      langTarget: "auto",
      languagebox: false
    }));
    setDisplaydetectedLanguageModal(false);
    setDisplaySelectLanguageModal(false)
    setOnDetectedLanguage(false)
    window.close()
  }

  const onAddMember = async (channel: any, memberArn: string, userId: string) => {
    const promise = Promise.resolve(channel);
    const channelArn = await promise.then((m) => m.ChannelArn);

    try {
      // this function have swap position of memberArn and annonymous arn
      const membership = await createChannelMembership(
        channelArn,
        `${process.env.REACT_APP_AWS_APP_INSTANCE_ARN}/user/${userId}`,
        memberArn,
      );
      return membership;
    } catch {
      console.log("Error occurred. Member not added to channel.");
    }
  };

  const fetchChannels = async () => {
    const userChannelMemberships =
      await listChannelMembershipsForAppInstanceUser(userChime?.userId);
    userChannelMemberships
      .map((channelMembership: any) => channelMembership.ChannelSummary)
      .sort((current: any, next: any) => {
        try {
          const newDate = new Date();
          const nextLast = next.LastMessageTimestamp
            ? next.LastMessageTimestamp.getTime()
            : newDate.getTime();
          const currentLast = current.LastMessageTimestamp
            ? current.LastMessageTimestamp.getTime()
            : newDate.getTime();

          return nextLast - currentLast;
        } catch {
          return null;
        }
      });

  };

  const handleOnOpenTranslateModal = (status: boolean) => {
    if (!status) {
      dispatchReduxToolkit(SetIsTranslate({
        ...isTranslate,
        status: false,
        languagebox: false
      }))
    }
    setDisplaySelectLanguageModal(status)
  };



  //send ping message to channel
  useEffect(() => {
    let memberObject = {
      userId: userChime?.userId,
    };
    let isExitedMetting = meetingData?.Meeting.meetingId ? true : false;
    const intervalId = setInterval(() => {
      sendChannelMessage(
        channelArn,
        'online',
        Persistence.NON_PERSISTENT,
        MessageType.CONTROL,
        memberObject
      );
    }, TimeOutOnlineStatus);
    if ((!tabHasFocus && !isExitedMetting) || !channelArn || !userChime) {
      clearInterval(intervalId);
    }
    // send onhold signal
    if (!tabHasFocus && channelArn && userChime) {
      sendChannelMessage(
        channelArn,
        isExitedMetting ? 'busy' : 'onhold',
        Persistence.NON_PERSISTENT,
        MessageType.CONTROL,
        memberObject
      );
    }
    return () => clearInterval(intervalId);
  }, [tabHasFocus, channelArn, userChime, meetingData]);

  //job trigger detect presence of user
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     let listMemberPresent = memberList.map((m: any) => {
  //       //default online status
  //       let newObject: MemberPresence = {
  //         userArn: m.Member.Arn,
  //         status: OnlinePresenceStatus.online
  //       };
  //       let findMemberByArn = listPresenceOfUserInChannel.find((user: any) => m.Member.Arn === user.userArn)
  //       //check ping after 60s
  //       if (findMemberByArn && !m.Member.Arn.includes(userChime?.userId)) {
  //         let lastPingTime = new Date(findMemberByArn.updatedTimeStamp);
  //         let nowDate = new Date();
  //         const timeDifferenceInSeconds = (nowDate.getTime() - lastPingTime.getTime()) / 1000;
  //         console.log(`Count Time of ${m.Member.Name} is ${timeDifferenceInSeconds}`);

  //         //change presence status
  //         if (findMemberByArn.status === 'onhold') {
  //           newObject.status = OnlinePresenceStatus.onhold
  //         }
  //         if (findMemberByArn.status === 'busy') {
  //           newObject.status = OnlinePresenceStatus.busy
  //         }
  //         if ((timeDifferenceInSeconds >= TimeOutOnlineStatusChange)) {
  //           newObject.status = OnlinePresenceStatus.onhold
  //         }
  //         //trigger count down from 120s not response
  //         if (timeDifferenceInSeconds >= TimeOutOnlineStatusTriggerCountDown) {
  //           let isBoschExpert = m.Member.Arn.includes(senderNameType.boschExper)
  //           if (memberList.length === 2 && isBoschExpert) {
  //             newObject.status = OnlinePresenceStatus.offline
  //           }
  //         }
  //       } else {
  //         newObject.status = OnlinePresenceStatus.offline
  //       }
  //       return newObject
  //     })
  //     setMemberPresenceList(listMemberPresent)
  //   }, TimeOutOnlineStatusTrigger);

  //   if (!channelArn || memberList.length <= 1) {
  //     clearInterval(intervalId);
  //   }
  //   return () => clearInterval(intervalId);
  // }, [channelArn, memberList, listPresenceOfUserInChannel, userChime]);

  //get list channel member
  useEffect(() => {
    const getListChannelMemberFunc = async () => {
      const memberShip = await listChannelMemberships(
        channelArn,
        userChime?.userId,
      )
      await setMemberList(memberShip)
    }

    if (channelArn && userChime) {
      getListChannelMemberFunc()
    }
  }, [channelArn, userChime])

  return (
    <div
      className={Styles.publicDashboard}
    >
      <div className={Styles.header_title}>

        <Button
          className={isTranslate.status ? `${Styles.translate_modal_btn} ${Styles.disable}` : `${Styles.translate_modal_btn}`}
          onClick={() => { handleOnOpenTranslateModal(!isTranslate.status) }}
          disabled={!channelArn}
        ><Boschicon
            style={{ padding: "unset" }}
            name={'bosch-ic-translate'}
          /> {t("Live_Translation")}
        </Button>

        <p>{t("WSA_label")}</p>
      </div>
      <div className={Styles.session_title}>
        <p>{t("Assistant_Welcome_Bot")}</p>
      </div>
      <div className={Styles.chatting_area}>
        <div className={Styles.chatting}>
          <Messages
            messagesAssit={updatedListMessage}
            messagesChanel={updatedListChannelMessage} memberPresenceList={memberPresenceList} />
          {
            (onStartAChatChannel && featureType && channelArn && userChime) ? (
              <PublicInputMessage
                activeChannelArn={channelArn}
                member={userChime.userId}
                browserLanguage={browserLanguage}
              />
            ) : (
              !showLoadingDot && (
                <UserInput
                  onSubmit={hanldeOnSubmitInput}
                />
              )
            )
          }
        </div>
      </div>
      {
        displayErrorModal && (<RDAlert title={errorModalMessage} messages={t('Retry_note_v2')} isOpen={displayErrorModal} type={"retry"} acceptButtonText={t('Retry_panel')} onAccept={onTrigger} />)
      }
      {
        displayDeleteModal && (<RDAlert title={errorModalMessage} messages={t('Thanks_note')} onAccept={onDeleteChannel} isOpen={displayDeleteModal} type={"close_channel"} acceptButtonText={t('Close_panel')} />)
      }
      {
        displaydetectedLanguageModal && (<RDAlert title={t('Translation_is_available')} messages={messageLanguageDetectedModal} onCancel={onCancelStartLiveTranslation} onAccept={onHanldeStartLiveTranslation} isOpen={displaydetectedLanguageModal} type={"detected_language"} acceptButtonText={t("Yes_Label")} cancelButtonText={t("No_Label")} />)
      }
      {
        displaySelectLanguageModal && (<ModalTranlationOptionsPublic isOpen={displaySelectLanguageModal} translateModeData={isTranslate} onClose={() => setDisplaySelectLanguageModal(false)} enableByBotWidget={displaySelectLanguageModal} acceptButtonText={t('Continue_panel')} />)
      }
      {isLoading && (
        <Fragment>
          <CustomLoader enable={isLoading}></CustomLoader>
        </Fragment>
      )}
    </div>
  );
};

export default AssistantDashBoard;