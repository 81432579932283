import axios from 'axios'
import {
  Log,
  SignoutResponse,
  User,
  UserManager,
  UserManagerSettings,
} from 'oidc-client-ts'
import RedirectHelper from '../helpers/RedirectHelper'
import { AccountProfile } from '../reducers/AccountReducer'
import { AuthState, initialState } from '../redux/auth'

interface CIAMCallbackData {
  redirectUrl: string
  guid?: string
}
export class AuthService {
  private static instance: AuthService

  public static getInstance(): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService()
    }

    return AuthService.instance
  }

  public userManagerLogin: UserManager
  constructor() {
    this.userManagerLogin = new UserManager({} as UserManagerSettings)
    this.SetManagerProfileSettings()
  }

  private SetManagerProfileSettings() {
    const authState = this.getAuthState()
    const clientRoot = RedirectHelper.getClientRoot(authState)
    console.log(`${clientRoot}/signincallback`)
    const settings = {
      authority: String(process.env.REACT_APP_DEFAULT_AUTHORITY),
      client_id: String(process.env.REACT_APP_DEFAULT_CLIENTID),
      redirect_uri: `${clientRoot}/signincallback`,
      silent_redirect_uri: `${clientRoot}/silent-renew.html`,
      popup_redirect_uri: `${clientRoot}/signin-popup-callback.html`,
      automaticSilentRenew: true,
      post_logout_redirect_uri: `${clientRoot}/signoutcallback`,
      response_type: 'code',
      scope: String(process.env.REACT_APP_DEFAULT_CLIENTSCOPE),
      staleStateAge: 10000,
      extraQueryParams: {
        resource: String(process.env.REACT_APP_DEFAULT_CLIENTID),
      },
    }
    this.userManagerLogin = new UserManager(settings)

    Log.setLogger(console)
    Log.setLevel(Log.INFO)
  }

  public getUser(): Promise<User | null> {
    return this.userManagerLogin.getUser()
  }

  public async loginAsync(
    dataRedirect?: CIAMCallbackData | undefined,
  ): Promise<void> {
    this.userManagerLogin.clearStaleState()
    return !!dataRedirect
      ? await this.userManagerLogin.signinRedirect({ state: dataRedirect })
      : await this.userManagerLogin.signinRedirect()
  }

  public async loginCallbackAsync(): Promise<User> {
    return await this.userManagerLogin.signinRedirectCallback()
  }

  public renewToken(): Promise<User | null> {
    return this.userManagerLogin.signinSilent()
  }

  public async logoutAsync(): Promise<void> {
    return await this.userManagerLogin.signoutRedirect()
  }

  public async logoutCallbackAsync(): Promise<SignoutResponse> {
    return await this.userManagerLogin.signoutRedirectCallback()
  }

  public async clearLoginUserState(): Promise<void> {
    await this.userManagerLogin.clearStaleState()
    await this.userManagerLogin.removeUser()
  }

  public getSimpleAccount(ciamid: string) {
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_KEY}/api/v1/account/GetAccountSimple/${ciamid}`,
      responseType: 'stream',
    })
  }

  public createAccount(account: AccountProfile) {
    return axios.post(
      `${process.env.REACT_APP_API_KEY}/api/v1/account`,
      account,
    )
  }

  public getAuthState(): AuthState {
    const regionStorageKey = process.env.REACT_APP_REGIONKEY
      ? process.env.REACT_APP_REGIONKEY
      : 'REGIONS_STORE'
    let auth = initialState
    if (!!localStorage.getItem(regionStorageKey))
      auth = JSON.parse(localStorage.getItem(regionStorageKey) as string)
    return auth
  }

  public static setAuthState(auth: AuthState) {
    let regionStorageKey = process.env.REACT_APP_REGIONKEY
      ? process.env.REACT_APP_REGIONKEY
      : 'REGIONS_STORE'
    localStorage.setItem(regionStorageKey, JSON.stringify(auth))
  }

  public removeAuthState() {
    let regionStorageKey = process.env.REACT_APP_REGIONKEY
      ? process.env.REACT_APP_REGIONKEY
      : 'REGIONS_STORE'
    localStorage.removeItem(regionStorageKey)
  }
}
