import { Fragment, useEffect, useState } from "react";
import { getAccountSimpleAsync, setRedirectUrl } from "../../redux/account";
import { AppDispatch, useAppDispatch } from "../../redux/store";
import { AuthService } from "../../services/AuthService";
import React from "react";
import { useHistory } from "react-router-dom";
import routes from "../../routes";
import ErrorModal from "../../components/modals/ErrorModal";
import CustomLoader from "../../common-components/RDComponents/CustomLoader";

const SignInCallback = () => {
    const authService: AuthService = AuthService.getInstance();
    const [status, setStatus] = useState('');
    const [displayErrorModal, setDisplayErrorModal] = useState<boolean>(false);
    const [errorModalMessage, setErrorModalMessage] = useState<string>("");
    const dispatchReduxToolkit: AppDispatch = useAppDispatch();
    const history = useHistory();

    const getSimpleAccount = async (user: any) => {
        var response = await dispatchReduxToolkit(getAccountSimpleAsync(user));
        if (getAccountSimpleAsync.fulfilled.match(response)) {
            if (!!response.payload) {
                if (response.payload.value && response.payload.value.isSuccess) {
                    history.push(routes.SIGNIN);
                } else {
                    setErrorModalMessage("Login Fail");
                    setDisplayErrorModal(true);
                }
            } else {
                setErrorModalMessage("Cannot connect Channel");
                setDisplayErrorModal(true);
            }
        }

        if (getAccountSimpleAsync.rejected.match(response)) {
            setErrorModalMessage("Login Fail");
            setDisplayErrorModal(true);
        }
    }

    const onTrigger = () => {
        history.push(routes.SIGNIN);
    }

    useEffect(() => {
        async function performLogin() {
            const result = await authService.loginCallbackAsync();
            if (!!result && result.profile && !!result.profile.preferred_username) {
                const chimeUserReq = {
                    "ciamId": result.profile.preferred_username ? result.profile.preferred_username : "",
                    "name": result.profile.name || "",
                    "mobile": result.profile.mobile || "",
                    "email": result.profile.email || ""
                }
                await getSimpleAccount(chimeUserReq);
                if (!!result.state) {
                    await dispatchReduxToolkit(setRedirectUrl((result.state as any).redirectUrl));   
                }
            }
            else {
                setStatus("Error_Common");
            }
        }

        performLogin()
    }, [])

    return (
        <>
            <ErrorModal showError={displayErrorModal}
                errorMessage={errorModalMessage}
                onSetErrorTrigger={onTrigger} />
            <Fragment>
                <CustomLoader enable={true}></CustomLoader>
            </Fragment>
        </>
    )
}

export default SignInCallback;