export const languageOptions =
    [
        { key: "English", text: "English", value: "en" },
        { key: "Spanish", text: "Española", value: "es" },
        { key: "Deutsch", text: "Deutsch", value: "de" },
        { key: "Danish", text: "Danish", value: "da" },
        { key: "Finnish", text: "Finnish", value: "fi" },
        { key: "French", text: "French", value: "fr" },
        { key: "Dutch", text: "Dutch", value: "nl" },
        { key: "Nynorsk", text: "Nynorsk", value: "nn" },
        { key: "Polish", text: "Polish", value: "pl" },
        { key: "Portuguese", text: "Portuguese", value: "pt" },
        { key: "Swedish", text: "Swedish", value: "sw" },
        { key: "Czech", text: "Czech Republic", value: "cz" },
    ];