import React, { Fragment } from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import './css/ModalCreateNewChannel.css'

interface ModalShowRandomIdProps {
  isOpen: boolean
  onClose: Function
  randomId: string
}

const ModalShowRandomId = ({
  isOpen,
  onClose,
  randomId
}: ModalShowRandomIdProps) => {
  const { t } = useTranslation()

  return (
    <Fragment>
      <Modal
        onClose={() => onClose()}
        open={isOpen}
        dimmer={{ className: 'test-dimmer' }}
        size={'tiny'}
        closeIcon
        closeOnDimmerClick={false}
        className='create-new-modal'
      >
        <div className="header-color-line"></div>
        <Modal.Content>
          <div className='random--code'>
            <div className='random--code-title'>
              {t('Generated_ID')}
            </div>
            <div className='random--code-content'>
              {t('Your_ID')} <a>{randomId}</a>
            </div>
            <Button
              primary
              className="random--code-connect"
              onClick={() => onClose()}
            >
              {t('Continue_panel')}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    </Fragment>
  )
}

export default ModalShowRandomId
