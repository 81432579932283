import React from 'react';

import { Flex } from 'amazon-chime-sdk-component-library-react';
import { useSelector } from 'react-redux';
import { selectMeetingState } from '../../redux/meeting';
import MeetingRoster from '../../components/MeetingRoster/MeetingRoster';
import { useTranslation } from "react-i18next";

const MeetingDetails = () => {

    const meetingData = useSelector(selectMeetingState);
    const meetingTitle = "meetingTitle";
    const { t } = useTranslation();
    return (
        <Flex container layout="fill-space-centered">
            <Flex mb="2rem" mr={{ md: '2rem' }} px="1rem">
                <h1 >
                    {t("Meeting_Information_Label")}
                </h1>
                <br />
                <br />
                <div>
                    <span>{t("Meeting_ID_Label") + ": "} </span>
                    <span>{meetingData.Meeting.meetingId}</span>
                </div>
                <br />
                {
                    meetingData.Meeting.externalMeetingId.toLowerCase() !== meetingTitle.toLowerCase() && (
                        <div>
                            <span>{t("Public_Meeting_ID_Label")} </span>
                            <span>{meetingData.Meeting.externalMeetingId}</span>
                        </div>
                    )
                }

                <br />
                {/* <div>
                    <span>Hosted region: </span>
                    <span>{meetingData.Meeting.mediaRegion}</span>
                </div> */}
                <br />
                <div>
                    <MeetingRoster />
                </div>
            </Flex>
        </Flex>
    );
};

export default MeetingDetails;
