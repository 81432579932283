import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "./store";

export interface ChannelItem {
    ChannelArn: string,
    LastMessageTimestamp: object | null,
    Metadata: object | null,
    Mode: string,
    Name: string,
    Privacy: string
}

export const initialChannelItemState: ChannelItem = {
    ChannelArn: '',
    LastMessageTimestamp: null,
    Metadata: null,
    Mode: '',
    Name: '',
    Privacy: ''
}

export interface ChannelListState {
    channelAllList: ChannelItem[] | any[],
    channelUnreadList: string[]
}

// export interface ChannelListState extends Array<ChannelItem> { }

export const initialChannelListState: ChannelListState = {
    channelAllList: [],
    channelUnreadList: []
}

export const selectChannelListState = (rootState: RootState) => rootState.channelList;
export const selectChannelAllListState = (rootState: RootState) => selectChannelListState(rootState).channelAllList;
export const selectChannelUnreadListState = (rootState: RootState) => selectChannelListState(rootState).channelUnreadList;

const channelListReducerSlice = createSlice({
    name: 'channelListReducer',
    initialState: initialChannelListState,
    reducers: {
        channelSetChannelList: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                channelAllList: action.payload
            }
        },
        channelSetChannelUnreadList: (state, action: PayloadAction<any>) => {
            if (state.channelUnreadList.includes(action.payload)) {
                return {
                    ...state
                }
            }
            return {
                ...state,
                channelUnreadList: [...state.channelUnreadList, action.payload]
            }
        },
        channelPopChannelUnreadList: (state, action: PayloadAction<any>) => {
            const items = state.channelUnreadList.filter(item => item !== action.payload);
            return {
                ...state,
                channelUnreadList: items
            }
        },
        channelClearChannelUnreadList: (state) => {
            return {
                ...state,
                channelUnreadList: []
            }
        }
    }
});

export const { channelSetChannelList, channelSetChannelUnreadList, channelClearChannelUnreadList, channelPopChannelUnreadList } = channelListReducerSlice.actions;
export default channelListReducerSlice.reducer;