import { Fragment, useState, useEffect } from "react";
import React from "react";
import { Grid, Button, Modal } from "semantic-ui-react";
import { useSelector } from "react-redux";
import "./DashBoard.css";
import MessagingService from "../../../services/MessagingService";
import { useAppDispatch } from "../../../redux/store";
import {
  ChannelClearState,
  channelSetChannel,
  selectChannelArn,
  selectIsTranslate,
  SetCleanPresenceOfUserInChannel,
  SetNewMemberJoinChannel,
  SetOnHanldeClearMeeting,
  SetOnHanldeLeaveMeeting,
  SetPresenceOfUserInChannel
} from "../../../redux/chimeSlide";
import {
  MessageType,
  Persistence,
  createChannel,
  createChannelMembership,
  createMemberArn,
  deleteChannel,
  describeChannel,
  listChannelMembershipsForAppInstanceUser,
  listChannels,
} from "../../../api/ChimeAPI";
import MessagesView from "../../../views/Messages";
import { selectIsSignIn } from "../../../redux/auth";
import { loginAsync, selectAccount, selectRedirectUrl, setRedirectUrl } from "../../../redux/account";
import AWS from "aws-sdk";
import { getTicketInfor } from "../../../redux/ticket";
import {
  channelSetChannelList,
  channelSetChannelUnreadList,
  channelClearChannelUnreadList,
} from "../../../redux/channel";
import {
  visualConnectSetList,
  selectVisualConnectListState,
  visualConnectSetActive,
  visualConnectSetFirstStart,
  visualConnectIsSendEmail
} from "../../../redux/visualConnect";
import addNotification from "react-push-notification";
import { CommonService } from "../../../services/Common";
import ModalJoinCurrentChannel from "../../modals/ModalJoinCurrentChannel";
import ModalCreateNewChannel from "../../modals/ModalCreateNewChannel";
import { useTranslation } from "react-i18next";
import { createMeetingByRandomCode, endMeeting, leaveMeeting, selectMeetingState } from "../../../redux/meeting";
import ModalNotiEndMeetting from "../../modals/ModalNotiEndMeetting";
import { useHistory } from "react-router-dom";
import { RSADecrypt } from "../../../services/RSA_Encrypt";
import ModalTranlationOptionsPublic from "../../modals/publicChannelModal/ModalTranlationOptionsPublic";
import ModalShowRandomId from "../../modals/ModalShowRandomId";
import CustomLoader from "../../../common-components/RDComponents/CustomLoader";
import RDNotification from "../../../common-components/RDComponents/RDNotification";
import { TimeOutOnlineStatus } from "../../../helpers/AppConstants";
import useBroadcast from "../../../hooks/useBroadcast";
import SignalRService from "../../../services/SignalRService";

const DashBoard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isConfiged, setIsConfiged] = useState(false);
  const isLoggedIn = useSelector(selectIsSignIn);
  const account = useSelector(selectAccount);
  const [displayErrorModal, setDisplayErrorModal] = useState<boolean>(false);
  const [errorModalMessage, setErrorModalMessage] = useState<string>("");
  const [notificationType, setNotificationType] = useState<string>("");
  const [isStopChannel, setIsStopChannel] = useState<boolean>(false);
  const [channelStopArn, setChannelStopArn] = useState<string>("");
  const [messagingService] = useState(MessagingService.getInstance());
  const [unReadChannel, setUnReadChannel] = useState<string>("");
  const [displayJoinCurrentChannelModal, setDisplayJoinCurrentChannelModal] = useState<boolean>(false);
  const [dataCurrentChannel, setDataCurrentChannel] = useState<any>(null);
  const [openCreateChannelModel, setOpenCreateChannelModel] = useState<boolean>(false);
  const [messageProp, setMessageProp] = useState<any>();
  const channelArn = useSelector(selectChannelArn);
  const visualConnectList = useSelector(selectVisualConnectListState);
  const meetingData = useSelector(selectMeetingState);
  const [isInCall, setIsInCall] = useState<boolean>(false);
  const history = useHistory();
  const isTranslate = useSelector(selectIsTranslate);
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const ticketId = window.location.search !== "" ? urlParams.get("ticketId") : "";
  const sessionId = window.location.search !== "" ? urlParams.get("joinMeeting") : "";
  const redirectUrl = useSelector(selectRedirectUrl);
  const [openModalShowRandomId, setOpenModalShowRandomId] = useState<boolean>(false);
  const [randomCode, setRandomCode] = useState<string>("");
  const dispatchReduxToolkit = useAppDispatch();
  const [displayDeleteModal, setDisplayDeleteModal] = useState<boolean>(false);
  const [tabHasFocusState, setTabHasFocusState] = useState<boolean>(true);
  const accountAdmin = process.env.REACT_APP_AWS_ACCOUNT_ADMIN || "";
  const signalRConnectorInstance = SignalRService.getInstance();
  const [sinalRHubConnected, setSinalRHubConnected] = useState<boolean>(false);

  const appInstance = process.env.REACT_APP_AWS_APP_INSTANCE_ARN || "";

  const sendLinkVisualConnect = async (phoneNumber: string) => {
    if (!meetingData.Meeting?.meetingId) {
      dispatchReduxToolkit(visualConnectSetList(phoneNumber));
      dispatchReduxToolkit(visualConnectSetActive(true));
      dispatchReduxToolkit(visualConnectSetFirstStart(true));
      setOpenCreateChannelModel(false);
      return;
    }
    setIsInCall(true)
  }
  const sendEmailVisualConnect = async (email: string) => {
    if (!meetingData.Meeting?.meetingId) {
      dispatchReduxToolkit(visualConnectSetList(email));
      dispatchReduxToolkit(visualConnectSetActive(true));
      dispatchReduxToolkit(visualConnectSetFirstStart(true));
      dispatchReduxToolkit(visualConnectIsSendEmail(true));
      setOpenCreateChannelModel(false);
      return;
    }
    setIsInCall(true)
  }

  const messagesProcessor = async (message: any) => {
    const messageType = message?.headers["x-amz-chime-event-type"];
    const record = JSON.parse(message?.payload);
    switch (messageType) {
      // Channel Messages
      case "CHANNEL_DETAILS":
        console.log("CHANNEL_DETAILS");
        // addMemberPresence(record.ChannelMemberships)
        //setActiveChannelMembershipsWithPresence([...activeChannelMembershipsWithPresence, record.ChannelMemberships])
        processUnreadChannelInit(record);
        break;
      case "CREATE_CHANNEL_MESSAGE":
      case "REDACT_CHANNEL_MESSAGE":
      case "UPDATE_CHANNEL_MESSAGE":
      case "DELETE_CHANNEL_MESSAGE":
      case "DENIED_CREATE_CHANNEL_MESSAGE":
      case "FAILED_CREATE_CHANNEL_MESSAGE":
      case "DENIED_UPDATE_CHANNEL_MESSAGE":
      case "FAILED_UPDATE_CHANNEL_MESSAGE":
      case "PENDING_CREATE_CHANNEL_MESSAGE":
      case "PENDING_UPDATE_CHANNEL_MESSAGE":

        processChannelMessage(record);
        break;
      case "CREATE_CHANNEL":
      case "UPDATE_CHANNEL":
        break;
      case "DELETE_CHANNEL":
        dispatchReduxToolkit(ChannelClearState());
        fetchChannels();
        break;
      case "CREATE_CHANNEL_MEMBERSHIP":
        if (!record?.Member?.Arn.includes(account.chimeUserId)) {
          setErrorModalMessage(t('Join_Channel_note'));
          setNotificationType("success")
          setDisplayDeleteModal(true);
        }
        dispatchReduxToolkit(SetNewMemberJoinChannel(true));
        break;
      case "UPDATE_CHANNEL_MEMBERSHIP":
        dispatchReduxToolkit(SetNewMemberJoinChannel(true));
        break;
      case "DELETE_CHANNEL_MEMBERSHIP":
        dispatchReduxToolkit(SetNewMemberJoinChannel(true));
        if (record?.Member?.Arn.includes(account.chimeUserId)) {
          dispatchReduxToolkit(ChannelClearState());
          fetchChannels();
        } else {
          dispatchReduxToolkit(SetCleanPresenceOfUserInChannel([]));
          setErrorModalMessage(t('Leave_Channel_note'));
          setNotificationType("warning")
          setDisplayDeleteModal(true);
        }
        break;
      default:
        console.log(`Unexpected message type! ${messageType}`);
    }
  };

  const processUnreadChannelInit = async (record: any) => {
    const promise = Promise.resolve(record);
    const channelPrefetch = await promise.then((m) => m);

    if (channelPrefetch) {
      try {
        if (
          new Date(channelPrefetch.ReadMarkerTimestamp).getTime() <
          new Date(channelPrefetch.Channel.LastMessageTimestamp).getTime()
        ) {
          setUnReadChannel(channelPrefetch.Channel.ChannelArn);
        }
      } catch { }
    }
  };
  var tabHasFocus = true;

  const handleFocus = () => {
    tabHasFocus = true;
    setTabHasFocusState(true)
  };

  const handleBlur = () => {
    tabHasFocus = false;
    setTabHasFocusState(false)
  };


  const processChannelMessage = async (message: any) => {
    const promise = Promise.resolve(message);
    const newMessage = await promise.then((m) => m);
    if (newMessage.Persistence === Persistence.NON_PERSISTENT && newMessage.Type === MessageType.CONTROL) {
      dispatchReduxToolkit(SetPresenceOfUserInChannel(newMessage));
    } else {
      if (newMessage.Sender.Arn !== createMemberArn(account.chimeUserId)) {
        setUnReadChannel(newMessage.ChannelArn);
        setUnReadChannel("");

        let metaData = JSON.parse(newMessage.Metadata)
        let checkHistoryType = metaData.type ? (metaData.type === 'history' ? true : false) : false;

        if (!tabHasFocus && !checkHistoryType) {
          addNotification({
            title: newMessage.Sender.Name + " sent you a message",
            message: newMessage.Content,
            theme: "darkblue",
            duration: 10000,
            native: true,
            onClick: notifiOnclick,
          });
        }
      }
      setMessageProp(newMessage);
    }
  };

  const notifiOnclick = () => {
    window.focus();
  };

  const registerGroup = (channelArn: string) => {
    if (channelArn) {
      signalRConnectorInstance.onConnectionEstablished(() => {
        signalRConnectorInstance.joinGroup(channelArn).then(() => {
          console.log(`Joined into group ${channelArn}`);
        }).catch((err: any) =>
          console.log(
            `Join to group ${channelArn} failed. Err: ${err}`
          )
        );
      });
    }
  };

  const fetchChannels = async () => {
    const userChannelMemberships =
      await listChannelMembershipsForAppInstanceUser(account.chimeUserId);

    const userChannelList = userChannelMemberships
      .map((channelMembership: any) => channelMembership.ChannelSummary)
      .sort((current: any, next: any) => {
        try {
          const newDate = new Date();
          const nextLast = next.LastMessageTimestamp
            ? next.LastMessageTimestamp.getTime()
            : newDate.getTime();
          const currentLast = current.LastMessageTimestamp
            ? current.LastMessageTimestamp.getTime()
            : newDate.getTime();

          return nextLast - currentLast;
        } catch {
          return null;
        }
      });
    dispatchReduxToolkit(channelSetChannelList(userChannelList));
  };

  const handleChannelDeletion = async (e: any) => {
    e.preventDefault();
    if (visualConnectList.phoneNumber !== "" && visualConnectList.active) {
      dispatchReduxToolkit(visualConnectSetList(""));
      dispatchReduxToolkit(visualConnectSetActive(false));
      dispatchReduxToolkit(visualConnectSetFirstStart(false));
      setIsStopChannel(false);
      setTimeout(() => {
        history.push("/pcs");
      }, 2000);
      return;
    }

    setIsLoading(true);
    setIsStopChannel(false);
    if (channelStopArn && channelStopArn !== "") {
      await stopChannel(channelStopArn, account?.chimeUserId);//await deleteChannel(channelArn, account?.chimeUserId);
      await CommonService.deleteAllChannelAttachments(
        channelStopArn.substring(channelStopArn.lastIndexOf("/") + 1)
      );
      // dispatchReduxToolkit(ChannelClearState());
    }
    setIsLoading(false);
  };

  const stopChannel = async (channelArn: string, ciamId: string) => {
    const channel = await describeChannel(channelArn, ciamId);
    const userArn = channel.CreatedBy.Arn.substring(channel.CreatedBy.Arn.lastIndexOf('/') + 1);
    await deleteChannel(channel.ChannelArn, userArn);
  }

  const onStartChannel = async (data: string) => {
    setOpenCreateChannelModel(false);
    excuteStartChannel(data);
  };

  const onStartChannelRandomCode = async (code: string) => {
    setOpenCreateChannelModel(false);
    excuteStartChannel(code, true);
    setOpenModalShowRandomId(true);
    setRandomCode(code)
  };

  const onStartRandomVSC = async (code: string) => {
    if (!meetingData.Meeting?.meetingId) {
      setOpenCreateChannelModel(false);
      onStartVSCByRandomCode(code);
      setOpenModalShowRandomId(true);
      setRandomCode(code)
      dispatchReduxToolkit(visualConnectSetFirstStart(true));
      return;
    }
    setIsInCall(true)
  };

  const onStartVSCByRandomCode = async (code: string) => {
    setIsLoading(true)
    const meeting = await dispatchReduxToolkit(createMeetingByRandomCode(code))
    if (createMeetingByRandomCode.fulfilled.match(meeting)) {
      let meetingId = meeting.payload.dataObject.createMeetingResponse.meeting.meetingId
      setIsLoading(false)
      openMeetingPopup(meetingId);
    }
    if (createMeetingByRandomCode.rejected.match(meeting)) {
      setErrorModalMessage('Cannot start video session')
      setDisplayErrorModal(true)
    }
  }

  const openMeetingPopup = (meetingId: string) => {
    const popup = window.open(
      `/pcs/meeting/${meetingId}/?user_id=${account.chimeUserId}`,
      'null',
      'popup,width=1200,height=680,left=300,top=500, menubar=0, toolbar=0, scrollbar=1',
    )
    if (popup) {
      broadcast("")
    }
  };

  const { broadcast } = useBroadcast<string>({
    channelName: "meeting-status",
    messageHandler: (message: MessageEvent<string>) => {
      if (message.data !== "") {
        let messageParse = JSON.parse(message.data);
        if (messageParse.type === 'end_metting') {
          endMeetingHandle(messageParse.metaData);
        } else if (messageParse.type === 'leave_metting') {
          leaveMeetingHandle()
        } else {
          return
        }
      }
      return
    },
  });

  const endMeetingHandle = async (bodyMeeting: any) => {
    await dispatchReduxToolkit(SetOnHanldeClearMeeting(true));
    await dispatchReduxToolkit(endMeeting(bodyMeeting));
    await dispatchReduxToolkit(visualConnectSetFirstStart(false));
  }

  const leaveMeetingHandle = async () => {
    await dispatchReduxToolkit(SetOnHanldeLeaveMeeting(true));
    await dispatchReduxToolkit(leaveMeeting());
    await dispatchReduxToolkit(visualConnectSetFirstStart(false));
  }

  const excuteStartChannel = async (ticketIdParam: string, randomChannel?: boolean) => {
    setIsLoading(true);
    if (randomChannel) {
      const response = await listChannels(appInstance, account?.chimeUserId);
      const exitChannel = response.find(
        (x: any) =>
          x.Name == ticketIdParam
      );

      if (exitChannel) {
        setIsLoading(false);
        setErrorModalMessage(`Channel with ID: ${ticketIdParam} has already exited. Please try again`);
        setDisplayErrorModal(true);
        return null
      }

      const channelArn = await createChannel(
        appInstance,
        JSON.stringify({
          ticket: ticketIdParam,
          channelType: "PUBLIC",
          memberArn: `${account?.chimeUserId}`
        }),
        ticketIdParam,
        "RESTRICTED",
        "PUBLIC",
        account?.chimeUserId
      );
      if (channelArn) {
        connectChannel(
          channelArn,
          account?.chimeUserId ? account.chimeUserId : "",
          false
        );

        setIsLoading(false);
      } else {
        setIsLoading(false);
        setErrorModalMessage("Cannot create new Channel");
        setDisplayErrorModal(true);
      }

      setIsLoading(false);
      return null
    } else {
      var ticket = await dispatchReduxToolkit(
        getTicketInfor({ ticketId: ticketIdParam, email: account.email })
      );
      if (getTicketInfor.fulfilled.match(ticket)) {
        if (!ticket.payload || !ticket.payload.dataObject?.ciamId) {
          setIsLoading(false);
          setErrorModalMessage("Cannot find ticket");
          setDisplayErrorModal(true);
        } else {
          setIsLoading(true);
          let ticketObject = ticket.payload.dataObject;
          let ticketId = ticketObject.ticketDetails.ticketId;
          let channelArn = `${appInstance}/channel/${ticketId}`;
          let channelDescribe = await describeChannel(channelArn, accountAdmin);
          if (channelDescribe) {
            const channelArn = channelDescribe.ChannelArn;
            if (channelArn) {
              connectChannel(
                channelArn,
                accountAdmin,
                false,
                ticket.payload.dataObject.ciamId
              );
              setIsLoading(false);
            } else {
              setIsLoading(false);
              setErrorModalMessage("Cannot create new Channel");
              setDisplayErrorModal(true);
            }
          } else {
            console.error("Channel not exited!")
            // choose contact person
            // setOpenModalContactPerson(true);
            createChannelProcess(ticket.payload.dataObject)
            // setTicketDetailFullFill(ticket.payload.dataObject);
            setIsLoading(false)
          }
        }
      }

      if (getTicketInfor.rejected.match(ticket)) {
        setErrorModalMessage("Cannot find ticket");
        if (
          ticket.payload &&
          ticket.payload.response?.data.httpStatusCode == 403
        ) {
          setErrorModalMessage("Permission denied");
        }
        setTimeout(() => {
          setIsLoading(false);
          setDisplayErrorModal(true);
        }, 2000);

      }
    }
  }

  const createChannelProcess = async (ticketInfo: any) => {
    try {
      const channelName = String(ticketInfo.ticketDetails?.ticketId);
      let channelArn = await createChannel(
        appInstance,
        JSON.stringify({
          ticket: channelName,
          nameWithRecipient:
            channelName + " - " + ticketInfo.firstName,
          companyName: ticketInfo.ticketDetails?.customer?.assignedAccount?.name1,
          fullNameCustomer: ticketInfo.firstName + " " + ticketInfo.lastName,
          serviceType: ticketInfo.ticketDetails?.topic?.serviceType,
          memberArn: `${account?.chimeUserId}`,
          channelType: "PRIVATE",
          customerCiamId: ticketInfo.ciamId
        }),
        channelName,
        "RESTRICTED",
        "PRIVATE",
        account?.chimeUserId
      );
      if (channelArn) {
        connectChannel(
          channelArn,
          account?.chimeUserId ? account.chimeUserId : "",
          true,
          ticketInfo.ciamId
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setErrorModalMessage("Cannot create new Channel 1");
        setDisplayErrorModal(true);
      }
    } catch (error) {
      setErrorModalMessage("Cannot create new Channel 2");
      setDisplayErrorModal(true);
    }
  }

  const connectChannel = async (
    channelDescribe: string,
    userId: string,
    addMember: boolean,
    ticketCiamId?: string
  ) => {
    const promise = Promise.resolve(channelDescribe);
    const channelArnCurrent = await promise.then((m) => m);
    const channel = await describeChannel(channelArnCurrent, userId);
    if (channel) {
      if (addMember && ticketCiamId) {
        const statusAdd = await onAddMember(channel, ticketCiamId);
        if (statusAdd) {
          dispatchReduxToolkit(channelSetChannel(channel));
          fetchChannels();
        }
      } else {
        if (channelArn === channelArnCurrent) return;
        const statusAdd = await onAddMember(channel, account.chimeUserId);
        if (statusAdd) {
          dispatchReduxToolkit(channelSetChannel(channel));
          fetchChannels();
        }
      }
      // toggleOpen(true);
    } else {
      console.log("Error, could not retrieve channel information.");
    }
  };

  const onJoinChannel = async (result: boolean) => {
    if (result) {
      dispatchReduxToolkit(channelSetChannel(dataCurrentChannel));
      fetchChannels();
      setDataCurrentChannel(null);
      setDisplayJoinCurrentChannelModal(false);
    } else {
      setDataCurrentChannel(null);
      setDisplayJoinCurrentChannelModal(false);
      setErrorModalMessage("Cannot join this channel");
      setDisplayErrorModal(true);
    }

  }

  const onAddMember = async (channel: any, technician: string) => {
    const promise = Promise.resolve(channel);
    const channelArn = await promise.then((m) => m.ChannelArn);
    try {
      const membership = await createChannelMembership(
        channelArn,
        `${appInstance}/user/${technician}`,
        accountAdmin
      );
      return membership;
    } catch {
      console.log("Error occurred. Member not added to channel.");
    }
  };

  const onTrigger = () => {
    setDisplayErrorModal(false);
  };

  const login = async () => {
    dispatchReduxToolkit(loginAsync());
  };

  //Use Effect
  useEffect(() => {
    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, []);


  useEffect(() => {
    // if (isConfiged) {
    if (ticketId && ticketId !== "") {
      excuteStartChannel(ticketId);
    }
    if (sessionId && sessionId !== "") {
      excuteStartChannel(sessionId);
    }

  }, [ticketId, sessionId]);

  useEffect(() => {
    if (!sinalRHubConnected) {
      registerGroup(channelArn);

      signalRConnectorInstance.setupEmitter();
      setSinalRHubConnected(true);
    }
  }, [sinalRHubConnected])

  useEffect(() => {
    if (channelArn) {
      signalRConnectorInstance.joinGroup(channelArn).then(() => {
        console.log(`Joined into group ${channelArn}`);
      }).catch((err: any) =>
        console.log(
          `Join to group ${channelArn} failed. Err: ${err}`
        )
      );
    }
  }, [channelArn])

  useEffect(() => {
    let messageObj = {
      key: channelArn,
      value: [
        {
          memberARN: account.chimeUserId,
          status: "online",
          datetime: new Date().toISOString(),
          role: "bosch_expert"
        }
      ]
    }
    const sendMessageToGroup = (message: any) => {
      signalRConnectorInstance.sendMessageToGroup("SendSinalToGroup", channelArn, JSON.stringify(message));
    };
    const intervalId = setInterval(() => {
      // send online to active channel
      messageObj.value = [{
        memberARN: account.chimeUserId,
        status: "online",
        datetime: new Date().toISOString(),
        role: "bosch_expert"
      }]
      if (isLoggedIn) {
        sendMessageToGroup(messageObj);
      }
    }, TimeOutOnlineStatus);

    //clear inteval
    if (!tabHasFocusState || !channelArn || !account) {
      clearInterval(intervalId);
    }
    // send onhold signal
    if (!tabHasFocusState && channelArn && account) {
      messageObj.value = [{
        memberARN: account.chimeUserId,
        status: "onhold",
        datetime: new Date().toISOString(),
        role: "bosch_expert"
      }]
      if (isLoggedIn) {
        sendMessageToGroup(messageObj);
      }
      clearInterval(intervalId);
    } else {
      if (isLoggedIn) {
        sendMessageToGroup(messageObj);
      }
    }
    return () => clearInterval(intervalId);
  }, [tabHasFocusState, channelArn, account]);

  useEffect(() => {
    if (!unReadChannel || channelArn === unReadChannel) return;
    dispatchReduxToolkit(channelSetChannelUnreadList(unReadChannel));
    setUnReadChannel("");
  }, [unReadChannel]);

  useEffect(() => {
    const setAwsKeysAndConnect = async () => {
      Notification.requestPermission();
      const awsKeys = await CommonService.getChimeSecret();
      if (awsKeys) {
        AWS.config.region = process.env.REACT_APP_REACT_REGION;
        const awsKeysDecrypt = await RSADecrypt.RSADecrypt(awsKeys);
        if (awsKeysDecrypt) {
          let awsKeysDecryptData = JSON.parse(awsKeysDecrypt!)
          AWS.config.credentials = {
            accessKeyId: awsKeysDecryptData.AccessKey,
            secretAccessKey: awsKeysDecryptData.SecretKey,
          };
        }
      } else {
        console.log("Cannot get AWS keys");
      }

      const member = {
        username: account?.chimeName,
        userId: account?.chimeUserId,
      };
      messagingService.connect(member);

      dispatchReduxToolkit(channelClearChannelUnreadList());
      dispatchReduxToolkit(ChannelClearState());

      setIsConfiged(true);
    }

    if (
      isLoggedIn && account &&
      account?.chimeName &&
      account?.chimeUserId &&
      account?.hasPermission
    ) {
      setAwsKeysAndConnect();
    }

    return () => {
      messagingService.close();
    };
  }, [isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn) return;
    messagingService.subscribeToMessageUpdate(messagesProcessor);

    return () => {
      messagingService.unsubscribeFromMessageUpdate(messagesProcessor);
    };
  }, [messagingService, isLoggedIn]);

  useEffect(() => {
    if (isConfiged) {
      fetchChannels();
    }
  }, [messageProp, isConfiged]);

  useEffect(() => {
    if (isLoggedIn && redirectUrl !== '') {
      dispatchReduxToolkit(setRedirectUrl(""));
      window.location.href = redirectUrl;
    }
  }, [isLoggedIn, redirectUrl])

  return (
    <>
      <div
        className="grid-responsive-width page-main"
      >
        <div className="page-main--header-title">
          <div className="header-title">
            <p>{t("Service_Assist_Label")}</p>
          </div>
          <div className="header-btn">
            {isLoggedIn && account.hasPermission && (
              <Button
                disabled={isLoading}
                primary
                className="btn-connect-channel"
                onClick={() => setOpenCreateChannelModel(true)}
              >
                {t("Start_New_LabelBtn")}
              </Button>
            )}
            {!isLoggedIn && (
              <Button
                disabled={isLoading}
                primary
                className="btn-connect-channel"
                onClick={() => { login() }}
              >
                {t("Login_Label")}
              </Button>
            )}
          </div>
        </div>

        <div className="page-main--content">
          {isLoggedIn && account.hasPermission && isConfiged && (
            <MessagesView
              message={messageProp}
              stopChannel={(e: string) => {
                if (e !== "") {
                  setChannelStopArn(e)
                  setIsStopChannel(true);
                }
              }}
              startChattingChannel={onStartChannel}
            />
          )}
          {isLoggedIn && !account.hasPermission && (
            <div className="page-main--body-container">
              <h5>{t("Account_Permission_Label")}</h5>
            </div>
          )}

          {!isLoggedIn && (
            <div className="page-main--body-container">
              <div className="page-main--body-container--item">
                <h5>{t("Login_Is_Required_Label")}</h5>
              </div>
              {
                (navigator.userAgent.indexOf('Firefox') !== -1) &&
                <div className="page-main--body-container--item--notification">
                  <p>{t("Noted_Recommend")}</p>
                </div>
              }

            </div>

          )}

        </div>
      </div>

      {isLoading && (
        <Fragment>
          <CustomLoader enable={isLoading}></CustomLoader>
        </Fragment>
      )}

      {isInCall && (
        <ModalNotiEndMeetting
          isOpen={isInCall}
          onClose={() => setIsInCall(false)}
        ></ModalNotiEndMeetting>
      )}

      {isTranslate.languagebox && (
        <ModalTranlationOptionsPublic
          translateModeData={isTranslate}
          isOpen={isTranslate.languagebox}
          onClose={() => { }} acceptButtonText={t('Continue_panel')} />
      )}

      {
        openCreateChannelModel && <ModalCreateNewChannel isOpen={openCreateChannelModel} onClose={() => setOpenCreateChannelModel(false)} onchangeTextSearchInput={onStartChannel} sendLinkVisualConnect={sendLinkVisualConnect} sendEmailVisualConnect={sendEmailVisualConnect} onStartRandomChannel={onStartChannelRandomCode} onStartRandomVSC={onStartRandomVSC}></ModalCreateNewChannel>
      }


      {/* Remove step choose contact person => auto choose ticket creator */}

      {
        openModalShowRandomId && (<ModalShowRandomId isOpen={openModalShowRandomId} onClose={() => setOpenModalShowRandomId(false)} randomId={randomCode}></ModalShowRandomId>)
      }

      {
        displayDeleteModal && (<RDNotification isOpen={displayDeleteModal} message={errorModalMessage} onClose={() => { setDisplayDeleteModal(false) }} type={notificationType} />)
      }

      <>
        <Fragment>
          {displayJoinCurrentChannelModal && <ModalJoinCurrentChannel data={dataCurrentChannel} isOpen={displayJoinCurrentChannelModal} onClose={() => { setDataCurrentChannel(null); setDisplayJoinCurrentChannelModal(false) }} onJoinFinish={onJoinChannel} />}
          <Modal
            onClose={onTrigger}
            open={displayErrorModal}
            dimmer={{ className: "test-dimmer" }}
            size={"mini"}
            closeIcon
          >
            <Modal.Content>
              <Grid divided="vertically">
                <Grid.Row>
                  <label
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    Error
                  </label>
                </Grid.Row>
                {errorModalMessage && (
                  <Grid.Row
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    {errorModalMessage}
                  </Grid.Row>
                )}

                <Grid.Row>
                  <Button
                    className="button-search"
                    style={{ width: "100%" }}
                    onClick={onTrigger}
                  >
                    Close
                  </Button>
                </Grid.Row>
              </Grid>
            </Modal.Content>
          </Modal>
        </Fragment>
        <Modal
          onClose={() => setIsStopChannel(false)}
          open={isStopChannel}
          dimmer={{ className: "test-dimmer black-dimmer" }}
          closeOnDimmerClick={false}
          size={"mini"}
        >
          <Modal.Content>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "1rem 0",
                fontSize: "1.25rem",
              }}
            >
              {t("Stop_Channel_Question")}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                padding: "1rem 0",
              }}
            >
              <Button
                className="bwc-btn--primary"
                onClick={(e) => {
                  handleChannelDeletion(e);
                }}
              >
                Yes
              </Button>
              <Button
                className="bwc-btn--secondary"
                onClick={() => setIsStopChannel(false)}
              >
                Cancel
              </Button>
            </div>
          </Modal.Content>
        </Modal>
      </>
    </>
  );
};

export default DashBoard;
