import axios from 'axios'
import { graphConfig } from './authConfig'

export async function callAvatarGraph(token: string) {
  try {
    const response = await axios.get(graphConfig.graphAvatarEndpoint, {
      responseType: 'arraybuffer', // Set the response type to arraybuffer to handle image data
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error('Error fetching user avatar:', error)
    throw error
  }
}
