import React from "react";
import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Grid,
    Button,
    Modal,
} from "semantic-ui-react";

const ErrorModal: FC<any> = (props: any) => {
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const { t } = useTranslation();

    useEffect(() => {
        if (props.showError && props.errorMessage != null && props.errorMessage != '') {
            setErrorMessage(props.errorMessage);
            setOpenErrorModal(true);
        }
        else {
            setErrorMessage('');
        }
    }, [props]);

    const closeErrorModal = () => {
        setErrorMessage('');
        setOpenErrorModal(false);
        props.onSetErrorTrigger();
    }

    return (
        <Fragment>
            <Modal
                onClose={closeErrorModal}
                open={openErrorModal}
                dimmer={{ className: "test-dimmer" }}
                size={"mini"}
                closeIcon >
                <Modal.Content>
                    <Grid divided='vertically'>
                        <Grid.Row>
                            <label style={
                                {
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%"
                                }}>
                                Error
                            </label>
                        </Grid.Row>
                        {errorMessage &&
                            <Grid.Row style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                                margin: "10px"
                            }}>
                                {errorMessage}
                            </Grid.Row>}

                        <Grid.Row>
                            <Button
                                className="button-search"
                                style={{ width: "100%" }}
                                onClick={closeErrorModal}>
                                Close
                            </Button>
                        </Grid.Row>
                    </Grid>
                </Modal.Content>
            </Modal>
        </Fragment>
    )
}

export default ErrorModal;