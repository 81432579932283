import React from "react";
import { useTranslation } from "react-i18next";
import ButtonAction from "./InputComponent/ButtonAction";
import InputAction from "./InputComponent/InputAction";
import { useSelector } from "react-redux";
import { selectStepAction, stepENUM } from "../../../../redux/assistantBot";

interface UserInputProps {
  onSubmit: any;
}

const UserInput = (props: UserInputProps) => {
  const { t } = useTranslation();
  const stepAction = useSelector(selectStepAction);

  return (
    <>
      {
        (stepAction === stepENUM.typeEdit || stepAction === stepENUM.chooseAction || stepAction === stepENUM.typeStart || stepAction === stepENUM.goBackToFirstStep) && (
          <ButtonAction onSubmit={props.onSubmit} type={stepAction}></ButtonAction>
        )
      }

      {
        (stepAction === stepENUM.typeName || stepAction === stepENUM.typeEditName) && (
          <InputAction onSubmit={props.onSubmit} title={t("Your_name_input_label")} type="name"></InputAction>
        )
      }

      {
        (stepAction === stepENUM.typeIdNumber || stepAction === stepENUM.typeEditCode) && (
          <InputAction onSubmit={props.onSubmit} title={t("Code_input_label")} type="code"></InputAction>
        )
      }
    </>
  );
};

export default UserInput;
