import AWS from 'aws-sdk'
import { CommonService } from './Common'
import { RSADecrypt } from './RSA_Encrypt'

export class AttachmentService {
  private static instance: AttachmentService

  public static getInstance(): AttachmentService {
    if (!AttachmentService.instance) {
      AttachmentService.instance = new AttachmentService()
    }

    return AttachmentService.instance
  }

  public myBucket: AWS.S3
  s3_bucket: string

  constructor() {
    AWS.config.getCredentials(function (err) {
      if (err) {
        console.log(err.stack)
        const setAwsCredentials = async () => {
          AWS.config.region = process.env.REACT_APP_REACT_REGION

          const awsKeys = await CommonService.getChimeSecretPublic()
          if (awsKeys) {
            const awsKeysDecrypt = await RSADecrypt.RSADecrypt(awsKeys)
            if (awsKeysDecrypt) {
              let awsKeysDecryptData = JSON.parse(awsKeysDecrypt!)
              AWS.config.credentials = {
                accessKeyId: awsKeysDecryptData.AccessKey,
                secretAccessKey: awsKeysDecryptData.SecretKey,
              }
            }
          } else {
            console.log('Cannot get AWS keys')
          }
        }

        setAwsCredentials()
      }
    })

    this.s3_bucket = process.env.REACT_APP_AWS_S3_BUCKET
      ? process.env.REACT_APP_AWS_S3_BUCKET
      : ''

    this.myBucket = new AWS.S3({
      params: { Bucket: this.s3_bucket },
      region: process.env.REACT_APP_REACT_REGION,
    })
  }

  public uploadObject = (file: any, channelArnId: string, uuid: string) => {
    const params = {
      Body: file,
      Bucket: this.s3_bucket,
      Key: `${channelArnId}/${uuid}`,
    }

    return this.myBucket.upload(params)
  }

  public downloadObject = (key: string) => {
    const params = {
      Bucket: this.s3_bucket,
      Key: key,
    }

    return this.myBucket.getObject(params)
  }

  public getPresignURL = (key: string) => {
    const params = {
      Bucket: this.s3_bucket,
      Key: key,
      Expires: 300,
    }

    return this.myBucket.getSignedUrl('getObject', params)
  }

  public checkObjectIsExist = async (key: string) => {
    const params = {
      Bucket: this.s3_bucket,
      Key: key,
    }

    return await this.myBucket.headObject(params)
  }
}
