import { AuthState } from "../redux/auth";

export default class RedirectHelper {
    static redirectToSubDomain(url: string, client_id: string, countryCode: string, lang: string) {
        if (url.endsWith("/"))
            window.location.href = `${url}?c=${countryCode}&l=${lang}&domain=${url}&clientid=${client_id}`;
        else
            window.location.href = `${url}/?c=${countryCode}&l=${lang}&domain=${url}&clientid=${client_id}`;
    }

    static redirectToMainSite() {
        window.location.href = this.getDefaultClientRoot();
    }

    static getDefaultClientRoot(): string {
        if (!!process.env.REACT_APP_DEFAULT_CLIENTROOT)
            return process.env.REACT_APP_DEFAULT_CLIENTROOT
        return '';
    }

    static getClientRoot(authState: AuthState) {
        if (!!authState && !!authState.domain && authState.domain !== "")
            return authState.domain
        return this.getDefaultClientRoot();
    }

    static getClientId(authState: AuthState) {
        if (!!authState && !!authState.clientId && authState.clientId !== "")
            return authState.clientId;
        if (!!process.env.REACT_APP_DEFAULT_CLIENTID)
            return process.env.REACT_APP_DEFAULT_CLIENTID;
        return '';
    }

    static extractDomainName(domain: string | undefined) {
        if (!domain || domain === "")
            return domain;
        let startIndex = domain.indexOf("//") >= 0 ? domain.indexOf("//") + 2 : 0;
        let endIndex = domain.indexOf("/", startIndex) > 0 ? domain.indexOf("/", startIndex) : domain.length;
        return domain.substring(startIndex, endIndex);
    }
}