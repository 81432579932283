import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import ResponseResultBase from '../models/ResponseResultBase'
import TicketRequest from '../models/TicketRequest'
import httpPCS from '../services/HttpServicePCS'
import { AppDispatch, RootState } from './store'

export interface TicketState {
  id: string
  ciamId: string
  firstName: string
  lastName: string
  customerId: number
  phoneNumber: string
  mobilePhone: string
  email: string
  ticketDetails: TicketDetails
  contactPersons: ContactPerson[]
  selectedContactPerson: ContactPerson | null
  hasWSA: boolean
}

export interface ContactPerson {
  allowPersonalAccount: boolean
  ciamId: string
  customerId: number
  email: string
  employeeId: number
  firstName: string
  hasPersonalAccountPending: boolean
  id: string
  isActive: boolean
  isProxyOf: number
  lastName: string
  mobilePhone: string
  phoneNumber: string
  title: string
  language: {
    country: string
    defaultLanguage: boolean
    id: number
    iso2: string
    language: string
    lcid: string
    localized: string
  }
  role: {
    id: number
    language: string
    localized: string
    role: string
  }
  titulation: {
    id: number
    language: string
    localized: string
    titulation: string
  },
  hasWSA: boolean
}

interface TicketDetails {
  customer: Customer
  topic: Topic
  ticketId: number
}

interface Topic {
  serviceType: string
}

interface Customer {
  name1: string
  assignedAccount: AssignedAccount
}
interface AssignedAccount {
  name1: string
}

export const initialState: TicketState = {
  id: '',
  ciamId: '',
  firstName: '',
  lastName: '',
  customerId: -1,
  phoneNumber: '',
  mobilePhone: '',
  email: '',
  ticketDetails: {
    ticketId: 0,
    customer: {
      name1: '',
      assignedAccount: {
        name1: '',
      },
    },
    topic: {
      serviceType: '',
    },
  },
  contactPersons: [],
  selectedContactPerson: null,
  hasWSA: false
}

export const getTicketInfor = createAsyncThunk<
  ResponseResultBase<TicketState> | null,
  TicketRequest,
  {
    dispatch: AppDispatch
    state: RootState
    rejectValue: AxiosError
  }
>('ticket/getTicketInfor', async (ticketRequest, thunkApi) => {
  var result: ResponseResultBase<TicketState> | null = null
  try {
    var response = await httpPCS.post<ResponseResultBase<TicketState>>(
      `/api/Ticket/GetTicketInfor`,
      ticketRequest,
    )
    if (
      response.status === 204 ||
      response.status === 200 ||
      response.status === 201
    ) {
      result = {
        dataObject: response.data.dataObject,
        errorMessages: [],
        httpStatusCode: 200,
        success: true,
      }
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error)
    }
  }
  return result
})

export const selectTicketState = (rootState: RootState) => rootState.ticket
export const selectTicketContactPerson = (rootState: RootState) =>
  rootState.ticket.contactPersons
export const selectSelectedContactPerson = (rootState: RootState) =>
  rootState.ticket.selectedContactPerson

const ticketReducerSlice = createSlice({
  name: 'ticketReducer',
  initialState: initialState,
  reducers: {
    getTicketState: (state) => {
      return { ...state }
    },
    addSelectedContactPerson: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        selectedContactPerson: action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTicketInfor.fulfilled, (state, { payload }) => {
      if (
        payload &&
        payload.dataObject != null &&
        (payload.httpStatusCode === 200 ||
          payload.httpStatusCode === 201 ||
          payload.httpStatusCode === 204)
      ) {
        state.ciamId = payload.dataObject.ciamId
        state.id = payload.dataObject.id
        state.firstName = payload.dataObject.firstName
        state.lastName = payload.dataObject.lastName
        state.customerId = payload.dataObject.customerId
        state.phoneNumber = payload.dataObject.phoneNumber
        state.mobilePhone = payload.dataObject.mobilePhone
        state.email = payload.dataObject.email
        state.contactPersons = payload.dataObject.contactPersons
        state.ticketDetails = payload.dataObject.ticketDetails
        state.hasWSA = payload.dataObject.hasWSA
      }
    })
  },
})

export const {
  getTicketState,
  addSelectedContactPerson,
} = ticketReducerSlice.actions
export default ticketReducerSlice.reducer
