import { Fragment, FC } from "react";
import boschSupergraphic from "../../../../assets/Bosch-Supergraphic_.png";
import logo from "../../../../assets/Bosch_DigitalLogo_BlackAnchor_144x96px.png";
import "./css/HeaderLandingPage.css";
import { Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React from "react";
import routes from "../../../../routes";
import TranslateButton from "./TranslateButton";

interface HeaderLandingPageProps {
  noTranslate?: boolean
}

const HeaderLandingPage: FC<
  HeaderLandingPageProps
> = ({ noTranslate }) => {
  const { t } = useTranslation();
  return (
    <div className="header-private-pcs">
      <div className="supergraphic">
        <Image src={boschSupergraphic} fluid></Image>
      </div>
      <div className="grid-responsive-width page-header-top-private">
        <div className="header-large-box--logo">
          <Image className="logo-image" src={logo}></Image>
        </div>
        {
          !noTranslate && <TranslateButton></TranslateButton>
        }
      </div>
    </div>
  );
};

export default HeaderLandingPage;
