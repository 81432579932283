import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";



export interface VisualConnectListState {
    phoneNumber: string
    active: boolean
    hasFirstStart: boolean
    isSendEmail: boolean
}

export const initialVisualConnectListState: VisualConnectListState = {
    phoneNumber: "",
    active: false,
    hasFirstStart: false,
    isSendEmail: false
}

export const selectVisualConnectListState = (rootState: RootState) => rootState.visualConnectList;

const visualConnectListReducerSlice = createSlice({
    name: 'visualConnectListReducer',
    initialState: initialVisualConnectListState,
    reducers: {
        visualConnectClearList: (state) => {
            return {
                ...state,
                phoneNumber: "",
                active: false,
                hasFirstStart: false,
                isSendEmail: false
            }
        },
        visualConnectSetList: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                phoneNumber: action.payload
            }
        },
        visualConnectSetActive: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                active: action.payload
            }
        },
        visualConnectSetFirstStart: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                hasFirstStart: action.payload
            }
        },
        visualConnectIsSendEmail: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                isSendEmail: action.payload
            }
        }
    }
});

export const { visualConnectClearList, visualConnectSetList, visualConnectSetActive, visualConnectSetFirstStart, visualConnectIsSendEmail } = visualConnectListReducerSlice.actions;
export default visualConnectListReducerSlice.reducer;