import { LogLevel } from 'amazon-chime-sdk-js'

export const AppConsts = {
  appBaseUrl: '',
  //remoteServiceBaseUrl: 'https://redi-hub-api.azurewebsites.net',
  //remoteServiceBaseUrl: 'https://app-dev-germanywestcentral-rediapi.azurewebsites.net',
  //remoteServiceBaseUrl: 'http://localhost:15825',
  appLanguages: [
    { key: 'English', text: 'English', value: 'en' },
    { key: 'Dansk', text: 'Dansk', value: 'da' },
  ],
  homeSideLink: [
    {
      countryCode: 'AT',
      aboutUs: 'https://www.bosch.at/unser-unternehmen/bosch-in-oesterreich/',
      news: 'https://www.bosch.at/news-and-stories/technik-fuers-leben-preis/',
      position: 'https://www.bosch.at/karriere/',
    },
    {
      countryCode: 'BE',
      aboutUs: 'https://www.bosch.be/our-company/our-history/',
      news:
        'https://www.bosch.be/news-and-stories/climatemattersatbosch-papillon-project/',
      position: 'https://www.bosch.be/careers/',
    },
    {
      countryCode: 'CH',
      aboutUs: 'https://www.bosch.ch/unser-unternehmen/bosch-in-der-schweiz/',
      news: 'https://www.bosch.ch/news-and-stories/geschaeftsjahr-2020/',
      position: 'https://www.bosch.ch/karriere/starte-deine-karriere/',
    },
    {
      countryCode: 'DK',
      aboutUs: '',
      news: 'https://www.bosch.dk/nyheder/',
      position: 'https://www.bosch.dk/karrierer/',
    },
    {
      countryCode: 'DE',
      aboutUs: 'https://www.bosch.de/unser-unternehmen/bosch-gruppe-weltweit/',
      news: 'https://www.bosch.com/de/unternehmen/geschaeftsbericht/',
      position: 'https://www.bosch.de/karriere/jobs/',
    },
    {
      countryCode: 'ES',
      aboutUs: 'https://www.grupo-bosch.es/nuestra-empresa/bosch-en-espana/',
      news: 'https://www.grupo-bosch.es/productos-y-servicios/',
      position: 'https://www.grupo-bosch.es/trabaja-con-nosotros/',
    },
    {
      countryCode: 'FI',
      aboutUs: '',
      news:
        'https://www.bosch.fi/uutisia-ja-tarinoita/ces-2021-boschin-aiot-ratkaisuissa-tekoaely-ja-esineiden-internet-valjastetaan-ihmisen-ja-ympaeristoen-hyvaeksi/',
      position: '',
    },
    {
      countryCode: 'LU',
      aboutUs: 'https://www.bosch.be/our-company/our-history/',
      news:
        'https://www.bosch.be/news-and-stories/climatemattersatbosch-papillon-project/',
      position: 'https://www.bosch.be/careers/',
    },
    {
      countryCode: 'NL',
      aboutUs: 'https://www.bosch.nl/ons-bedrijf/onze-geschiedenis/',
      news: 'https://www.bosch.nl/nieuws/climatemattersatbosch-in-tilburg/',
      position: 'https://www.bosch.nl/carriere/',
    },
    {
      countryCode: 'NO',
      aboutUs: '',
      news:
        'https://www.bosch.no/nyheter/ces-2021-bosch-setter-sin-lit-til-kunstig-intelligens-og-tilkoblingsmuligheter/',
      position: '',
    },
    {
      countryCode: 'SE',
      aboutUs: '',
      news:
        'https://www.bosch.se/nyheter/ces-2021-bosch-ai-system-anvaends-foer-att-navigera-minirobotar-pa-manen/',
      position: 'https://www.bosch.se/jobba-hos-oss/',
    },
  ],

  listAppItem: [
    {
      id: 1,
      title: "Remote Diagnostics",
      image: "Picture4.png",
      link: process.env.REACT_APP_REDI_PAGE || "",
    },
    {
      id: 2,
      title: "Service Training",
      image: "Picture1.png",
      link: process.env.REACT_APP_BAT_PAGE || "",
    },
  ]
}

export const VIDEO_INPUT = {
  NONE: 'None',
  BLUE: 'Blue',
  SMPTE: 'SMPTE Color Bars',
}

export const AUDIO_INPUT = {
  NONE: 'None',
  440: '440 Hz',
}

export const MAX_REMOTE_VIDEOS = 25

export const AVAILABLE_AWS_REGIONS = {
  'us-east-1': 'United States (N. Virginia)',
  'af-south-1': 'Africa (Cape Town)',
  'ap-northeast-1': 'Japan (Tokyo)',
  'ap-northeast-2': 'Korea (Seoul)',
  'ap-south-1': 'India (Mumbai)',
  'ap-southeast-1': 'Singapore',
  'ap-southeast-2': 'Australia (Sydney)',
  'ca-central-1': 'Canada',
  'eu-central-1': 'Germany (Frankfurt)',
  'eu-north-1': 'Sweden (Stockholm)',
  'eu-south-1': 'Italy (Milan)',
  'eu-west-1': 'Ireland',
  'eu-west-2': 'United Kingdom (London)',
  'eu-west-3': 'France (Paris)',
  'sa-east-1': 'Brazil (São Paulo)',
  'us-east-2': 'United States (Ohio)',
  'us-west-1': 'United States (N. California)',
  'us-west-2': 'United States (Oregon)',
}

export const VIDEO_INPUT_QUALITY = {
  '360p': '360p (nHD) @ 15 fps (600 Kbps max)',
  '540p': '540p (qHD) @ 15 fps (1.4 Mbps max)',
  '720p': '720p (HD) @ 15 fps (1.4 Mbps max)',
}

export const SDK_LOG_LEVELS = {
  debug: LogLevel.DEBUG,
  info: LogLevel.INFO,
  warn: LogLevel.WARN,
  error: LogLevel.ERROR,
  off: LogLevel.OFF,
}

export const DATA_MESSAGE_LIFETIME_MS = 300000
export const DATA_MESSAGE_TOPIC = 'ChimeComponentLibraryDataMessage'

export const DATA_MESSAGE_TYPE = {
  Chat: 'CHAT',
  Command: 'COMMAND',
}

export const DATA_MESSAGE_ACTION = {
  SendMessage: 'send_message',
  TurnOnFlash: 'turn_on_flash',
  TurnOffFlash: 'turn_off_flash',
  OCR: "ocr",
  TurnOnAR: "turn_on_ar",
  TurnOffAR: "turn_off_ar",
  NewMember: "new_joiner",
  Pointer: "pointer",
  UndoAR: "undo_ar",
  RemoveAR: "remove_ar",
  ArAvailable: "ar_is_available",
  ArNoAvailable: "ar_not_available",
  TurnOnDraw: "turn_on_draw",
  TurnOffDraw: "turn_off_draw",
  Draw: "draw",
  TurnOnMouse: "turn_on_mouse",
  TurnOffMouse: "turn_off_mouse",
  MouseMove: "mouse_move",
  PauseVideo: 'pause_video',
  UnPauseVideo: 'un_pause_video',
  IsMobile: 'is_mobile',
  onVoiceCall: 'voice_call',
  onVideoCall: 'video_call',
  acceptVideoCall: "accept_video_call",
  rejectVideoCall: "reject_video_call"
}

export const countryOptions = [
  { key: 'auto', value: 'auto', text: 'Detect Language' },
  { key: 'af', value: 'af', flag: 'af', text: 'Afrikaans' },
  { key: 'fa', value: 'fa', flag: 'af', text: 'Farsi (Persian)' },
  { key: 'fa-AF', value: 'fa-AF', flag: 'af', text: 'Dari' },
  { key: 'sq', value: 'sq', flag: 'al', text: 'Albanian' },
  { key: 'ar', value: 'ar', flag: 'dz', text: 'Arabic' },
  { key: 'hy', value: 'hy', flag: 'am', text: 'Armenian' },
  { key: 'nl', value: 'nl', flag: 'aw', text: 'Dutch' },
  { key: 'az', value: 'az', flag: 'az', text: 'Azerbaijani' },
  { key: 'bn', value: 'bn', flag: 'bd', text: 'Bengali' },
  { key: 'bs', value: 'bs', flag: 'ba', text: 'Bosnian' },
  { key: 'bg', value: 'bg', flag: 'bg', text: 'Bulgarian' },
  { key: 'zh', value: 'zh', flag: 'cn', text: 'Chinese (Simplified)' },
  { key: 'zh-TW', value: 'zh-TW', flag: 'cn', text: 'Chinese (Traditional)' },
  { key: 'hr', value: 'hr', flag: 'hr', text: 'Croatian' },
  { key: 'cs', value: 'cs', flag: 'cz', text: 'Czech' },
  { key: 'da', value: 'da', flag: 'dk', text: 'Danish' },
  { key: 'en', value: 'en', flag: 'gb eng', text: 'English' },
  { key: 'et', value: 'et', flag: 'ee', text: 'Estonian' },
  { key: 'am', value: 'am', flag: 'et', text: 'Amharic' },
  { key: 'fi', value: 'fi', flag: 'fi', text: 'Finnish' },
  { key: 'fr', value: 'fr', flag: 'fr', text: 'French' },
  { key: 'ka', value: 'ka', flag: 'ge', text: 'Georgian' },
  { key: 'de', value: 'de', flag: 'de', text: 'German' },
  { key: 'el', value: 'el', flag: 'gr', text: 'Greek' },
  { key: 'ht', value: 'ht', flag: 'ht', text: 'Haitian Creole' },
  { key: 'hu', value: 'hu', flag: 'hu', text: 'Hungarian' },
  { key: 'ga', value: 'ga', flag: 'is', text: 'Irish' },
  { key: 'hi', value: 'hi', flag: 'in', text: 'Hindi' },
  { key: 'gu', value: 'gu', flag: 'in', text: 'Gujarati' },
  { key: 'kn', value: 'kn', flag: 'in', text: 'Kannada' },
  { key: 'mr', value: 'mr', flag: 'in', text: 'Marathi' },
  { key: 'ml', value: 'ml', flag: 'in', text: 'Malayalam' },
  { key: 'pa', value: 'pa', flag: 'in', text: 'Punjabi' },
  { key: 'ur', value: 'ur', flag: 'in', text: 'Urdu' },
  { key: 'te', value: 'te', flag: 'in', text: 'Telugu' },
  { key: 'id', value: 'id', flag: 'id', text: 'Indonesian' },
  { key: 'is', value: 'is', flag: 'ie', text: 'Icelandic' },
  { key: 'he', value: 'he', flag: 'il', text: 'Hebrew' },
  { key: 'it', value: 'it', flag: 'it', text: 'Italian' },
  { key: 'ja', value: 'ja', flag: 'jp', text: 'Japanese' },
  { key: 'kk', value: 'kk', flag: 'kz', text: 'Kazakh' },
  { key: 'sw', value: 'sw', flag: 'ke', text: 'Swahili' },
  { key: 'lv', value: 'lv', flag: 'lv', text: 'Latvian' },
  { key: 'lt', value: 'lt', flag: 'lt', text: 'Lithuanian' },
  { key: 'mk', value: 'mk', flag: 'mk', text: 'Macedonian' },
  { key: 'ms', value: 'ms', flag: 'my', text: 'Malay' },
  { key: 'mt', value: 'mt', flag: 'mt', text: 'Maltese' },
  { key: 'es-MX', value: 'es-MX', flag: 'mx', text: 'Spanish (Mexico)' },
  { key: 'mn', value: 'mn', flag: 'mn', text: 'Mongolian' },
  { key: 'ha', value: 'ha', flag: 'ng', text: 'Hausa' },
  { key: 'no', value: 'no', flag: 'no', text: 'Norwegian (Bokmål)' },
  { key: 'ps', value: 'ps', flag: 'pk', text: 'Pashto' },
  { key: 'tl', value: 'tl', flag: 'ph', text: 'Filipino, Tagalog' },
  { key: 'pl', value: 'pl', flag: 'pl', text: 'Polish' },
  { key: 'pt', value: 'pt', flag: 'pt', text: 'Portuguese (Brazil)' },
  { key: 'pt-PT', value: 'pt-PT', flag: 'pt', text: 'Portuguese (Portugal)' },
  { key: 'ro', value: 'ro', flag: 'ro', text: 'Romanian' },
  { key: 'ru', value: 'ru', flag: 'ru', text: 'Russian' },
  { key: 'sr', value: 'sr', flag: 'cs', text: 'Serbian' },
  { key: 'sk', value: 'sk', flag: 'sk', text: 'Slovak' },
  { key: 'sl', value: 'sl', flag: 'si', text: 'Slovenian' },
  { key: 'so', value: 'so', flag: 'so', text: 'Somali' },
  { key: 'ko', value: 'ko', flag: 'kr', text: 'Korean' },
  { key: 'es', value: 'es', flag: 'es', text: 'Spanish' },
  { key: 'si', value: 'si', flag: 'lk', text: 'Sinhala' },
  { key: 'sv', value: 'sv', flag: 'se', text: 'Swedish' },
  { key: 'th', value: 'th', flag: 'th', text: 'Thai' },
  { key: 'tr', value: 'tr', flag: 'tr', text: 'Turkish' },
  { key: 'uk', value: 'uk', flag: 'ua', text: 'Ukrainian' },
  { key: 'uz', value: 'uz', flag: 'uz', text: 'Uzbek' },
  { key: 'vi', value: 'vi', flag: 'vn', text: 'Vietnamese' },
  { key: 'cy', value: 'cy', flag: 'gb wls', text: 'Welsh' },
]

export const TimeOutOnlineStatus = 30000; //5000ms = 5s
export const TimeOutOnlineStatusTrigger = 2000; //2000ms = 2s
export const TimeOutOnlineStatusChange = 60; //60s
export const TimeOutOnlineStatusTriggerCountDown = 120; //120s
export const OnlinePresenceStatus = {
  online: "online",
  offline: "offline",
  busy: "busy",
  onhold: "onhold"
};