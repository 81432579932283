import React, { Fragment, useEffect, useState } from 'react'
import { useAppDispatch } from '../../../../redux/store'
import './PublicMessageBox.css'
import { useTranslation } from 'react-i18next'
import PublicMessages from '../PublicMessages/PublicMessages'
import { useSelector } from 'react-redux'
import { ChannelSetMessage, selectChannelMessage, selectIsTranslate } from '../../../../redux/chimeSlide'
import { createMemberArn, listChannelMessages } from '../../../../api/ChimeAPI'
import PublicInputMessage from '../PublicInputChat/PublicInputMessage'
import { CommonService } from '../../../../services/Common'
import CustomLoader from '../../../../common-components/RDComponents/CustomLoader'
interface MessagesViewProps {
  message: any[]
  stopChannel: Function
  userId: string
  channelArn: string
  uuid: string
}

const PublicMessageBox = ({
  message,
  stopChannel,
  userId,
  channelArn,
  uuid
}: MessagesViewProps) => {
  const dispatchReduxToolkit = useAppDispatch()
  const browserLanguage = window.navigator.language
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false);
  const messages = useSelector(selectChannelMessage)
  const [statusMessageShow, setStatusMessageShow] = useState(-1) //useState(false);
  const isTranslate = useSelector(selectIsTranslate);
  
  useEffect(() => {
    const updateMessage = async () => {
      if (channelArn && channelArn !== '') {
        const newMessages = await listChannelMessages(
          channelArn,
          userId
        )

        const message_translate = await Promise.all(
          newMessages.Messages.map(async (message: any) => {
            if (
              createMemberArn(userId) !== message.Sender.Arn &&
              isTranslate.status
            ) {
              // const sourceLanguageCode = JSON.parse(message.Metadata)?.language || "";
              const translateMessage = await CommonService.liveTranslateMessage(
                message.Content,
                isTranslate.langSource,
                isTranslate.langTarget,
              )
              return { ...message, Content: translateMessage }
            }
            return message
          }),
        )
        await dispatchReduxToolkit(
          ChannelSetMessage({
            ChannelMessages: message_translate,
            NextToken: newMessages.NextToken,
          }),
        )
        setStatusMessageShow(1)
      }
    }
    updateMessage()
  }, [channelArn, message, isTranslate])

  return (
    <div className="publicMessageBox">
      <div className="publicMessageBox-container">
        {statusMessageShow == 1 && (
          <Fragment>
            <PublicMessages
              messages={messages}
              channelArn={channelArn}
              userId={userId}
              uuid={uuid} 
              translateModeData={isTranslate}/>
            <PublicInputMessage
              activeChannelArn={channelArn}
              member={
                userId
              }
              browserLanguage={browserLanguage}
            />
          </Fragment>
        )}

      </div>
      {isLoading && (
        <Fragment>
          <CustomLoader enable={isLoading}></CustomLoader>
        </Fragment>
      )}
    </div>
  )
}

export default PublicMessageBox
