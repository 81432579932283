import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_EN } from "./en/translations";
import { TRANSLATIONS_ES } from "./es/translations";
import { TRANSLATIONS_DA } from "./da/translations";
import { TRANSLATIONS_DE } from "./de/translations";
import { TRANSLATIONS_FI } from "./fi/translations";
import { TRANSLATIONS_FR } from "./fr/translations";
import { TRANSLATIONS_NL } from "./nl/translations";
import { TRANSLATIONS_NN } from "./nn/translations";
import { TRANSLATIONS_PL } from "./pl/translations";
import { TRANSLATIONS_PT } from "./pt/translations";
import { TRANSLATIONS_SWE } from "./swe/translations";
import { TRANSLATIONS_CZ } from "./cz/translations";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        resources: {
            "en": {
                translation: TRANSLATIONS_EN
            },
            "es": {
                translation: TRANSLATIONS_ES
            },
            "da": {
                translation: TRANSLATIONS_DA
            },
            "de": {
                translation: TRANSLATIONS_DE
            },
            "fi": {
                translation: TRANSLATIONS_FI
            },
            "fr": {
                translation: TRANSLATIONS_FR
            },
            "nl": {
                translation: TRANSLATIONS_NL
            },
            "nn": {
                translation: TRANSLATIONS_NN
            },
            "pl": {
                translation: TRANSLATIONS_PL
            },
            "pt": {
                translation: TRANSLATIONS_PT
            },
            "swe": {
                translation: TRANSLATIONS_SWE
            },
            "cz": {
                translation: TRANSLATIONS_CZ
            }
        },
        parseMissingKeyHandler: function (key: string) {
            let str = key as keyof typeof TRANSLATIONS_EN;
            let ret = TRANSLATIONS_EN[str];
            if (!ret)
                ret = key;
            return ret;
        }
    });

export default i18n;