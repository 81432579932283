import React, { Fragment } from 'react';
import { Label } from 'amazon-chime-sdk-component-library-react';

import MicrophoneActivityPreviewBar from './MicrophoneActivityPreviewBar';

const MicrophoneActivityPreview = () => {
    return (
        <Fragment>
            <Label style={{ display: 'block', marginBottom: '.5rem' }}>
                Microphone activity
            </Label>
            <MicrophoneActivityPreviewBar />
        </Fragment>
    );
};

export default MicrophoneActivityPreview;
