import axios from 'axios'
import ChannelNotification from '../models/ChannelNotification'
import ChannelEmailNotification from '../models/ChannelEmailNotification'
import httpPCS from './HttpServicePCS'
import httpPCSPublic from './HttpServicePCSPublic'

export class CommonService {
  constructor() {}

  public static async sendChannelNotification(
    channelNotification: ChannelNotification,
  ) {
    try {
      var response = await httpPCS.post(
        `/api/ChannelNotification/ChannelSendNotification/`,
        channelNotification,
      )
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return null
      }
    }
    return null
  }

  public static async deleteAllChannelAttachments(channel: string) {
    try {
      var response = await httpPCS.post(
        `/api/Document/DeleteAllFiles?channel=${channel}`,
      )
      if (response.status == 200) {
        return true
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  }

  public static async getChimeSecret() {
    try {
      const headers = {
        ApiKey: process.env.REACT_APP_PCS_KEY || '',
      }
      var response = await httpPCS.get<any>(`/api/chime/GetChimeSecret/`, {
        headers,
      })
      return response.data
    } catch (error) {}

    return null
  }

  public static async sendSMSToStartVideoSession(
    channelNotification: ChannelNotification,
  ) {
    try {
      var response = await httpPCS.post(
        `/api/ChannelNotification/ChimeChannelSendSms/`,
        channelNotification,
      )
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return null
      }
    }
    return null
  }

  public static async sendEmailToStartVideoSession(
    channelEmailNotification: ChannelEmailNotification,
  ) {
    try {
      var response = await httpPCS.post(
        `/api/ChannelNotification/ChimeChannelSendEmail/`,
        channelEmailNotification,
      )
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return null
      }
    }
    return null
  }

  public static async getTicketInfor(data: any) {
    try {
      var response = await httpPCS.post(`/api/Ticket/GetTicketInfor`, data)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return null
      }
    }
    return null
  }
  public static async getChimeUserForPublic(data: any) {
    try {
      const headers = {
        ApiKey: process.env.REACT_APP_PCS_KEY || '',
      }
      var response = await httpPCSPublic.post(`/api/Chime/GetChimeUser`, data, {
        headers,
      })
      return response.data.value
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return null
      }
    }
    return null
  }

  public static async getChimeSecretPublic() {
    try {
      const headers = {
        ApiKey: process.env.REACT_APP_PCS_KEY || '',
      }
      var response = await httpPCSPublic.get<any>(
        `/api/chime/GetChimeSecret/`,
        { headers },
      )
      return response.data
    } catch (error) {}

    return null
  }

  public static async detectLanguage(data: any) {
    try {
      const headers = {
        ApiKey: process.env.REACT_APP_PCS_KEY || '',
      }
      var response = await httpPCSPublic.post<any>(
        `/api/chime/DetectLanguage/`,
        data,
        { headers },
      )
      return response.data
    } catch (error) {}

    return null
  }

  public static async liveTranslateMessage(
    textContent: string,
    sourceLanguageCode: string,
    targetLanguageCode: string,
  ) {
    if (
      sourceLanguageCode === '' ||
      targetLanguageCode === '' ||
      sourceLanguageCode === targetLanguageCode
    ) {
      return textContent
    }
    const data = {
      text: textContent,
      sourceLanguageCode: sourceLanguageCode,
      targetLanguageCode: targetLanguageCode,
    }
    const headers = {
      ApiKey: process.env.REACT_APP_PCS_KEY || '',
    }
    const res = await httpPCSPublic.post(`/api/Chime/TranslateMessage`, data, {
      headers,
    })
    return res.data.translatedText
  }
}
