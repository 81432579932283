import React, { Fragment, useEffect, useState } from 'react'
import { Grid, Loader, Button, Modal, Image, Icon } from 'semantic-ui-react'
import Messages from '../../components/messages/Messages'
import Input from '../../containers/InputChat/InputMessage'
import { useAppDispatch } from '../../redux/store'
import { useSelector } from 'react-redux'
import {
  channelSetChannel,
  ChannelSetMessage,
  selectChannelArn,
  selectChannelMessage,
  selectChannelName,
  selectIsTranslate,
  selectMeetingIDOnLive,
  selectMessageWithLinkId,
  selectMetadata,
  selectOnHanldeClearMeeting,
  selectOnHanldeLeaveMeeting,
  SetMeetingIDOnLive,
  SetMessageWithLinkId,
  SetOnHanldeClearMeeting,
  SetOnHanldeLeaveMeeting,
} from '../../redux/chimeSlide'
import {
  createMemberArn,
  describeChannel,
  listChannelMessages,
  MessageType,
  Persistence,
  sendChannelMessage,
  updateChannelMessage,
  updateChannelReadMarker,
} from '../../api/ChimeAPI'
import './style.css'
import { selectAccount } from '../../redux/account'
import { ChannelList } from 'amazon-chime-sdk-component-library-react'
import {
  channelPopChannelUnreadList,
  selectChannelAllListState,
  selectChannelUnreadListState,
} from '../../redux/channel'
import {
  createMeeting,
  meetingSetChannelName,
  selectMeetingState,
} from '../../redux/meeting'
import { liveTranslateMessage } from '../../helpers/api'
import httpPCS from '../../services/HttpServicePCS'
import { CommonService } from '../../services/Common'
import ListMemberChannelModal from '../../components/modals/ListMemberChannelModal'
import { ReactComponent as Chat } from '../../assets/svg/livechat.svg'
import { ReactComponent as VoiceCall } from '../../assets/svg/call.svg'
import { ReactComponent as VoiceCallBlack } from '../../assets/svg/call_black.svg'
import { ReactComponent as CloseChannel } from '../../assets/svg/close.svg'
import { ReactComponent as Notification } from '../../assets/svg/notification.svg'
import { useTranslation } from 'react-i18next'
import ARIcon from '../../assets/AR-white.png'
import ModalSendInviteChattingChannel from '../../components/modals/ModalSendInviteChattingChannel'
import ModalSendInviteVisualConnect from '../../components/modals/ModalSendInviteVisualConnect'
import {
  visualConnectSetList,
  visualConnectSetActive,
  selectVisualConnectListState,
  visualConnectIsSendEmail,
  visualConnectClearList,
} from '../../redux/visualConnect'
import ModalNotiEndMeetting from '../../components/modals/ModalNotiEndMeetting'
import { ReactComponent as Antendees } from '../../assets/svg/user-mouth-spreechless.svg'
import Boschicon from '../../common-components/boschIcon/Boschicon'
import { selectIsSignIn } from '../../redux/auth'
import { loginRequest, msalInstance } from '../../authConfig'
import { Buffer } from 'buffer';
import { addSelectedContactPerson, getTicketInfor, selectSelectedContactPerson, selectTicketState } from '../../redux/ticket'
import CustomLoader from '../../common-components/RDComponents/CustomLoader'
import useBroadcast from '../../hooks/useBroadcast'
import { callAvatarGraph } from '../../graph'
interface MessagesViewProps {
  message: any[]
  stopChannel: Function
  startChattingChannel: Function
}

const MessagesView = ({
  message,
  stopChannel,
  startChattingChannel,
}: MessagesViewProps) => {
  const dispatchReduxToolkit = useAppDispatch()
  const messages = useSelector(selectChannelMessage)
  const account = useSelector(selectAccount)
  const channelArn = useSelector(selectChannelArn)
  const channelName = useSelector(selectChannelName)
  const [statusMessageShow, setStatusMessageShow] = useState(-1) //useState(false);
  const meetingData = useSelector(selectMeetingState)
  const channelList = useSelector(selectChannelAllListState)
  const channelListUnread = useSelector(selectChannelUnreadListState)
  const visualConnectList = useSelector(selectVisualConnectListState)
  const [isLoading, setIsLoading] = useState(false)
  const [displayErrorModal, setDisplayErrorModal] = useState<boolean>(false)
  const [errorModalMessage, setErrorModalMessage] = useState<string>('')
  const [onMeetting, setOnMeetting] = useState<boolean>(false)
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [openInviteChatModal, setOpenInviteChatModal] = useState<boolean>(false)
  const [
    openInviteVisualConnectModal,
    setOpenInviteVisualConnectModal,
  ] = useState<boolean>(false)
  const [ticketDetail, setTicketDetail] = useState<any>()
  const [isInCall, setIsInCall] = useState<boolean>(false)
  const browserLanguage = window.navigator.language
  const { t } = useTranslation()
  const channelMetaData = useSelector(selectMetadata)
  const isTranslate = useSelector(selectIsTranslate);
  const isSignIn = useSelector(selectIsSignIn);
  const ticketState = useSelector(selectTicketState);
  const [isOpenMobileMenu, setOpenMobileMenu] = useState<boolean>(false)
  const [isOpenContactDetail, setOpenContactDetail] = useState<boolean>(false)
  const [isOpenRequestDetails, setOpenRequestDetails] = useState<boolean>(false)
  const selectedContactPerson = useSelector(selectSelectedContactPerson)
  const selectedOnHanldeClearMeeting = useSelector(selectOnHanldeClearMeeting)
  const selectedOnHanldeLeaveMeeting = useSelector(selectOnHanldeLeaveMeeting)
  const messageWithLinkId = useSelector(selectMessageWithLinkId);
  const selectedMeetingId = useSelector(selectMeetingIDOnLive);
  const [isOpenAttendees, setOpenAttendees] = useState<boolean>(false)
  const [meetingIdState, setMeetingIdState] = useState<string>(selectedMeetingId)
  const [avatarData, setAvatarData] = useState(null)
  const [avatarDataEmpty, setAvatarDataEmpty] = useState(true)
  const [onVoiceCall, setOnVoiceCall] = useState<boolean>(false)
  const currentDomain = window.location.protocol + '//' + window.location.host;


  const { broadcast } = useBroadcast<string>({
    channelName: "meeting-status",
    messageHandler: (message: MessageEvent<string>) => {
      if (message.data !== "") {
        let messageParse = JSON.parse(message.data);
        switch (messageParse.type) {
          case 'start_metting':
            setOnMeetting(true)
            break;
          case 'end_metting' || 'leave_metting':
            setOnMeetting(false)
            break;
          default:
            break;
        }
      }
      return;
    },
  });

  const channelGetContactPersonHandler = async (channelMetaDataParsed: any, ticketDetail: any) => {
    let contactPersonCiamID = channelMetaDataParsed ? channelMetaDataParsed.customerCiamId : "";
    let ticketDetailObject = ticketDetail ? ticketDetail : {};
    let ticketSetContactPerson: any;

    if (contactPersonCiamID === ticketDetailObject.ciamId) {
      ticketSetContactPerson = ticketDetailObject
    } else {
      let ticketContactPersonDetail = ticketDetailObject?.contactPersons?.filter((x: any) => {
        return contactPersonCiamID === x.ciamId
      })
      ticketSetContactPerson = ticketContactPersonDetail?.length > 0 && ticketContactPersonDetail[0];
    }

    let ticketCreatorDetail = {
      ciamId: ticketSetContactPerson?.ciamId || "",
      firstName: ticketSetContactPerson?.firstName || "",
      lastName: ticketSetContactPerson?.lastName || "",
      mobilePhone: ticketSetContactPerson?.mobilePhone || "",
      phoneNumber: ticketSetContactPerson?.phoneNumber || "",
      hasWSA: ticketSetContactPerson?.hasWSA || false,
      email: ticketSetContactPerson?.email || ""
    }
    dispatchReduxToolkit(addSelectedContactPerson(ticketCreatorDetail))
    return;
  }

  const channelIdChangeHandler = async (channel: any) => {
    setIsLoading(true)
    let channelName = channel.Name ?? "";
    let channelARN = channel.ChannelArn ?? "";
    if (channelName != "") {
      let channel = await describeChannel(
        channelARN,
        account.chimeUserId,
      )
      updateChannelReadMarker(channelARN, account.chimeUserId)
      if (channel && channel.Privacy !== "PUBLIC") {
        updateChannelReadMarker(channelARN, account.chimeUserId);
        let ticket = await dispatchReduxToolkit(
          getTicketInfor({ ticketId: channelName, email: account.email })
        );
        if (getTicketInfor.fulfilled.match(ticket)) {
          if (!ticket.payload || !ticket.payload.dataObject?.ciamId) {
            setIsLoading(false);
            setErrorModalMessage("Cannot find ticket");
            setDisplayErrorModal(true);
          }
        }
        if (getTicketInfor.rejected.match(ticket)) {
          setErrorModalMessage("Cannot find ticket");
          if (
            ticket.payload &&
            ticket.payload.response?.data.httpStatusCode == 403
          ) {
            setErrorModalMessage("Permission denied");
          }
          setTimeout(() => {
            setIsLoading(false);
            setDisplayErrorModal(true);
          }, 2000);
        }
      }
      dispatchReduxToolkit(channelPopChannelUnreadList(channelARN))
      dispatchReduxToolkit(channelSetChannel(channel))
      setStatusMessageShow(0)
    }
    setIsLoading(false)
  }

  const getTeachnicanInfor = async (onVoiceCall: boolean = false) => {
    setIsLoading(true)
    const data = {
      ticketId: channelName,
      email: account.email,
    }
    setOnVoiceCall(onVoiceCall);
    if (meetingData.Meeting && meetingData.Meeting?.meetingId === '') {
      await httpPCS
        .post(`/api/Ticket/GetTicketInfor`, data)
        .then((res) => {
          setIsLoading(false)
          setPhoneNumber(res.data.dataObject.mobilePhone || "")
          setOpenInviteVisualConnectModal(true)
        })
        .catch((e) => {
          setIsLoading(false)
          setPhoneNumber('')
          setOpenInviteVisualConnectModal(true)
        })
      return
    }
    setOpenInviteVisualConnectModal(true)
  }

  const onStartMeeting = async (phoneNumber: string, isSendSMS: boolean = true) => {
    setOpenInviteVisualConnectModal(false)
    setIsLoading(true)
    const meeting = await dispatchReduxToolkit(createMeeting())
    if (createMeeting.fulfilled.match(meeting)) {
      let meetingId =
        meeting.payload.dataObject.createMeetingResponse.meeting.meetingId
      if (phoneNumber && isSendSMS) {
        await CommonService.sendSMSToStartVideoSession({
          Phone: phoneNumber,
          MeetingId: meetingId,
          onStartVoiceCall: onVoiceCall ? true : false
        })
      }
      if (phoneNumber && !isSendSMS) {
        await CommonService.sendEmailToStartVideoSession({
          subject: onVoiceCall ? 'Invitation to join Voice Call session' : 'Invitation to join Visual Connect session',
          mailTo: phoneNumber,
          message: meetingId,
          onStartVoiceCall: onVoiceCall ? true : false
        })
      }
      if (!phoneNumber || visualConnectList.phoneNumber == '') {
        const options = JSON.stringify({
          meeting: {
            meetingId: meetingId,
            pathRoute: `${currentDomain}/pcs/meeting/`,
          },
          language: browserLanguage,
          onStartVoiceCall: onVoiceCall ? true : false
        })
        const memberSender = { userId: account.chimeUserId }

        const messageRes = await sendChannelMessage(
          channelArn,
          onVoiceCall ? 'Please join voice call via ' : 'Please join video call via ',
          Persistence.PERSISTENT,
          MessageType.STANDARD,
          memberSender,
          options,
          onVoiceCall,
          onVoiceCall ? meetingId : ""
        )
        const messageId = messageRes ? messageRes.response?.MessageId : ""
        await dispatchReduxToolkit(SetMessageWithLinkId(messageId))
        await setMeetingIdState(meetingId)
        await dispatchReduxToolkit(SetMeetingIDOnLive(meetingId))
        await updateChannelReadMarker(channelArn, memberSender.userId)
      }
      await dispatchReduxToolkit(meetingSetChannelName((visualConnectList.active ? "" : channelName)))
      setIsLoading(false)
      await setOnMeetting(true)
      //check voice call or video call
      let meetingUrlPath = onVoiceCall ? `/pcs/meeting/${meetingId}/?user_id=${account.chimeUserId}&mtype=voice` : `/pcs/meeting/${meetingId}/?user_id=${account.chimeUserId}`;
      void window.open(
        meetingUrlPath,
        'null',
        'popup,width=1200,height=680,left=300,top=500, menubar=0, toolbar=0, scrollbar=1',
      )
    }
    if (createMeeting.rejected.match(meeting)) {
      setErrorModalMessage('Cannot start video session')
      setDisplayErrorModal(true)
    }
  }
  const sendEmailVisualConnect = async (email: string) => {
    let isSendSms = false;
    dispatchReduxToolkit(visualConnectIsSendEmail(true))
    onStartMeeting(email, isSendSms);
  }

  const onTrigger = () => {
    setDisplayErrorModal(false)
  }

  const onHanldeStartChattingChannel = (data: string) => {
    dispatchReduxToolkit(visualConnectSetList(''))
    dispatchReduxToolkit(visualConnectSetActive(false))
    dispatchReduxToolkit(visualConnectIsSendEmail(false));
    startChattingChannel(data)
    setOpenInviteChatModal(false)
  }

  const handleBackToList = () => {
    setStatusMessageShow(-1)
    setOpenMobileMenu(false)
    dispatchReduxToolkit(visualConnectSetActive(false))
  }
  const handleMobilestopChannel = (channelArn: any) => {
    setStatusMessageShow(-1)
    setOpenMobileMenu(false)
    stopChannel(channelArn)
  }


  const renderContactDetail = () => {
    return (
      <div className="content">
        {(ticketDetail && ticketDetail !== 'PUBLIC') && (
          <>
            <div className="address">
              <p>
                {ticketDetail.ticketDetails?.customer?.assignedAccount?.name1 || ''}
              </p>
              <p>
                {ticketDetail.ticketDetails?.customer?.address1 || ''}
              </p>
              <p>
                {ticketDetail.ticketDetails?.customer?.address2 || ''}
              </p>
              <p>
                {ticketDetail.ticketDetails.customer?.city || ''}
              </p>
            </div>
            <div className="contact">
              <p>
                {(selectedContactPerson?.firstName || '') +
                  (selectedContactPerson?.lastName || '')}
              </p>
              <p>{selectedContactPerson?.phoneNumber || selectedContactPerson?.mobilePhone || ""}</p>
              <p>
                <a href={selectedContactPerson?.email || ''}>
                  {selectedContactPerson?.email || ''}
                </a>
              </p>
            </div>
            <div className="wsa_check">
              <p>
                {t("Wsa_status_title")}<span className={selectedContactPerson?.hasWSA ? "wsa_active" : "wsa_no_active"}>{selectedContactPerson?.hasWSA ? "Active" : "Not used"}</span>
              </p>
            </div>
          </>
        )}
      </div>
    )
  }
  const renderRequestDetails = () => {
    return (
      <div className="content">
        {ticketDetail && (
          <div className="detail">
            <Grid columns="equal" className="request-detail-grid">
              <Grid.Column
                width={7}
                style={{ paddingRight: '0px' }}
              >
                <p>{t("Id_panel")}</p>
                <p>{t("Topic_panel")}</p>
                <p>{t("Make_panel")}</p>
                <p>{t("Model_pane")}</p>
                <p>{t("Engine_code_panel")}</p>
                <p>{t("Year_panel")}</p>
                <p>{t("Vin_panel")}</p>
                <p>{t("Plate_panel")}</p>
                <p>{t("Hardware_panel")}</p>
              </Grid.Column>
              <Grid.Column className='detail--value' style={{ paddingLeft: '0px' }}>
                <p>
                  {
                    ticketDetail === 'PUBLIC' ? (<>{channelName}</>) : (<> {ticketDetail.ticketDetails?.ticketId || '-'}</>)
                  }
                </p>
                <p>
                  {ticketDetail.ticketDetails?.topic?.serviceType ||
                    '-'}
                </p>
                <p>
                  {ticketDetail.ticketDetails?.vehicleDetails?.carBrand || '-'}
                </p>
                <p>
                  {ticketDetail.ticketDetails?.vehicleDetails?.carModel || '-'}
                </p>
                <p>
                  {ticketDetail.ticketDetails?.vehicleDetails?.engineCode || '-'}
                </p>
                <p>
                  {ticketDetail.ticketDetails?.vehicleDetails?.modelYear || '-'}
                </p>
                <p>
                  {ticketDetail.ticketDetails?.vehicleDetails?.vin || '-'}
                </p>
                <p>
                  {ticketDetail.ticketDetails?.vehicleDetails?.licensePlate || '-'}
                </p>
                <p>
                  {ticketDetail.ticketDetails?.customer?.hardware ? ticketDetail.ticketDetails?.customer?.hardware[0]?.model : '-'}
                </p>
              </Grid.Column>
            </Grid>
          </div>
        )}
      </div>
    )
  }

  //use Effect
  useEffect(() => {
    const getAvatarFormMS = async () => {
      if (isSignIn && !avatarData) {
        try {
          const loginResponse = await msalInstance.ssoSilent(loginRequest);
          if (loginResponse) {
            callAvatarGraph(loginResponse.accessToken).then((response) => {
              setAvatarData(response)
              setAvatarDataEmpty(false)
            }
            ).catch(() =>
              setAvatarDataEmpty(true)
            )
          }
        } catch (err) {
          console.error("Get Avatar Error ...: ", err);
        }
      }
    }
    getAvatarFormMS()
  }, [isSignIn, avatarData])

  //handle progres message after leave or end meeting
  useEffect(() => {
    if (channelArn) {
      if (selectedOnHanldeClearMeeting) {
        const metaData = {
          deleted: true,
        }
        const handleRemoveMessageLink = async () => {
          await dispatchReduxToolkit(SetMessageWithLinkId(""));
          await dispatchReduxToolkit(SetOnHanldeClearMeeting(false));
          await updateChannelMessage(channelArn, messageWithLinkId, "Deleted", JSON.stringify(metaData), account.chimeUserId)
          await dispatchReduxToolkit(SetMeetingIDOnLive(""));
        }
        handleRemoveMessageLink()
      }

      if (selectedOnHanldeLeaveMeeting) {
        const metaData = JSON.stringify({
          meeting: {
            meetingId: meetingIdState,
            pathRoute: `${currentDomain}/pcs/meeting/`,
          },
          language: browserLanguage,
          onStartVoiceCall: onVoiceCall ? true : false
        })

        const handleRemoveMessageLink = async () => {
          await dispatchReduxToolkit(SetMeetingIDOnLive(""));
          await dispatchReduxToolkit(SetOnHanldeLeaveMeeting(false));
          await updateChannelMessage(channelArn, messageWithLinkId, t("Leave_meeting_meesage_Warning"), metaData, account.chimeUserId)
        }
        handleRemoveMessageLink()
      }
    }
  }, [selectedOnHanldeClearMeeting, selectedOnHanldeLeaveMeeting, channelArn])

  useEffect(() => {
    const updateMessage = async () => {
      if (channelArn && channelArn !== '') {
        const newMessages = await listChannelMessages(
          channelArn,
          account?.chimeUserId,
        )
        const message_translate = await Promise.all(
          newMessages.Messages.map(async (message: any) => {
            if (
              createMemberArn(account.chimeUserId) !== message.Sender.Arn &&
              isTranslate.status
            ) {
              // const sourceLanguageCode = JSON.parse(message.Metadata)?.language || "";
              const translateMessage = await liveTranslateMessage(
                message.Content,
                isTranslate.langSource,
                isTranslate.langTarget,
              )
              return { ...message, Content: translateMessage }
            }
            return message
          }),
        )

        await dispatchReduxToolkit(
          ChannelSetMessage({
            ChannelMessages: message_translate,
            NextToken: newMessages.NextToken,
          }),
        )
        setStatusMessageShow(1)
      }
    }
    updateMessage()
  }, [channelArn, message, isTranslate])

  useEffect(() => {
    if (channelArn === '' && channelList.length > 0) {
      const fetchChannels = async () => {
        const channel = await describeChannel(
          channelList[0].ChannelArn,
          account.chimeUserId,
        )
        await channelIdChangeHandler(channel)
        setStatusMessageShow(0)
      }
      if (statusMessageShow !== -1) {
        fetchChannels()
      }
    } else if (channelArn === '' && channelList.length === 0) {
      setStatusMessageShow(-1)
    }
  }, [channelList])

  useEffect(() => {
    if (channelArn === '' && channelList.length === 0) {
      setStatusMessageShow(-1)
    }
  }, [channelList, channelArn])

  useEffect(() => {

    const getTicketInforHandler = async () => {
      const channelype = channelMetaData ? JSON.parse(channelMetaData)?.channelType : "";
      const channelMetaDataParsed = channelMetaData ? JSON.parse(channelMetaData) : "";
      if (channelype !== 'PUBLIC') {
        channelGetContactPersonHandler(channelMetaDataParsed, ticketState);
        setTicketDetail(ticketState)
      } else {
        setTicketDetail('PUBLIC');
      }
    }

    if (channelMetaData) {
      getTicketInforHandler()
    }
  }, [channelMetaData])

  useEffect(() => {

    const getTicketInforHandler = async () => {
      setTicketDetail(ticketState)
    }

    if (ticketState.ciamId) {
      getTicketInforHandler()
    }
  }, [ticketState])

  useEffect(() => {
    if (!meetingData.Meeting?.meetingId) {
      setOnMeetting(false)
    }
  }, [meetingData.Meeting?.meetingId])

  useEffect(() => {
    if (
      visualConnectList.phoneNumber !== '' &&
      visualConnectList.hasFirstStart
    ) {
      let isSendSMS = !visualConnectList.isSendEmail;
      onStartMeeting(visualConnectList.phoneNumber, isSendSMS);
    }
  }, [visualConnectList.phoneNumber])

  return (
    <div className="messages">
      <Grid columns="equal" className="messages-grid">
        {/* computer view */}
        <Grid.Column style={{ paddingRight: '0' }} only="computer">
          <div className="messages-left-side">
            <div className="bosch-expert-info">
              <div className="logo-setting">
                <Button
                  className="setting--btn"
                  onClick={() => { }}
                  icon={
                    <Boschicon name={'bosch-ic-settings'} />
                  }
                ></Button>
              </div>
              <div className="bosch-expert-avatar">
                <div className="setting--avatar">
                  {avatarData ? (
                    <img className='setting--avatar--img'
                      src={`data:image/jpeg;base64,${Buffer.from(avatarData).toString(
                        'base64',
                      )}`}
                      alt="User Avatar"
                    />
                  ) : (
                    <>
                      {
                        !avatarDataEmpty ? (<Loader active inline />) : (<Boschicon name={'bosch-ic-my-brand-frame'} />)
                      }
                    </>
                  )}

                </div>
                <p>{account.name}</p>
              </div>
            </div>
            <div className="list-channel">
              <div className="list-channel-title">{t('Channel_List')}</div>
              <ChannelList className="channel-list">
                {channelList.map((channel: any) => (
                  <div
                    className={
                      channel.ChannelArn === channelArn &&
                        !visualConnectList.active
                        ? 'channel-list-btn active'
                        : 'channel-list-btn'
                    }
                    key={channel.ChannelArn}
                  >
                    <div className='btn--content--title' onClick={() => {
                      dispatchReduxToolkit(visualConnectSetActive(false))
                      if (channel.ChannelArn === channelArn) {
                        setStatusMessageShow(1)
                        return
                      }
                      channelIdChangeHandler(channel)
                    }}>
                      <div className='content--title'>
                        <div className='channel-name'>
                          {
                            JSON.parse(channel.Metadata).channelType === 'PUBLIC' ? (
                              <>
                                <p>
                                  {t('Anonymous')} ({channel.Name})
                                </p>
                              </>
                            ) : (
                              <>
                                {JSON.parse(channel.Metadata).companyName ? (
                                  <>
                                    <p>
                                      {JSON.parse(channel.Metadata).fullNameCustomer} ({JSON.parse(channel.Metadata).serviceType})
                                    </p>
                                    <p>{JSON.parse(channel.Metadata).companyName}</p>
                                  </>
                                ) : (
                                  JSON.parse(channel.Metadata).nameWithRecipient ||
                                  channel.Name
                                )}
                              </>

                            )
                          }
                        </div>
                        {channelListUnread.includes(channel.ChannelArn) && (
                          <div className="btn--content--unseen">
                            <Icon
                              style={{ verticalAlign: 'sub', padding: 'unset' }}
                            >
                              <Notification />
                            </Icon>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='btn--content--icon-delete' onClick={() => stopChannel(channel.ChannelArn)}>
                      {channel.ChannelArn !== channelArn && (
                        <Boschicon name={"bosch-ic-abort-frame"} />
                      )}
                    </div>
                  </div>
                ))}
                {channelList.length == 0 &&
                  visualConnectList.phoneNumber == '' && (
                    <p style={{ marginTop: '30px', fontSize: '10px' }}>
                      Empty list
                    </p>
                  )}
                {visualConnectList.phoneNumber !== '' && (
                  <div
                    className={
                      visualConnectList.active
                        ? 'channel-list-btn active'
                        : 'channel-list-btn'
                    }>
                    <div className='btn--content--title' onClick={() => {
                      dispatchReduxToolkit(visualConnectSetActive(true))
                      setStatusMessageShow(0)
                    }}>
                      <div className='content--title'>
                        <p>{t("Visual_Connect_Label")}</p>
                      </div>
                    </div>

                    <div className='btn--content--icon-delete' onClick={() => { dispatchReduxToolkit(visualConnectClearList()) }}>
                      <Boschicon name={"bosch-ic-abort-frame"} />
                    </div>
                  </div>
                )}
              </ChannelList>
            </div>
          </div>
        </Grid.Column>

        {/* No actived channel */}
        {statusMessageShow !== 1 && !visualConnectList.active && (
          <>
            {/* computer view */}
            <Grid.Column width={12} style={{ paddingLeft: '0' }} only="computer">
              {statusMessageShow == 0 && (
                <div className="message-list-container loading-message">
                  <Loader active inline />
                </div>
              )}
              {statusMessageShow == -1 && (
                <div className="message-list-container loading-message">
                  <span>{t('Welcome_Label')}</span>
                </div>
              )}
            </Grid.Column>
            {/* mobile and tablet view */}
            <Grid.Column only="mobile tablet">
              <div className='messages-mobile-layout'>
                <div className="bosch-expert-info">
                  <div className='bosch-expert-name'>
                    Hi, <span>{account.name}</span>!
                  </div>
                  <div className="bosch-expert-avatar">
                    <div className="setting--avatar">
                      {avatarData ? (
                        <img className='setting--avatar--img'
                          src={`data:image/jpeg;base64,${Buffer.from(avatarData).toString(
                            'base64',
                          )}`}
                          alt="User Avatar"
                        />
                      ) : (
                        <>
                          {
                            !avatarDataEmpty ? (<Loader active inline />) : (<Boschicon name={'bosch-ic-my-brand-frame'} />)
                          }
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {
                channelList.length > 0 && (
                  <div className="list-channel-mobile-layout">
                    <div className="list-channel-title">{t('Channel_List')}</div>
                    <ChannelList className="channel-list">
                      {channelList.map((channel: any) => (
                        <div
                          className={
                            channel.ChannelArn === channelArn &&
                              !visualConnectList.active
                              ? 'channel-list-btn active'
                              : 'channel-list-btn'
                          }
                          key={channel.ChannelArn}
                        >
                          <div className='btn--content--title' onClick={() => {
                            dispatchReduxToolkit(visualConnectSetActive(false))
                            if (channel.ChannelArn === channelArn) {
                              setStatusMessageShow(1)
                              return
                            }
                            channelIdChangeHandler(channel)
                          }}>
                            <div className='content--title'>
                              <div className='channel-name'>
                                {
                                  JSON.parse(channel.Metadata).channelType === 'PUBLIC' ? (
                                    <>
                                      <p>
                                        {t('Anonymous')} ({channel.Name})
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      {JSON.parse(channel.Metadata).companyName ? (
                                        <>
                                          <p>
                                            {JSON.parse(channel.Metadata).fullNameCustomer} ({JSON.parse(channel.Metadata).serviceType})
                                          </p>
                                          <p>{JSON.parse(channel.Metadata).companyName}</p>
                                        </>
                                      ) : (
                                        JSON.parse(channel.Metadata).nameWithRecipient ||
                                        channel.Name
                                      )}
                                    </>

                                  )
                                }
                              </div>
                              {channelListUnread.includes(channel.ChannelArn) && (
                                <div className="btn--content--unseen">
                                  <Icon
                                    style={{ verticalAlign: 'sub', padding: 'unset' }}
                                  >
                                    <Notification />
                                  </Icon>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className='btn--content--icon-delete' onClick={() => stopChannel(channel.ChannelArn)}>
                            {channel.ChannelArn !== channelArn && (
                              <Boschicon name={"bosch-ic-abort-frame"} />
                            )}
                          </div>
                        </div>
                      ))}
                      {visualConnectList.phoneNumber !== '' && (
                        <div
                          className={
                            visualConnectList.active
                              ? 'channel-list-btn active'
                              : 'channel-list-btn'
                          }>
                          <div className='btn--content--title' onClick={() => {
                            dispatchReduxToolkit(visualConnectSetActive(true))
                            setStatusMessageShow(0)
                          }}>
                            <div className='content--title'>
                              <p>{t("Visual_Connect_Label")}</p>
                            </div>
                          </div>

                          <div className='btn--content--icon-delete' onClick={() => { dispatchReduxToolkit(visualConnectClearList()) }}>
                            <Boschicon name={"bosch-ic-abort-frame"} />
                          </div>
                        </div>
                      )}
                    </ChannelList>
                  </div>
                )
              }
            </Grid.Column>
            {(channelList.length == 0) && (
              <Grid.Row className='list-channels-mobile' only="mobile tablet">
                <div className="message-list-container loading-message">
                  <span>{t('Welcome_Label')}</span>
                </div>
              </Grid.Row>
            )}
          </>
        )}

        {/* Have actived channel */}
        {statusMessageShow == 1 && !visualConnectList.active && (
          <>
            {/* computer view */}
            <Grid.Column
              width={9}
              style={{ paddingRight: '5px', paddingLeft: '5px' }}
              only="computer"
            >
              <div className="messages-middle-side">
                <div className="messages--function">
                  {statusMessageShow == 1 && (
                    <>
                      <div className="temp"></div>
                      <div className="main--function">
                        <div className="main--function--icon">
                          <Button
                            className={
                              channelArn
                                ? 'function--btn--active'
                                : 'function--btn'
                            }
                            onClick={
                              channelArn
                                ? () => { }
                                : () => setOpenInviteChatModal(true)
                            }
                            icon={
                              <Icon
                                style={{
                                  verticalAlign: 'sub',
                                  padding: 'unset',
                                }}
                              >
                                <Chat />
                              </Icon>
                            }
                          ></Button>
                          <div
                            className={
                              channelArn
                                ? 'function--title--active'
                                : 'function--title'
                            }
                          >
                            {t('Chat_Label')}
                          </div>
                        </div>
                        <div className="main--function--icon">
                          <Button
                            className={
                              onMeetting &&
                                meetingData.ChannelName === channelName
                                ? 'function--btn--active'
                                : 'function--btn'
                            }
                            onClick={
                              onMeetting
                                ? meetingData.ChannelName !== channelName
                                  ? () => {
                                    setIsInCall(true)
                                  }
                                  : () => { }
                                : () => getTeachnicanInfor()
                            }
                            icon={
                              <Icon>
                                <Image src={ARIcon} fluid></Image>
                              </Icon>
                            }
                          ></Button>
                          <div
                            className={
                              onMeetting &&
                                meetingData.ChannelName === channelName
                                ? 'function--title--active'
                                : 'function--title'
                            }
                          >
                            {t('Visual_Connect_Label')}
                          </div>
                        </div>
                        <div className="main--function--icon">
                          <Button
                            className={
                              onMeetting &&
                                meetingData.ChannelName === channelName
                                ? 'function--btn--active'
                                : 'function--btn'
                            }
                            onClick={
                              onMeetting
                                ? meetingData.ChannelName !== channelName
                                  ? () => {
                                    setIsInCall(true)
                                  }
                                  : () => { }
                                : () => getTeachnicanInfor(true)
                            }
                            icon={
                              <Icon
                                style={{
                                  verticalAlign: 'sub',
                                  padding: 'unset',
                                }}
                              >
                                <VoiceCall />
                              </Icon>
                            }
                          ></Button>
                          <div
                            className={
                              onMeetting &&
                                meetingData.ChannelName === channelName
                                ? 'function--title--active'
                                : 'function--title'
                            }
                          >
                            {t('Voice_Call_Label')}
                          </div>
                        </div>
                      </div>
                      <div className="end--function">
                        <div className="main--function--icon">
                          <Button
                            className="closeChannel--btn"
                            onClick={() => stopChannel(channelArn)}
                            icon={
                              <Icon
                                style={{
                                  verticalAlign: 'sub',
                                  padding: 'unset',
                                }}
                              >
                                <CloseChannel />
                              </Icon>
                            }
                          ></Button>
                          <div
                            className={
                              'function--title'
                            }
                          >
                            {t('Stop_Channel_Label')}
                          </div>

                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="messages--chatting-area">
                  <div className="messaging-container">
                    {statusMessageShow == 1 && (
                      <Fragment>
                        <Messages
                          messages={messages}
                          channelArn={channelArn}
                          userId={account?.chimeUserId ? account.chimeUserId : ''}
                          translateModeData={isTranslate} />
                        <Input
                          activeChannelArn={channelArn}
                          member={
                            account?.chimeUserId ? account.chimeUserId : ''
                          }
                          browserLanguage={browserLanguage}
                        />
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width={3} style={{ paddingLeft: '0' }} only="computer">
              <div className="messages-right-side">
                <div className="contact-detail">
                  <div className="title">
                    <p>{t('Contact_Details_Label')}</p>
                  </div>
                  {ticketState.ciamId && renderContactDetail()}
                </div>
                <div className="request-detail">
                  <div className="title">
                    <p>{t('Request_Details_Label')}</p>
                  </div>
                  {ticketState.ciamId && renderRequestDetails()}
                </div>
                <div className="attendees">
                  <div className="title">
                    <p>{t('Attendees_Label')}</p>
                  </div>
                  <div className="content">
                    <ListMemberChannelModal></ListMemberChannelModal>
                  </div>
                </div>
              </div>
            </Grid.Column>
            {/* mobile and tablet view */}
            <Grid.Column only="mobile tablet">
              <div className='messages-mobile-layout'>
                <div className="bosch-expert-info">
                  {(ticketDetail && ticketDetail !== 'PUBLIC') && (
                    <>
                      <div className='back-to-list'>
                        <Boschicon name={'bosch-ic-back-left'} onClick={handleBackToList} />
                      </div>
                      <div className='channel-name'>
                        <p>{"#" + ticketDetail.ticketDetails?.ticketId || '#'}</p>
                        <p className='channel-name-title'>{(ticketDetail.firstName || '') +
                          (ticketDetail.lastName || '') + ` (${ticketDetail.ticketDetails?.customer?.assignedAccount?.name1 || ''})`}</p>
                        <p className='online-title'>Online</p>
                      </div>
                      <div className="menu-channel">
                        <Boschicon name={'bosch-ic-list-view-mobile'} onClick={() => setOpenMobileMenu(true)} />
                      </div>
                    </>
                  )}
                  {(ticketDetail && ticketDetail === 'PUBLIC') && (
                    <>
                      <div className='back-to-list'>
                        <Boschicon name={'bosch-ic-back-left'} onClick={handleBackToList} />
                      </div>
                      <div className='channel-name'>
                        <p>{"#" + channelName || '#'}</p>
                        <p className='channel-name-title'>{t("Anonymous")}</p>
                        <p className='online-title'>Online</p>
                      </div>
                      <div className="menu-channel">
                        <Boschicon name={'bosch-ic-list-view-mobile'} onClick={() => setOpenMobileMenu(true)} />
                      </div>
                    </>
                  )}
                </div>
              </div>
              {!isOpenMobileMenu ? (<div className="chatting-mobile-layout">
                {statusMessageShow == 1 && (
                  <Fragment>
                    <Messages
                      messages={messages}
                      channelArn={channelArn}
                      userId={account?.chimeUserId ? account.chimeUserId : ''}
                      translateModeData={isTranslate} />
                    <Input
                      activeChannelArn={channelArn}
                      member={
                        account?.chimeUserId ? account.chimeUserId : ''
                      }
                      browserLanguage={browserLanguage}
                    />
                  </Fragment>
                )}
              </div>) : (<div className='menu-mobile-layout'>
                <div className='menu-mobile-layout--close'>
                  <Boschicon name={'bosch-close'} onClick={() => setOpenMobileMenu(false)} />
                </div>
                <div className='menu-mobile-layout-option functionality'>
                  <div className='functionality-title'>
                    <p>{t("Functionality_Label")}</p>
                  </div>
                  <div className='functionality-item'>
                    <Button
                      className={
                        onMeetting &&
                          meetingData.ChannelName === channelName
                          ? 'function--btn--active'
                          : 'function--btn'
                      }
                      onClick={
                        onMeetting
                          ? meetingData.ChannelName !== channelName
                            ? () => {
                              setIsInCall(true)
                            }
                            : () => { }
                          : () => getTeachnicanInfor()
                      }
                    >
                      <Icon style={{ verticalAlign: 'sub', padding: 'unset' }}>
                        <div className='visualConnect-image-btn'></div>
                      </Icon>
                      {t('Visual_Connect_Label')}
                    </Button>
                  </div>
                  <div className='functionality-item'>
                    <Button
                      className={
                        onMeetting &&
                          meetingData.ChannelName === channelName
                          ? 'function--btn--active'
                          : 'function--btn'
                      }
                      onClick={
                        onMeetting
                          ? meetingData.ChannelName !== channelName
                            ? () => {
                              setIsInCall(true)
                            }
                            : () => { }
                          : () => getTeachnicanInfor(true)
                      }
                    >
                      <Icon
                        style={{
                          verticalAlign: 'sub',
                          padding: 'unset',
                        }}
                      >
                        <VoiceCallBlack />
                      </Icon>
                      {t('Voice_Call_Label')}
                    </Button>
                  </div>
                </div>
                <div className='menu-mobile-layout-option channel-information'>
                  <div className='functionality-title'>
                    <p>{t("Meeting_Information_Label")}</p>
                  </div>
                  <div className='functionality-item'>
                    <Button
                      className={'function--btn detail'
                      }
                      onClick={() => { setOpenContactDetail(!isOpenContactDetail) }}
                    >
                      {'Contact Details'}
                      <Boschicon name={'down'} />
                    </Button>
                    {isOpenContactDetail && (
                      <div className="contact-detail">
                        {renderContactDetail()}
                      </div>
                    )}
                  </div>
                  <div className='functionality-item'>
                    <Button
                      className={'function--btn detail'
                      }
                      onClick={() => { setOpenRequestDetails(!isOpenRequestDetails) }}
                    >
                      {' Request Details'}
                      <Boschicon name={'down'} />
                    </Button>
                    {isOpenRequestDetails && (
                      <div className="request-detail">
                        {renderRequestDetails()}
                      </div>
                    )}
                  </div>
                  <div className='functionality-item'>
                    <Button
                      className={'function--btn detail'
                      }
                      onClick={() => { setOpenAttendees(!isOpenAttendees) }}
                    >
                      {'Attendees'}
                      <Boschicon name={'down'} />
                    </Button>
                    {isOpenAttendees && (
                      <div className="content">
                        <ListMemberChannelModal></ListMemberChannelModal>
                      </div>
                    )}
                  </div>
                </div>
                <div className='menu-mobile-layout--close-channel'>
                  <Button
                    className={'function--btn close'
                    }
                    onClick={() => { handleMobilestopChannel(channelArn) }}
                  >
                    {'Close Channel'}
                  </Button>
                </div>
              </div>)}
            </Grid.Column>
          </>
        )}

        {/* Have visual connect channel */}
        {visualConnectList.phoneNumber !== '' && visualConnectList.active && (
          <>
            <Grid.Column
              width={9}
              style={{ paddingRight: '5px', paddingLeft: '5px' }}
              only="computer"
            >
              <div className="messages-middle-side">
                <div className="messages--function">
                  <div className="temp"></div>
                  <div className="main--function">
                    <div className="main--function--icon">
                      <Button
                        className={'function--btn'}
                        onClick={() => { }}
                        disabled
                        icon={
                          <Icon
                            style={{ verticalAlign: 'sub', padding: 'unset' }}
                          >
                            <Chat />
                          </Icon>
                        }
                      ></Button>
                      <div className={'function--title'}>{t('Chat_Label')}</div>
                    </div>
                    <div className="main--function--icon">
                      <Button
                        className={
                          onMeetting && meetingData.ChannelName === ""
                            ? 'function--btn--active'
                            : 'function--btn'
                        }
                        onClick={
                          onMeetting
                            ? meetingData.ChannelName !== ""
                              ? () => {
                                setIsInCall(true)
                              }
                              : () => { }
                            : () => getTeachnicanInfor()
                        }
                        icon={
                          <Icon>
                            <Image src={ARIcon} fluid></Image>
                          </Icon>
                        }
                      ></Button>
                      <div
                        className={
                          onMeetting && meetingData.ChannelName === ""
                            ? 'function--title--active'
                            : 'function--title'
                        }
                      >
                        {t('Visual_Connect_Label')}
                      </div>
                    </div>
                  </div>
                  <div className="end--function">
                    <div className="main--function--icon">
                      <Button
                        className="closeChannel--btn"
                        onClick={() => stopChannel(channelArn)}
                        icon={
                          <Icon
                            style={{ verticalAlign: 'sub', padding: 'unset' }}
                          >
                            <CloseChannel />
                          </Icon>
                        }
                      ></Button>
                      {t('Stop_Channel_Label')}
                    </div>
                  </div>
                </div>
                <div className="messages--chatting-area">
                  <div className="messaging-container">
                    <p>{t("Press_Chatting_Button_Label")}</p>
                  </div>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width={3} style={{ paddingLeft: '0' }} only="computer">
              <div className="messages-right-side">
                <div className="contact-detail">
                  <div className="title">
                    <p>{t('Contact_Details_Label')}</p>
                  </div>
                  <div className="content">
                    <div className='bosch-expert-email'>
                      {account.email}
                    </div>
                  </div>
                </div>
                <div className="request-detail">
                  <div className="title">
                    <p>{t('Request_Details_Label')}</p>
                  </div>
                  <div className="content">
                    {ticketDetail && <div className="detail"></div>}
                  </div>
                </div>
                <div className="attendees">
                  <div className="title">
                    <p>{t('Attendees_Label')}</p>
                  </div>
                  <div className="content">
                    <div className="member-item">
                      <div className="member-icon">
                        <Icon className='icon'>
                          <Antendees />
                        </Icon>
                      </div>
                      <div className="member-name">
                        {account.name + ' (' + t('Bosch_Expert_Label') + ')'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid.Column>
            {/* mobile and tablet view */}
            <Grid.Column only="mobile tablet">
              <div className='messages-mobile-layout'>
                <div className="bosch-expert-info">
                  <div className='back-to-list'>
                    <Boschicon name={'bosch-ic-back-left'} onClick={handleBackToList} />
                  </div>
                  <div className='channel-name'>
                    <p className='channel-name-title'>{t("Visual_Connect_Label")}</p>
                    <p className='online-title'>Online</p>
                  </div>
                  <div className="menu-channel">
                    <Boschicon name={'bosch-ic-list-view-mobile'} onClick={() => setOpenMobileMenu(true)} />
                  </div>
                </div>
              </div>
              {!isOpenMobileMenu ? (<div className="chatting-mobile-layout">
              </div>) : (<div className='menu-mobile-layout'>
                <div className='menu-mobile-layout--close'>
                  <Boschicon name={'bosch-close'} onClick={() => setOpenMobileMenu(false)} />
                </div>
                <div className='menu-mobile-layout-option functionality'>
                  <div className='functionality-title'>
                    <p>{t("Functionality_Label")}</p>
                  </div>
                  <div className='functionality-item'>
                    <Button
                      className={
                        onMeetting &&
                          meetingData.ChannelName === channelName
                          ? 'function--btn--active'
                          : 'function--btn'
                      }
                      onClick={
                        onMeetting
                          ? meetingData.ChannelName !== channelName
                            ? () => {
                              setIsInCall(true)
                            }
                            : () => { }
                          : () => getTeachnicanInfor()
                      }
                    >
                      <Icon style={{ verticalAlign: 'sub', padding: 'unset' }}>
                        <div className='visualConnect-image-btn'></div>
                      </Icon>
                      {t('Visual_Connect_Label')}
                    </Button>
                  </div>
                  <div className='functionality-item'>
                    <Button
                      className={
                        onMeetting &&
                          meetingData.ChannelName === channelName
                          ? 'function--btn--active'
                          : 'function--btn'
                      }
                      onClick={
                        onMeetting
                          ? meetingData.ChannelName !== channelName
                            ? () => {
                              setIsInCall(true)
                            }
                            : () => { }
                          : () => getTeachnicanInfor(true)
                      }
                    >
                      <Icon
                        style={{
                          verticalAlign: 'sub',
                          padding: 'unset',
                        }}
                      >
                        <VoiceCallBlack />
                      </Icon>
                      {t('Voice_Call_Label')}
                    </Button>
                  </div>
                </div>
                <div className='menu-mobile-layout--close-channel'>
                  <Button
                    className={'function--btn close'
                    }
                    onClick={() => { handleMobilestopChannel(channelArn) }}
                  >
                    {'Close Channel'}
                  </Button>
                </div>
              </div>)}
            </Grid.Column>
          </>
        )}

        <Fragment>
          <Modal
            onClose={onTrigger}
            open={displayErrorModal}
            dimmer={{ className: 'test-dimmer' }}
            size={'mini'}
            closeIcon
          >
            <Modal.Content>
              <Grid divided="vertically">
                <Grid.Row>
                  <label
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    Error
                  </label>
                </Grid.Row>
                {errorModalMessage && (
                  <Grid.Row
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    {errorModalMessage}
                  </Grid.Row>
                )}

                <Grid.Row>
                  <Button
                    className="button-search"
                    style={{ width: '100%' }}
                    onClick={onTrigger}
                  >
                    Close
                  </Button>
                </Grid.Row>
              </Grid>
            </Modal.Content>
          </Modal>
        </Fragment>
        {isLoading && (
          <Fragment>
            <CustomLoader enable={isLoading}></CustomLoader>
          </Fragment>
        )}
        {openInviteChatModal && (
          <ModalSendInviteChattingChannel
            isOpen={openInviteChatModal}
            onClose={() => setOpenInviteChatModal(false)}
            onchangeTextSearchInput={onHanldeStartChattingChannel}
          ></ModalSendInviteChattingChannel>
        )}
        {openInviteVisualConnectModal && (
          <ModalSendInviteVisualConnect
            isLoading={isLoading}
            phoneNumberInput={phoneNumber}
            isOpen={openInviteVisualConnectModal}
            onClose={() => setOpenInviteVisualConnectModal(false)}
            onHanldeSendSMS={onStartMeeting}
            sendEmailVisualConnect={sendEmailVisualConnect}
            onVoiceCall={onVoiceCall}
          ></ModalSendInviteVisualConnect>
        )}
        {isInCall && (
          <ModalNotiEndMeetting
            isOpen={isInCall}
            onClose={() => setIsInCall(false)}
          ></ModalNotiEndMeetting>
        )}
      </Grid>
    </div >
  )
}

export default MessagesView
